import { string } from 'prop-types';
import dayjs from 'dayjs';
import {
  Button, Card, Divider, Flex, Space, Table, Tooltip,
  Typography,
} from 'antd';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import {
  AccountBookFilled, ClockCircleOutlined, DeleteOutlined, EditOutlined, EnvironmentOutlined, PlusOutlined, TeamOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalize, get, head, omit,
} from 'lodash';
import useURLParams from '../../../hooks/useURLParams';
import ListOfUsersHRM54 from '../../users/ListOfUsersHRM54';
import ListOfCurrentUsers from '../../users/ListOfCurrentUsers';
import useActorsHRM54 from '../../../actors/hooks/useActorsHRM54';
import { getDefaultDepartment, getDefaultDepartmentUUID } from '../../../redux/departments/selectors/defaultDepartmentSelector';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import useOfficesAPI from '../../../api/useOfficesAPI';
import EntityTitleForCard from '../../commonComponents/entityCardComponents/EntityTitleForCard';
import EntityDescriptionForCard from '../../commonComponents/entityCardComponents/EntityDescriptionForCard';
import useAPI from '../../../api/useAPI';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import {
  actorIsRootOrAdmin, firstName, getUuid, lastName,
} from '../../../actors/utils';
import { entityRead } from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import { ENABLE_RESET_PROPERTY } from '../../../redux/reset/resetSlice';

const { Text } = Typography;

export default function OfficeCard({
  // office,
  onBackCallback,
  onUpdateOfficeCallback,
}) {
  const dispatch = useDispatch();
  const defaultDepartmentUUID = useSelector(getDefaultDepartmentUUID);
  const partitionUUID = useSelector(getDeliveryPartition);
  const { setSearchParams, getURLParams, clearSearchParams } = useURLParams();
  const { office: activeOfficeUUID } = getURLParams();

  const { readListOfOffices, updateOffice, deleteOffice } = useOfficesAPI();

  const [office, setOffice] = useState(null);

  const [isEditActorsMode, setIsEditActorsMode] = useState(false);

  const [users, setUsers] = useState([]);
  const [usersUUIDs, setUsersUUIDs] = useState([]);

  const [actors, setActors] = useState([]);

  const { getDeliveryUsers } = useAPI();

  const onUserSelect = async (value) => {
    if (!usersUUIDs?.includes(value?.actor)) {
      setUsers((prev) => [...prev, value]);
      setUsersUUIDs((prev) => [...prev, value.actor]);
    } else {
      setUsers((prev) => prev.filter((user) => user.actor !== value?.actor));
      setUsersUUIDs((prev) => prev.filter((userUUID) => userUUID !== value?.actor));
    }
  };

  const initActors = async () => {
    if (office?.params?.contractors?.length > 0) {
      const res = await dispatch(entityRead({
        data: {
          actor_type: ['user', 'classic_user'],
          offset: 0,
          depth: 0,
          parent: partitionUUID,
          entity_uuids: office?.params?.contractors ?? [],
        },
        constants: [
          'FETCH_ACTORS_HRM54_REQUEST',
          'FETCH_ACTORS_HRM54_SUCCESS',
          'FETCH_ACTORS_HRM54_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }));
      const newActors = res?.map?.((item) => ({
        value: getUuid(item),
        actor: item?.actor ?? '',
        label: `${capitalize(firstName(item))} ${capitalize(lastName(item))}`,
        isWMSAdmin: actorIsRootOrAdmin(item),
        user: item,
      })) ?? [];
      if (newActors) {
        setUsers(newActors);
        setUsersUUIDs(newActors?.map((actor) => actor?.actor));
        setActors(newActors);
      }
    } else {
      setUsers([]);
      setUsersUUIDs([]);
      setActors([]);
    }
  };

  const onUpdateOffice = async (
    data = null,
  ) => {
    if (activeOfficeUUID) {
      const config = {
        entity_uuid: activeOfficeUUID,
      };

      if (data) {
        config.params = { ...data };
      }

      const res = await updateOffice(activeOfficeUUID, config, [
        'UPDATE_SINGLE_OFFICE_REQUEST',
        'UPDATE_SINGLE_OFFICE_SUCCESS',
        'UPDATE_SINGLE_OFFICE_FAILURE',
      ]);
      if (res) {
        setOffice(head(res));
      }
      if (onUpdateOfficeCallback) {
        onUpdateOfficeCallback(head(res));
      }
    }
  };

  const onDeleteOffice = async () => {
    if (activeOfficeUUID) {
      await deleteOffice(activeOfficeUUID, {}, [
        'DELETE_OFFICE_REQUEST',
        'DELETE_OFFICE_SUCCESS',
        'DELETE_OFFICE_FAILURE',
      ]);
      setOffice(null);
      dispatch(ENABLE_RESET_PROPERTY('offices_list'));
      clearSearchParams(['office']);
    }
  };

  useEffect(() => {
    if (activeOfficeUUID) {
      const initFunc = async () => {
        const res = await readListOfOffices(activeOfficeUUID, {}, [
          'GET_SINGLE_OFFICE_REQUEST',
          'GET_SINGLE_OFFICE_SUCCESS',
          'GET_SINGLE_OFFICE_FAILURE',
        ]);
        if (res) {
          setOffice(head(res));
        }
      };
      initFunc();
    } else {
      setOffice(null);
    }
  }, [activeOfficeUUID]);

  useEffect(() => {
    if (partitionUUID && office) {
      initActors();
    }
  }, [partitionUUID, office]);

  return (
    <Card
      className="blue-base-card"
      title={(
        <Flex align="center" justify="space-between">
          <Flex
            align="center"
            className="text-2xl"
          >
            <Icon
              path={mdiArrowLeft}
              size={1}
              className="cursor-pointer mr-2"
              onClick={onBackCallback}
            />
            <span>Офис</span>
          </Flex>
          <Space>
            <Tooltip title="Удалить">
              <Button danger onClick={onDeleteOffice}>Удалить</Button>
            </Tooltip>
          </Space>
        </Flex>
    )}
    >
      <Flex
        className="mx-8 mt-2 max-w-full overflow-hidden"
        justify="start"
      >
        <EntityTitleForCard
          title={get(office, ['params', 'title'], '')}
          onEditCallback={onUpdateOffice}
        />
      </Flex>
      <Divider className="my-2" />
      <EntityDescriptionForCard
        className="mx-8 my-3"
        description={get(office, ['params', 'description'], '')}
        onEditCallback={onUpdateOffice}
      />
      <Card
        size="small"
        title={(
          <Space>
            <TeamOutlined />
            <span>Подрядчики</span>
          </Space>
      )}
        extra={!isEditActorsMode ? (
          <Button
            type="primary"
            size="small"
            onClick={() => setIsEditActorsMode(true)}
          >
            Редактировать подрядчиков
          </Button>
        ) : (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setIsEditActorsMode(false);
              onUpdateOffice({
                contractors: users?.map((user) => user?.value),
              });
            }}
          >
            Сохранить
          </Button>
        )}
        className="!mb-0 mt-4"
      >
        {!isEditActorsMode ? (
          <Table
            size="small"
            className="mx-4 my-2"
            dataSource={actors}
            pagination={false}
            columns={[
              {
                title: 'Имя',
                dataIndex: ['label'],
                key: 'name',
                render: (text, record) => text,
              },
            ]}
          />
        ) : (
          <Flex
            // justify="space-between"
            gap={8}
            className="mx-4 my-2"
          >
            <Flex
              vertical
            >
              <span
                className="text-lg text-gray-500"
              >
                Доступные пользователи:
              </span>
              <ListOfUsersHRM54
                parent={defaultDepartmentUUID}
                selected={usersUUIDs}
                onUserSelect={onUserSelect}
              />
            </Flex>
            <Flex
              vertical
              className="ml-2"
            >
              <span
                className="text-lg text-gray-500"
              >
                Подрядчики офиса:
              </span>
              <ListOfCurrentUsers
                users={users}
                onUserChange={onUserSelect}
              />
            </Flex>
          </Flex>
        )}
      </Card>
    </Card>
  );
}

OfficeCard.propTypes = {
  officeUUID: string,
};
