import React from 'react';
import { Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import useDepartmentRequestsAPI from '../../api/useDepartmentRequestsAPI';

export default function ReviewRequest({
  uuid, requestStatus, isDepartmentRoutes, onUpdate,
}) {
  const { updateDepartmentRequest } = useDepartmentRequestsAPI();

  const handleReview = async () => {
    onUpdate?.();
    await updateDepartmentRequest(uuid, {
      params: {
        status: 'approved',
      },
    });
  };

  if (requestStatus === 'approved' || requestStatus === 'rejected') {
    return null;
  }

  return (
    <Button
      type="primary"
      icon={<CheckOutlined />}
      onClick={handleReview}
    >
      Рассмотреть
    </Button>
  );
}

ReviewRequest.propTypes = {
  uuid: PropTypes.string.isRequired,
  requestStatus: PropTypes.string.isRequired,
  isDepartmentRoutes: PropTypes.bool.isRequired,
};
