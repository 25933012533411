import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { get } from 'lodash';
import {
  Button, Card, Flex, Input, Modal, Spin, Dropdown,
} from 'antd';
import dayjs from 'dayjs';
import {
  AutoSizer, List,
} from 'react-virtualized';
import { EllipsisOutlined } from '@ant-design/icons';
import { getDeliveryPartition } from '../../redux/config/selectors';
import { getProfileUUID } from '../../redux/profile/selectors';
import useURLParams from '../../hooks/useURLParams';
import { getDefaultDepartmentUUID } from '../../redux/departments/selectors/defaultDepartmentSelector';
import useDepartments from '../../redux/departments/hooks/useDepartments';
import useArtifacts from '../../redux/departments/hooks/useArtifacts';
import AntDesignSearchBox54origins from '../../54origins/components/AntDesignSearchBox54origins';
import ContractorLabel from '../../components/contractors/commonComponents/ContractorLabel';
import BaseTableWithPagination from '../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import PageWrapper from '../../components/PageWrapper';
import { getFullNameOrInitials } from '../../utils/utils54origins';
import antNotification from '../../utils/antNotification';
import useTriggersOnboarding from '../../events/useTriggersOnboarding';
import useTriggersTermination from '../../events/useTriggersTermination';
import useOfficesAPI from '../../api/useOfficesAPI';
import useOnboardingAPI from '../../api/useOnboardingAPI';
import useTerminationAPI from '../../api/useTerminationAPI';
import useArtifactsAPI from '../../api/useArtifactsAPI';
import { getArtifact, getArtifactUUID, getIsFetchingArtifact } from '../../redux/departments/selectors/artifactsSelector';
import {
  GET_SINGLE_ARTIFACT_REQUEST,
  GET_SINGLE_ARTIFACT_SUCCESS,
  GET_SINGLE_ARTIFACT_FAILURE,
  RESET_SINGLE_ARTIFACT,
} from '../../redux/departments/slices/artifactsSlice';
import ArtifactCard from '../../components/artifacts/ArtifactCard';
import UniGeneralButtons from '../../components/commonComponents/uniGeneralButtons/UniGeneralButtons';
import RequestsList from '../../components/requests/RequestsList';

const { TextArea } = Input;

export default function UsersForHrPanelPage() {
  const dispatch = useDispatch();

  const {
    readListOfArtifacts,
  } = useArtifactsAPI();

  const artifact = useSelector(getArtifact);
  const artifactUUID = useSelector(getArtifactUUID);

  const {
    getURLParams, clearSearchParams, setSearchParams, addAndRemoveSearchParams,
  } = useURLParams();

  const { contractor: activeArtifact } = getURLParams();

  const { getListOfOffices } = useOfficesAPI();
  const { getListOfOnboarding, updateOnboarding } = useOnboardingAPI();

  const partitionUUID = useSelector(getDeliveryPartition);
  const actorUUID = useSelector(getProfileUUID);
  const defaultDepartmentUUID = useSelector(getDefaultDepartmentUUID);

  const {
    departments,
    isFetching: isFetchingDepartments,
  } = useDepartments({
    defaultConfig: {
      depth: 0,
      limit: null,
    },
  });
  const {
    artifacts,
    isFetching: isFetchingArtifacts,
    paginationOptions,
    valueSearch,
    onChangeSearch,
    onReloadArtifacts,
  } = useArtifacts({
    parentUUID: partitionUUID,
    defaultConfig: {
      entity_type: 'user',
      depth: 0,
      limit: 50,
    },
  });
  const {
    run: runCreateOnboarding,
    EventComponent: EventComponentCreateOnboarding,
  } = useTriggersOnboarding();

  const {
    run: runCompleteOnboarding,
    EventComponent: EventComponentCompleteOnboarding,
  } = useTriggersOnboarding();

  const {
    run: runTermination,
    EventComponent: EventComponentTermination,
  } = useTriggersTermination();

  const {
    run: runCompleteTermination,
    EventComponent: EventComponentCompleteTermination,
  } = useTriggersTermination();

  const { getListOfTermination } = useTerminationAPI();

  const [userOnboarding, setUserOnboarding] = useState(null);
  const [instruction, setInstruction] = useState('');
  const [office, setOffice] = useState('');
  const [offices, setOffices] = useState([]);
  const [usersOnboarding, setUsersOnboarding] = useState({});
  const [terminationStatuses, setTerminationStatuses] = useState({});
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (activeArtifact) {
      readListOfArtifacts(
        activeArtifact,
        { },
        [
          GET_SINGLE_ARTIFACT_REQUEST,
          GET_SINGLE_ARTIFACT_SUCCESS,
          GET_SINGLE_ARTIFACT_FAILURE,
        ],
      );
    } else {
      dispatch(RESET_SINGLE_ARTIFACT);
    }
  }, [activeArtifact]);

  const loadOffices = async () => {
    const officesRes = await getListOfOffices(partitionUUID);

    setOffices(officesRes?.data || []);
  };

  const loadOnboardingStatuses = async () => {
    const statuses = {};

    const res = await getListOfOnboarding(partitionUUID, {
      params: {
        status: 'active',
      },
    });

    res?.data?.map((el) => {
      statuses[el?.parent] = el || null;
    });

    setUsersOnboarding(statuses);
  };

  const loadTerminationStatuses = async () => {
    const statuses = {};

    const res = await getListOfTermination(partitionUUID, {
      params: {
        status: 'pending',
      },
    });

    res?.data?.map((el) => {
      statuses[el?.parent] = el || null;
    });

    setTerminationStatuses(statuses);
  };

  useEffect(() => {
    loadOffices();
    loadOnboardingStatuses();
    loadTerminationStatuses();
  }, []);

  const getActionButtons = (record, onboarding) => {
    const items = [
      {
        key: 'onboarding',
        label: onboarding ? (
          <Button
            size="small"
            className="w-full text-left text-blue-600 hover:!text-blue-500 !border-blue-600"
            type="default"
            onClick={() => runCompleteOnboarding('complete-onboarding', {
              user: record,
              onboarding,
              offices,
              departments,
              onComplete: () => {
                onReloadArtifacts();
                loadOffices();
                loadOnboardingStatuses();
              },
            })}
          >
            Завершить онбординг
          </Button>
        ) : (
          <Button
            size="small"
            className="w-full text-left text-blue-600 hover:!text-blue-500 !border-blue-600"
            type="default"
            onClick={() => runCreateOnboarding('start-onboarding', {
              user: record,
              onComplete: () => {
                onReloadArtifacts();
                loadOffices();
                loadOnboardingStatuses();
              },
            })}
          >
            Онбординг
          </Button>
        ),
      },
      {
        key: 'termination',
        label: terminationStatuses[record.uuid] ? (
          <Button
            size="small"
            className="w-full text-left text-red-600 hover:!text-red-500 !border-red-600"
            type="default"
            onClick={() => runCompleteTermination('complete-termination', {
              user: record,
              onComplete: () => {
                onReloadArtifacts();
                loadOffices();
                loadTerminationStatuses();
              },
            })}
          >
            Завершить увольнение
          </Button>
        ) : (
          <Button
            size="small"
            className="w-full text-left text-red-600 hover:!text-red-500 !border-red-600"
            type="default"
            onClick={() => runTermination('start-termination', {
              user: record,
              saveCallback: () => {
                loadTerminationStatuses();
                onReloadArtifacts();
                loadOffices();
              },
            })}
          >
            Запустить увольнение
          </Button>
        ),
      },
    ];

    if (activeArtifact) {
      return (
        <div className="flex justify-center w-full">
          <Dropdown
            menu={{ items }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            placement="bottomRight"
          >
            <Button
              size="small"
              type="default"
              shape="circle"
              icon={<EllipsisOutlined />}
              className="flex items-center justify-center border-gray-300 hover:border-gray-400"
            />
          </Dropdown>
        </div>
      );
    }

    return (
      <Flex gap="small">
        {onboarding ? (
          <Button
            size="small"
            type="default"
            className="text-blue-600 border-blue-600 hover:!text-blue-500 hover:!border-blue-500"
            onClick={() => runCompleteOnboarding('complete-onboarding', {
              user: record,
              onboarding,
              offices,
              departments,
              onComplete: () => {
                onReloadArtifacts();
                loadOffices();
                loadOnboardingStatuses();
              },
            })}
          >
            Завершить онбординг
          </Button>
        ) : (
          <Button
            size="small"
            type="default"
            className="text-blue-600 border-blue-600 hover:!text-blue-500 hover:!border-blue-500"
            onClick={() => runCreateOnboarding('start-onboarding', {
              user: record,
              onComplete: () => {
                onReloadArtifacts();
                loadOffices();
                loadOnboardingStatuses();
              },
            })}
          >
            Онбординг
          </Button>
        )}
        {terminationStatuses[record.uuid] ? (
          <Button
            size="small"
            className="bg-red-600 text-white border-red-600 hover:!border-red-500"
            onClick={() => runCompleteTermination('complete-termination', {
              user: record,
              onComplete: () => {
                onReloadArtifacts();
                loadOffices();
                loadTerminationStatuses();
              },
            })}
          >
            Завершить увольнение
          </Button>
        ) : (
          <Button
            size="small"
            danger
            onClick={() => runTermination('start-termination', {
              user: record,
              saveCallback: () => {
                loadTerminationStatuses();
                onReloadArtifacts();
                loadOffices();
              },
            })}
          >
            Запустить увольнение
          </Button>
        )}
      </Flex>
    );
  };

  const columns = [
    {
      key: 'name',
      title: (
        <div className="flex items-center">
          <span className="mr-2">Имя</span>
          <AntDesignSearchBox54origins
            maxWidth={250}
            valueSearch={valueSearch}
            onSearch={onChangeSearch}
          />
        </div>
      ),
      dataIndex: ['uinfo'],
      width: '20%',
      render: (cell, row) => (
        <ContractorLabel
          user={row}
        />
      ),
    },
    {
      key: 'department',
      title: 'Отдел',
      dataIndex: ['parent'],
      width: '20%',
      render: (cell, row) => {
        const department = departments?.find((el) => el?.uuid === cell);
        if (department) {
          return <span className="text-blue-500">{department?.params?.title}</span>;
        }
        return <span className="text-gray-500">Не назначен</span>;
      },
    },
    {
      title: 'Офис',
      dataIndex: 'uuid',
      key: 'office',
      width: '20%',
      render: (uuid) => {
        const userOffice = offices?.find((office) => office?.params?.contractors?.includes(uuid));
        return userOffice ? <span className="text-blue-500">{userOffice?.params?.title}</span> : <span className="text-gray-500">Не назначен</span>;
      },
    },
    {
      key: 'created',
      title: 'Добавлен',
      dataIndex: ['created'],
      width: '20%',
      className: 'text-sm text-gray-500',
      render: (cell) => dayjs(cell).format('DD MMMM YYYY HH:mm:ss'),
    //   render: (cell, row) => {
    //     const department = departments?.find((el) => el?.uuid === cell);
    //     if (department) {
    //       return <span className="text-blue-500">{department?.params?.title}</span>;
    //     }
    //     return <span className="text-red-500">Нет отдела</span>;
    //   },
    },
    {
      title: 'Действия',
      key: 'actions',
      dataIndex: ['uuid'],
      width: activeArtifact ? '10%' : '25%',
      align: 'center',
      render: (_, record) => getActionButtons(record, usersOnboarding[record.uuid]),
    },
  ];

  const onRow = (record) => ({
    onClick: () => {
      const uuid = get(record, 'uuid', '');

      addAndRemoveSearchParams({ contractor: uuid }, ['contractor']);
    },
  });

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Подрядчики
        </Flex>
      )}
    >
      <Flex className="w-full">
        <Card
          className={cn('w-full p-0 pb-3 px-2 h-max blue-base-card', {
            'w-1/2': activeArtifact,
          })}
          title={(
            <Flex
              className="text-xl"
            >
              Подрядчики
            </Flex>
        )}
        >
          <Spin
            className="w-full h-100"
            spinning={isFetchingDepartments || isFetchingArtifacts}
          >
            <BaseTableWithPagination
              columns={columns}
              data={artifacts}
              rowKey="uuid"
              rowClassName="cursor-pointer"
              onRow={onRow}
              size="small"
              className="w-full h-full !mb-3"
              pageSizeOptions={[20, 50, 100]}
              useCustomPagination
              disablePagination={false}
              {...paginationOptions}
            />
          </Spin>
        </Card>

        {activeArtifact && (
        <ArtifactCard
          hideDeleteButton
          hideCopyURLButton
          showOnboardingTab
        // hideBackButton
          className="w-1/2 ml-6"
          artifact={artifact}
          artifactUUID={artifactUUID}
          onBackArtCallback={() => {
            clearSearchParams(['contractor']);
          }}
          onUpdateArtCallback={() => {
          }}
          onDeleteArtCallback={() => {
            clearSearchParams(['contractor']);
          }}
        />
        )}
      </Flex>

      <EventComponentCreateOnboarding />
      <EventComponentCompleteOnboarding />
      <EventComponentTermination />
      <EventComponentCompleteTermination />
    </PageWrapper>
  );
}
