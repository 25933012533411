import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List, Tag, Button, Spin,
  Flex, Space, Typography,
  Tooltip,
  Input, Form,
  Tabs,
  Popconfirm,
} from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  ClockCircleOutlined,
  CaretRightOutlined,
  UserOutlined,
  TeamOutlined,
  HistoryOutlined,
  ApartmentOutlined,
  BankOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import MetaeventEvents from '../../metaevents/events/MetaeventEvents';
import MetaeventLogs from '../../metaevents/logs/MetaeventLogs';
import axiosRequest from '../../../api/api';
import { getListAndReadEntities } from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import useURLParams from '../../../hooks/useURLParams';
import MetaEventCard from '../card/MetaEventCard';
import { TRIGGER_NAMES } from '../../metaevents/triggers';
import useOfficesAPI from '../../../api/useOfficesAPI';

const { Text } = Typography;

export default function EventMetaList({
  metaevents,
  selectedTrigger,
  expandedMetaEvents,
  onToggleMetaEvent,
  onViewMetaEvent,
  onEditMetaEvent,
  onDeleteMetaEvent,
  isLoading,
  selectedMetaEvent,
}) {
  const dispatch = useDispatch();

  const partitionUuid = useSelector(getDeliveryPartition);

  const history = useHistory();

  const { getListOfOffices } = useOfficesAPI();

  const { setSearchParams, getURLParams } = useURLParams();
  const [groupsMap, setGroupsMap] = useState({});
  const [officesMap, setOfficesMap] = useState({});
  const [departmentsMap, setDepartmentsMap] = useState({});

  const items = [
    {
      key: 'info',
      label: 'Информация',
      children: (metaEvent) => (
        <div className="log-values -mt-6 -pl-4 -pt-2 -pb-10">
          {metaEvent.params.instruction_title && (
          <div className="log-field">
            <Text type="secondary">Название инструкции:</Text>
            <div className="instruction-text">
              {metaEvent.params.instruction_title}
            </div>
          </div>
          )}
          {metaEvent.params.instruction && (
            <div className="log-field">
              <Text type="secondary">Инструкция:</Text>
              <div className="instruction-text">
                {metaEvent.params.instruction.split('\n').map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            </div>
          )}
        </div>
      ),
    },
    {
      key: 'events',
      label: (
        <span>
          <ApartmentOutlined />
          События
        </span>
      ),
      children: (metaEvent) => (
        <Flex className="-my-10">
          <MetaeventEvents metaeventUuid={metaEvent.uuid} />
        </Flex>
      ),
    },
    {
      key: 'logs',
      label: (
        <span>
          <HistoryOutlined />
          История
        </span>
      ),
      children: (metaEvent) => (
        <Flex className="-my-10">
          <MetaeventLogs metaeventUuid={metaEvent.uuid} />
        </Flex>
      ),
    },
  ];

  function getListOfAllGroups() {
    return dispatch(axiosRequest.post(
      'utility/actor/list',
      [
        'GET_LIST_OF_GROUPS_REQUEST',
        {
          type: 'GET_LIST_OF_GROUPS_SUCCESS',
          payload: (res) => {
            const groups = {};
            res?.data?.forEach((group) => {
              groups[group.uuid] = group.uinfo.group_name;
            });
            setGroupsMap(groups);
            return res;
          },
        },
        'GET_LIST_OF_GROUPS_FAILURE',
      ],
      {
        actor_type: 'group',
        not_banned: true,
      },
    ));
  }

  async function getOffices() {
    const res = await getListOfOffices(partitionUuid, {
      depth: 0,
    });

    const offices = {};
    res?.data?.forEach((office) => {
      offices[office.uuid] = office?.params?.title;
    });
    setOfficesMap(offices);
    return res;
  }

  async function getDepartments() {
    if (!partitionUuid) return;

    const res = await dispatch(getListAndReadEntities({
      constants: [
        'GET_LIST_OF_DEPARTMENTS_REQUEST',
        {
          type: 'GET_LIST_OF_DEPARTMENTS_SUCCESS',
        },
        'GET_LIST_OF_DEPARTMENTS_FAILURE',
      ],
      data: {
        entity_type: 'department',
        parent: partitionUuid,
      },
      partition: SERVICE_PARTITION_NAME,
    }));

    const departments = {};
    res?.data?.forEach((dept) => {
      departments[dept.uuid] = dept.params.title;
    });
    setDepartmentsMap(departments);
  }

  useEffect(() => {
    getListOfAllGroups();
    getOffices();
    getDepartments();
  }, []);

  const handleOpenCard = (metaEvent) => {
    setSearchParams({ metaevent: metaEvent.uuid });
  };

  const getTriggerInfo = (triggerValue) => (
    TRIGGER_NAMES[triggerValue] || {}
  );

  return (
    <Spin spinning={isLoading} className="mt-0">
      <List
        dataSource={metaevents}
        renderItem={(metaEvent) => (
          <div
            className={`meta-event-card mb-2 ${
              expandedMetaEvents[metaEvent.uuid] ? 'expanded' : ''
            }`}
          >
            <div className="meta-event-header">
              <div className="title-row">
                <div className="title-section">
                  <div className="flex items-center ">
                    <Flex>
                      <div className="flex items-center gap-3">
                        <CaretRightOutlined
                          className={`expand-icon ${expandedMetaEvents[metaEvent.uuid] ? 'expanded' : ''}`}
                          onClick={() => onToggleMetaEvent(metaEvent.uuid)}
                        />
                        <h3>{metaEvent.params.title}</h3>
                      </div>

                      <div className="flex items-center gap-4 ml-6 -mt-1">
                        <div className="meta-event-brief-info flex items-center gap-2 text-gray-500">
                          <ClockCircleOutlined className="text-blue-500" />
                          <span>
                            {new Date(metaEvent.created).toLocaleDateString('ru-RU', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </span>
                        </div>

                        <div className="meta-event-brief-info flex items-center gap-2 text-gray-500">
                          <UserOutlined className="text-blue-500" />
                          <span>
                            {metaEvent.uinfo
                              ? `${metaEvent.uinfo.first_name} ${metaEvent.uinfo.last_name}`
                              : metaEvent.actor}
                          </span>
                        </div>
                      </div>
                    </Flex>
                  </div>

                  <div className="meta-event-brief-info mt-2 ml-1">
                    <Space size="middle" className="flex-wrap ml-10">
                      <span className="flex items-center gap-2">
                        <BankOutlined className="text-blue-500" />
                        <span className="text-blue-500">Офисы:</span>
                        {metaEvent.params.offices?.length > 0 ? (
                          <Space size={[0, 4]} className="flex-wrap">
                            {metaEvent.params.offices.slice(0, 2).map((officeId) => (officesMap[officeId] ? (
                              <Tag key={officeId}>
                                {officesMap[officeId] || officeId}
                              </Tag>
                            ) : null))}
                            {metaEvent.params.offices.length > 2 && (
                            <Tooltip
                              title={(
                                <div className="offices-tooltip">
                                  {metaEvent.params.offices.slice(2).map((officeId) => (
                                    <Tag key={officeId}>
                                      {officesMap[officeId] || officeId}
                                    </Tag>
                                  ))}
                                </div>
                                  )}
                            >
                              <Tag className="cursor-pointer">
                                +
                                {metaEvent.params.offices.length - 2}
                              </Tag>
                            </Tooltip>
                            )}
                          </Space>
                        ) : (
                          <span className="text-gray-500">Не указаны</span>
                        )}
                      </span>

                      <span className="flex items-center gap-2 ml-2">
                        <ApartmentOutlined className="text-blue-500" />
                        <span className="text-blue-500">Отделы:</span>
                        {metaEvent.params.departments?.length > 0 ? (
                          <Space size={[0, 4]} className="flex-wrap">
                            {metaEvent.params.departments.slice(0, 2).map((deptId) => (departmentsMap[deptId] ? (
                              <Tag key={deptId}>
                                {departmentsMap[deptId]}
                              </Tag>
                            ) : null))}
                            {metaEvent.params.departments.length > 2 && (
                            <Tooltip
                              title={(
                                <div className="departments-tooltip">
                                  {metaEvent.params.departments.slice(2).map((deptId) => (departmentsMap[deptId] ? (
                                    <Tag key={deptId}>
                                      {departmentsMap[deptId]}
                                    </Tag>
                                  ) : null))}
                                </div>
                                  )}
                            >
                              <Tag className="cursor-pointer">
                                +
                                {metaEvent.params.departments.length - 2}
                              </Tag>
                            </Tooltip>
                            )}
                          </Space>
                        ) : (
                          <span className="text-gray-500">Не указаны</span>
                        )}
                      </span>

                      {metaEvent.params.groups?.length > 0 && (
                      <span className="flex items-center gap-2 ml-2">
                        <TeamOutlined className="text-blue-500" />
                        <span className="text-blue-500">Группы:</span>
                        <Space size={[0, 4]} className="flex-wrap">
                          {metaEvent.params.groups.slice(0, 3).map((groupId) => (
                            <Tag key={groupId} className="ml-1">
                              {groupsMap[groupId] || groupId}
                            </Tag>
                          ))}
                          {metaEvent.params.groups.length > 3 && (
                          <Tooltip
                            title={(
                              <div className="groups-tooltip">
                                {metaEvent.params.groups.slice(3).map((groupId) => (
                                  <Tag key={groupId}>
                                    {groupsMap[groupId] || groupId}
                                  </Tag>
                                ))}
                              </div>
                                  )}
                          >
                            <Tag className="cursor-pointer">
                              +
                              {metaEvent.params.groups.length - 3}
                            </Tag>
                          </Tooltip>
                          )}
                        </Space>
                      </span>
                      )}
                    </Space>
                  </div>
                </div>

                <div className="meta-event-controls flex items-center gap-2">
                  <Button
                    type="text"
                    className="text-blue-500 hover:text-blue-400"
                    icon={<EyeOutlined />}
                    onClick={() => handleOpenCard(metaEvent)}
                  >
                    Открыть
                  </Button>
                  <Tag color={getTriggerInfo(metaEvent.params?.trigger)?.color}>
                    {getTriggerInfo(metaEvent.params?.trigger)?.name}
                  </Tag>
                </div>
              </div>
            </div>

            {expandedMetaEvents[metaEvent.uuid] && (
            <>
              <Tabs
                defaultActiveKey="info"
                items={items.map((item) => ({
                  ...item,
                  children: item.children(metaEvent),
                }))}
                className="mt-0 -p-4 -pl-6"
              />
              <div className="meta-event-footer">
                <div className="meta-event-info">
                  <div className="meta-event-brief-info">
                    <ClockCircleOutlined />
                    <span>
                      Создано:
                      {' '}
                      {new Date(metaEvent.created).toLocaleDateString('ru-RU', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </span>
                  </div>
                  <div className="meta-event-brief-info">
                    <UserOutlined />
                    <span>
                      Создал:
                      {' '}
                      {metaEvent.uinfo
                        ? `${metaEvent.uinfo.first_name} ${metaEvent.uinfo.last_name}`
                        : metaEvent.actor}
                    </span>
                  </div>
                  {metaEvent.modified !== metaEvent.created && (
                  <div className="meta-event-brief-info">
                    <ClockCircleOutlined />
                    <span>
                      Обновлено:
                      {' '}
                      {new Date(metaEvent.modified).toLocaleDateString('ru-RU', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </span>
                  </div>
                  )}
                </div>
                <div
                  className="meta-event-actions"
                  onClick={(e) => e.stopPropagation()}
                  role="group"
                  aria-label="Действия с метасобытием"
                  tabIndex={-1}
                >
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={() => onEditMetaEvent(metaEvent)}
                  >
                    Редактировать
                  </Button>
                  <Popconfirm
                    title="Удаление метасобытия"
                    description="Вы уверены, что хотите удалить это метасобытие? Это действие нельзя будет отменить."
                    okText="Да, удалить"
                    cancelText="Отмена"
                    placement="topRight"
                    okButtonProps={{ danger: true }}
                    onConfirm={async () => {
                      await onDeleteMetaEvent(metaEvent);
                      getDepartments();
                    }}
                  >
                    <Button
                      type="text"
                      icon={<DeleteOutlined />}
                      className="delete-btn text-red-500 hover:text-red-400"
                    >
                      Удалить
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </>
            )}
          </div>
        )}
      />
    </Spin>
  );
}

EventMetaList.propTypes = {
  metaevents: PropTypes.arrayOf(PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    params: PropTypes.shape({
      title: PropTypes.string.isRequired,
      trigger: PropTypes.string.isRequired,
      instruction: PropTypes.string,
      groups: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    actor: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    modified: PropTypes.string,
    uinfo: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
  })).isRequired,
  selectedTrigger: PropTypes.shape({
    color: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  expandedMetaEvents: PropTypes.shape({}).isRequired,
  onToggleMetaEvent: PropTypes.func.isRequired,
  onViewMetaEvent: PropTypes.func.isRequired,
  onEditMetaEvent: PropTypes.func.isRequired,
  onDeleteMetaEvent: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedMetaEvent: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    params: PropTypes.shape({
      title: PropTypes.string.isRequired,
      trigger: PropTypes.string.isRequired,
      instruction: PropTypes.string,
      groups: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
  }),
};

EventMetaList.defaultProps = {
  selectedMetaEvent: null,
};
