import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert, Divider, Flex, Table, Tabs,
} from 'antd';
import { ProfileOutlined, InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import {
  bool, object, shape, string, arrayOf,
} from 'prop-types';
import { capitalize, get, head } from 'lodash';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import useAPI from '../../../api/useAPI';
import useVacationsAPI from '../../../api/useVacationsAPI';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import { getArrByType, isWorkingDay } from '../../../pages/user/dayOff/utils';
import { enumerateDaysBetweenDates } from '../../../utils/commonUtils';
import { translateTypeDay } from '../../days/utils/utils';
import DaysCalendar from '../../days/DaysCalendar';
import UpdateDailyReportForm from '../../days/UpdateDailyReportForm';
import PencilEditButton from '../../commonComponents/PencilEditButton';
import MonthNavigation from '../common/MonthNavigation';
import { renderWorkTime } from '../common/utilityComponents';
import useOnboardingAPI from '../../../api/useOnboardingAPI';

const MONTHS_TO_FETCH = 6;
const TABLE_COLUMNS = [
  {
    title: 'Месяц',
    dataIndex: ['params', 'date'],
    render: (cell) => capitalize(dayjs(cell).format('MMMM')),
  },
  {
    title: 'Проектных часов',
    dataIndex: ['params', 'statistics', 'totalWorks'],
    className: '!bg-green-100',
  },
  {
    title: 'Клиентский пр.',
    dataIndex: ['params', 'statistics', 'clientWork'],
  },
  {
    title: 'Внутренний пр.',
    dataIndex: ['params', 'statistics', 'internalWork'],
  },
  {
    title: 'Бенч',
    className: '!bg-orange-100',
    dataIndex: ['params', 'statistics', 'totalBenches'],
    render: (cell) => <span className={cn({ 'text-red-500': +cell > 0 })}>{cell}</span>,
  },
  {
    className: '!bg-red-100',
    title: 'Нерабочих часов',
    dataIndex: ['params', 'statistics', 'totalDayOff'],
    render: (cell) => <span className={cn({ 'text-red-500': +cell > 0 })}>{cell}</span>,
  },
  {
    title: 'Отгул',
    dataIndex: ['params', 'statistics', 'dayoff'],
    render: (cell) => <span className={cn({ 'text-red-500': +cell > 0 })}>{cell}</span>,
  },
  {
    title: 'Больничный',
    dataIndex: ['params', 'statistics', 'sickday'],
    render: (cell) => <span className={cn({ 'text-red-500': +cell > 0 })}>{cell}</span>,
  },
  {
    title: 'Отпуск',
    dataIndex: ['params', 'statistics', 'vacation'],
    render: (cell) => <span className={cn({ 'text-red-500': +cell > 0 })}>{cell}</span>,
  },
];

function OnboardingStatus({ onboardingList }) {
  if (!onboardingList?.length) {
    return (
      <Flex
        align="center"
        justify="center"
        className="text-gray-400 text-base py-12 bg-gray-50 rounded-lg"
      >
        <InfoCircleOutlined className="mr-2 text-lg" />
        Нет данных об онбординге
      </Flex>
    );
  }

  const getStatusText = (status) => {
    switch (status) {
      case 'active':
        return 'В процессе';
      case 'completed':
        return 'Завершен';
      default:
        return 'Неизвестный статус';
    }
  };

  return (
    <Flex vertical gap="small">
      {onboardingList?.map((onboarding) => (
        <Flex
          key={onboarding?.uuid}
          vertical
          style={{
            boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1)',
            borderRadius: '8px',
          }}
          className={cn(
            'w-full hover:shadow-md transition-shadow duration-200',
            {
              'bg-gradient-to-r from-blue-50 to-white border-l-4 border-l-blue-500': onboarding?.params?.status === 'active',
              'bg-white border-l-4 border-l-gray-200': onboarding?.params?.status !== 'active',
            },
          )}
        >
          <Flex align="center" className="px-6 py-4 border-b border-gray-100">
            {onboarding?.params?.status === 'active' ? (
              <InfoCircleOutlined className="text-blue-500 mr-3 text-xl" />
            ) : (
              <CheckCircleOutlined className="text-gray-400 mr-3 text-xl" />
            )}
            <span className={cn('font-medium', {
              'text-blue-600': onboarding?.params?.status === 'active',
              'text-gray-600': onboarding?.params?.status !== 'active',
            })}
            >
              Онбординг
            </span>
            <span className={cn('ml-auto text-sm px-3 py-1 rounded-full', {
              'bg-blue-100 text-blue-600': onboarding?.params?.status === 'active',
              'bg-gray-100 text-gray-600': onboarding?.params?.status !== 'active',
            })}
            >
              {getStatusText(onboarding?.params?.status)}
            </span>
          </Flex>

          <Flex vertical className="px-6 py-4">
            <Flex align="center" className="mb-3">
              <span className="text-gray-400 w-24">Создано:</span>
              <span className="text-gray-700">{dayjs(onboarding?.created).format('DD.MM.YYYY HH:mm')}</span>
            </Flex>

            <Flex align="center">
              <span className="text-gray-400 w-24">Запустил:</span>
              <span className="text-gray-700">
                {onboarding?.uinfo?.first_name}
                {' '}
                {onboarding?.uinfo?.last_name}
              </span>
            </Flex>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
}

OnboardingStatus.propTypes = {
  onboardingList: arrayOf(shape({
    uuid: string,
    created: string,
    params: shape({
      status: string,
    }),
    uinfo: shape({
      first_name: string,
      last_name: string,
    }),
  })),
};

OnboardingStatus.defaultProps = {
  onboardingList: [],
};

export default function UserArtifactView({ artifact, showOnboardingTab }) {
  const { getMonthlyReport } = useAPI();
  const { getListOfVacations } = useVacationsAPI();
  const partitionUUID = useSelector(getDeliveryPartition);
  const { uuid, actor } = artifact || {};

  const { getListOfOnboarding } = useOnboardingAPI();

  const [currentMonth, setCurrentMonth] = useState({});
  const [reports, setReports] = useState([]);
  const [today, setToday] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().subtract(1, 'month').format('YYYY-MM'));
  const [isOpenEditDay, setIsOpenEditDay] = useState(false);
  const [vacationsArr, setVacationsArr] = useState([]);
  const [onboardingData, setOnboardingData] = useState([]);

  const startMonth = dayjs(selectedMonth).startOf('month').format('YYYY-MM-DD');
  const endMonth = dayjs(selectedMonth).endOf('month').format('YYYY-MM-DD');

  const data = useMemo(
    () => getArrByType(
      [...vacationsArr, ...get(currentMonth, 'params.days', [])],
      enumerateDaysBetweenDates(startMonth, endMonth),
    ),
    [vacationsArr, currentMonth?.params?.days, startMonth, endMonth, actor],
  );

  const fetchInitialData = async () => {
    const monthsArray = Array.from({ length: MONTHS_TO_FETCH + 1 })
      .map((_, index) => dayjs().subtract(index, 'month').format('YYYY-MM'));

    const [reportsData, vacationsData] = await Promise.all([getMonthlyReport(uuid, {
      actor,
      depth: 0,
      orderBy: 'params.date',
      order: 'asc',
      params: { date: monthsArray },
    }, ['GET_REPORTS_REQUEST', 'GET_REPORTS_SUCCESS', 'GET_REPORTS_FAILURE']),
    getListOfVacations(uuid, {
      actor,
      params: {
        status: 'approved',
        rangeDates: [dayjs().format('YYYY-MM-DD')],
      },
    }, ['GET_VACATIONS_REQUEST', 'GET_VACATIONS_SUCCESS', 'GET_VACATIONS_FAILURE']),
    ]);

    const currentMonthDate = dayjs().format('YYYY-MM');
    const newCurrentMonth = reportsData
      ?.data?.find((el) => el?.params?.date === currentMonthDate);

    const todayReport = newCurrentMonth?.params?.days?.filter(
      (el) => head(el?.rangeDates) === dayjs().format('YYYY-MM-DD'),
    );

    setToday([...(todayReport || []), ...(vacationsData?.data || [])]
      .reduce((acc, el) => (acc && dayjs(acc).isAfter(el?.created) ? acc : ({
        ...(el?.params || el),
        type: el?.type || el?.entity_type,
      })), null));
    setReports(reportsData?.data?.filter((el) => el?.params?.date !== currentMonthDate));
  };

  const fetchOnboardingData = async () => {
    if (uuid) {
      const res = await getListOfOnboarding(uuid);
      setOnboardingData(res?.data || []);
    }
  };

  useEffect(() => {
    if (!actor || !uuid || !partitionUUID) return;

    fetchInitialData();
  }, [actor, uuid, partitionUUID]);

  useEffect(() => {
    if (!actor || !uuid || !partitionUUID || !selectedMonth) return;

    const fetchCalendarData = async () => {
      const [calendarData, vacationsData] = await Promise.all([
        getMonthlyReport(uuid, {
          actor,
          depth: 0,
          orderBy: 'params.date',
          order: 'asc',
          params: { date: selectedMonth },
        }, ['GET_REPORTS_REQUEST', 'GET_REPORTS_SUCCESS', 'GET_REPORTS_FAILURE']),
        getListOfVacations(uuid, {
          actor,
          params: {
            status: 'approved',
            rangeDates: enumerateDaysBetweenDates(startMonth, endMonth),
          },
        }, ['GET_VACATIONS_REQUEST', 'GET_VACATIONS_SUCCESS', 'GET_VACATIONS_FAILURE']),
      ]);

      setCurrentMonth(calendarData?.data?.find((el) => el?.params?.date === selectedMonth));
      setVacationsArr(vacationsData?.data);
    };

    fetchCalendarData();
  }, [actor, uuid, partitionUUID, selectedMonth, startMonth, endMonth]);

  useEffect(() => {
    if (!uuid || !showOnboardingTab) return;

    fetchOnboardingData();
  }, [uuid, showOnboardingTab]);

  return (
    <Flex vertical className="w-full h-full">
      <Flex>
        <Flex align="center" className="mx-12">
          <span className="mr-2 text-base">Имя:</span>
          <span className="text-blue-500">
            {artifact?.uinfo?.first_name}
            {' '}
            {artifact?.uinfo?.last_name}
          </span>
        </Flex>
      </Flex>

      <Divider className="my-2" />

      <Flex className="mx-12" align="start">
        <span className="mr-2 text-base">Дневной отчет (сегодня):</span>
        {today?.type ? (
          <Flex vertical className="text-base mr-2 text-blue-500">
            <span className={cn({ 'text-red-500': !isWorkingDay(today?.type) })}>
              {translateTypeDay[today?.type]}
              {renderWorkTime(today)}
            </span>
          </Flex>
        ) : (
          <span className="mr-2 text-base text-red-500">Нет отчета</span>
        )}
        <UpdateDailyReportForm
          report={currentMonth}
          date={dayjs().format('YYYY-MM-DD')}
          isOpen={isOpenEditDay}
          onCancel={() => setIsOpenEditDay(false)}
          onSave={() => {
            fetchInitialData();
            setIsOpenEditDay(false);
          }}
        >
          <PencilEditButton onClick={() => setIsOpenEditDay(true)} />
        </UpdateDailyReportForm>
      </Flex>

      <Divider className="my-2" />

      <Flex className="mx-12 w-full" align="start" vertical>
        <Flex className="mb-3 text-base" align="center">
          Календарь за
          {' '}
          <MonthNavigation
            selectedMonth={selectedMonth}
            onPrevMonth={() => setSelectedMonth(dayjs(selectedMonth).subtract(1, 'month').format('YYYY-MM'))}
            onNextMonth={() => setSelectedMonth(dayjs(selectedMonth).add(1, 'month').format('YYYY-MM'))}
          />
        </Flex>
        <Flex className="w-full">
          <DaysCalendar data={[...data?.values()]} month={selectedMonth} />
        </Flex>
      </Flex>

      <Divider className="my-2" />

      <Flex className="w-full" align="start" vertical>
        <span className="mx-12 mb-3 text-base">
          Доступные месячные отчеты за последние 6 месяцев:
        </span>
        <Table
          rowKey={(row) => row?.actor?.uuid || row}
          columns={TABLE_COLUMNS}
          className="w-full max-w"
          dataSource={reports}
          size="small"
          pagination={false}
        />
      </Flex>
      <Divider className="my-2" />

      {showOnboardingTab && (
        <Flex vertical className="w-full px-12">
          <Tabs
            defaultActiveKey="onboarding"
            items={[
              {
                key: 'onboarding',
                label: (
                  <span>
                    Онбординг
                  </span>
                ),
                children: <OnboardingStatus onboardingList={onboardingData} />,
              },
            ]}
          />
        </Flex>
      )}
    </Flex>
  );
}

UserArtifactView.propTypes = {
  artifact: shape({
    uuid: string,
    actor: string,
    uinfo: shape({
      first_name: string,
      last_name: string,
    }),
  }),
  showOnboardingTab: bool,
};

UserArtifactView.defaultProps = {
  artifact: null,
  showOnboardingTab: false,
};
