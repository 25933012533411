import React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'antd';

function CreateOfficeButton({
  // checkAuthSessionAndRunFuncForRestart,
  onClick,
  className,
}) {
  return (
    <Button
      type="primary"
      // onClick={checkAuthSessionAndRunFuncForRestart}
      onClick={onClick}
      size="small"
      className={className}
    >
      Добавить офис
    </Button>
  );
}

export default CreateOfficeButton;

CreateOfficeButton.propTypes = {
  // checkAuthSessionAndRunFuncForRestart: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
