import React, { useEffect, useState } from 'react';
import {
  Card, Flex, Space, Spin, Table, Tag, Tooltip,
} from 'antd';
import {
  array, bool, func, string,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { omit } from 'lodash';
import CreateOfficeButton from './operations/CreateOfficeButton';
import BaseTableWithPagination from '../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import useOffices from '../../hooks/lists/useOffices';
import { getResetValueForCurrentKey } from '../../redux/reset/resetSelector';
import { DISABLE_RESET_PROPERTY } from '../../redux/reset/resetSlice';
import AntDesignSearchBox54origins from '../../54origins/components/AntDesignSearchBox54origins';

export default function OfficesList({
  name = 'offices_list',
  hideAddOffice,
  parent,
  defaultConfig = {},
  title = 'Офисы',
  cardClassName = '',
  onOfficeSelect,
  onOpenOfficeCreate,
  // onCreateOfficeCallback,
  // checkAuthSessionAndRunFuncForRestart,
}) {
  const dispatch = useDispatch();

  const needReload = useSelector(getResetValueForCurrentKey(name));

  const [valueSearch, onChangeSearch] = useState('');
  const [config, setConfig] = useState({});

  const {
    offices,
    isFetching,
    paginationOptions,
    onReloadOffices,
  } = useOffices({
    parentUUID: parent,
    defaultConfig: {
      ...defaultConfig,
      ...config,
    },
  });

  useEffect(() => {
    if (valueSearch === '') {
      setConfig((prev) => ({ ...omit(prev, 'search_data') }));
    } else {
      setConfig((prev) => ({
        ...prev,
        search_data: {
          fields: {
            params: {
              title: 'title',
            },
          },
          value: valueSearch,
        },
      }));
    }
  }, [valueSearch]);

  const columns = [
    {
      title: <AntDesignSearchBox54origins
        maxWidth={350}
        valueSearch={valueSearch}
        onSearch={onChangeSearch}
      />,
      width: '50%',
      dataIndex: ['params', 'title'],
      key: 'name',
      render: (text) => (
        <Space>
          <span>{text}</span>
        </Space>
      ),
    },
    {
      title: 'Создано',
      key: 'created',
      dataIndex: ['created_at'],
      render: (text) => (
        <span>{dayjs(text).format('DD.MM.YYYY')}</span>
      ),
    },
  ];

  useEffect(() => {
    if (needReload) {
      onReloadOffices();
      dispatch(DISABLE_RESET_PROPERTY(name));
    }
  }, [needReload]);

  return (
    <Card
      className={`w-full p-0 pb-3 h-max blue-base-card ${cardClassName}`}
      title={(
        <Space className="w-full justify-between">
          <Flex className="text-2xl">{title}</Flex>
          {!hideAddOffice && (
            <CreateOfficeButton
              // checkAuthSessionAndRunFuncForRestart={checkAuthSessionAndRunFuncForRestart}
              onClick={onOpenOfficeCreate}
            />
          )}
        </Space>
      )}
    >
      <Flex className="px-2 w-full" vertical>
        <Spin
          className="w-full h-100"
          spinning={isFetching}
        >
          <BaseTableWithPagination
            columns={columns}
            data={offices}
            rowKey="uuid"
            size="small"
            className="w-full h-full !mb-3"
            onRow={(record) => ({
              onClick: () => onOfficeSelect?.(record),
              className: 'cursor-pointer hover:bg-gray-100',
            })}
            pageSizeOptions={[20, 50, 100]}
            useCustomPagination
            disablePagination={false}
            {...paginationOptions}
          />
        </Spin>
      </Flex>
    </Card>
  );
}

OfficesList.propTypes = {
  name: string,
  hideAddOffice: bool,
  parent: string,
  title: string,
  cardClassName: string,
  onOfficeSelect: func,
  onCreateOfficeCallback: func,
};
