import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal } from 'antd';
import Login54Origins from '../../54origins/components/Login54Origins';

import {
  getModalAuthSessionFlag,
  CHANGE_MODAL_GET_AUTH_SESSION,
} from '../../redux/auth/slicers/getAuthSessionModalSlicer';
import { authServiceURL } from '../../api/app_config';

function GetSessionTokenModal({ width = 800 }) {
  const dispatch = useDispatch();

  const isOpen = useSelector(getModalAuthSessionFlag);
  // const isOpenGPT = useSelector(getModalGPTSessionFlag);

  const handleCancel = useCallback(() => {
    dispatch(CHANGE_MODAL_GET_AUTH_SESSION(false));
    // dispatch(CHANGE_MODAL_GET_GPT_SESSION(false));
  }, []);

  return (
    <Modal
      open={isOpen}
      handleCancel={handleCancel}
      width={width}
      destroyOnClose
    >
      <Login54Origins
        url={authServiceURL}
        doNotMakeRedirect
        serviceName="Auth54"
        hiddenElements={['signUpSection']}
        afterSaveSession={handleCancel}
        reloadJsAfterLoadHTML
      />
    </Modal>
  );
}

export default memo(GetSessionTokenModal);
