import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Card, Flex, Select, Form, Input, Button, Space, Tag, Alert,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { TRIGGER_NAMES } from '../../metaevents/triggers';
import axiosRequest from '../../../api/api';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import useOfficesAPI from '../../../api/useOfficesAPI';
import './EditEventMetaForm.scss';

const { TextArea } = Input;

export default function EditEventMetaForm({
  metaEvent,
  onCancel,
  onSubmit,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const partitionUUID = useSelector(getDeliveryPartition);

  const { getListOfOffices } = useOfficesAPI();
  const { getListOfDepartments } = useDepartmentsAPI();

  const [groups, setGroups] = useState([]);
  const [offices, setOffices] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [instructionTitle, setInstructionTitle] = useState(metaEvent.params.instruction_title);
  const [instruction, setInstruction] = useState(metaEvent.params.instruction);
  const [selectedGroups, setSelectedGroups] = useState(metaEvent.params.groups || []);
  const [selectedOffices, setSelectedOffices] = useState(metaEvent.params.offices || []);
  const [selectedDepartments, setSelectedDepartments] = useState(metaEvent.params.departments || []);

  const toggleGroup = (groupUuid) => {
    setSelectedGroups((prev) => {
      if (prev.includes(groupUuid)) {
        return prev.filter((id) => id !== groupUuid);
      }
      return [...prev, groupUuid];
    });
  };

  const toggleOffice = (officeUuid) => {
    setSelectedOffices((prev) => {
      if (prev.includes(officeUuid)) {
        return prev.filter((id) => id !== officeUuid);
      }
      return [...prev, officeUuid];
    });
  };

  const toggleDepartment = (departmentUuid) => {
    setSelectedDepartments((prev) => {
      if (prev.includes(departmentUuid)) {
        return prev.filter((id) => id !== departmentUuid);
      }
      return [...prev, departmentUuid];
    });
  };

  function getListOfAllGroups() {
    return dispatch(axiosRequest.post(
      'utility/actor/list',
      [
        'GET_LIST_OF_GROUPS_REQUEST',
        {
          type: 'GET_LIST_OF_GROUPS_SUCCESS',
          payload: (res) => {
            setGroups(res?.data || []);
            return res?.data || [];
          },
        },
        'GET_LIST_OF_GROUPS_FAILURE',
      ],
      {
        actor_type: 'group',
        not_banned: true,
      },
    ));
  }

  async function getListOfAllOffices() {
    const res = await getListOfOffices(partitionUUID);
    setOffices(res?.data || []);
  }

  async function getListOfAllDepartments() {
    const res = await getListOfDepartments(partitionUUID);
    setDepartments(res?.data || []);
  }

  useEffect(() => {
    getListOfAllGroups();
    getListOfAllOffices();
    getListOfAllDepartments();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      title: metaEvent.params.title,
      instruction_title: metaEvent.params.instruction_title,
      instruction: metaEvent.params.instruction,
      offices: metaEvent.params.offices || [],
      departments: metaEvent.params.departments || [],
      groups: metaEvent.params.groups || [],
    });
  }, [metaEvent]);

  const handleSubmit = (values) => {
    onSubmit({
      ...values,
      trigger: metaEvent.params.trigger,
      groups: selectedGroups,
      offices: selectedOffices,
      departments: selectedDepartments,
      instruction_title: values.instruction_title,
      instruction: values.instruction,
    });
  };

  return (
    <Card
      className="blue-base-card h-fit"
      title={(
        <Flex align="center" gap="small">
          <Icon
            onClick={onCancel}
            path={mdiArrowLeft}
            className="cursor-pointer"
            size={1}
          />
          <h3 className="ml-3 text-2xl m-0">
            Редактирование метасобытия
          </h3>
        </Flex>
      )}
    >
      <Flex vertical gap="large" className="p-6">
        <Flex vertical className="content-section" gap="small">
          <div className="text-lg text-gray-500">Тип триггера</div>
          <Select
            placeholder="Выберите тип триггера"
            className="w-full"
            value={metaEvent.params.trigger}
            disabled
          >
            {Object.entries(TRIGGER_NAMES).map(([value, info]) => (
              <Select.Option key={value} value={value}>
                {info.name}
              </Select.Option>
            ))}
          </Select>
        </Flex>

        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{
            title: metaEvent.params.title,
            instruction_title: metaEvent.params.instruction_title,
            instruction: metaEvent.params.instruction,
            offices: metaEvent.params.offices || [],
            departments: metaEvent.params.departments || [],
            groups: metaEvent.params.groups || [],
          }}
        >
          <Form.Item
            name="title"
            label="Название метасобытия"
            rules={[{ required: true, message: 'Введите название' }]}
          >
            <Input placeholder="Название метасобытия" />
          </Form.Item>

          <div className="p-4 border border-dashed border-gray-400 rounded-md mb-4">
            <Form.Item
              name="instruction_title"
              label="Название инструкции"
              rules={[{ required: true, message: 'Введите название инструкции' }]}
            >
              <Input
                placeholder="Введите название инструкции"
                onChange={(e) => setInstructionTitle(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="instruction"
              label="Инструкция"
              rules={[{ required: true, message: 'Введите инструкцию' }]}
            >
              <TextArea
                rows={6}
                placeholder="Опишите инструкцию для этого метасобытия"
                onChange={(e) => setInstruction(e.target.value)}
              />
            </Form.Item>

            <span className="text-sm text-gray-500 mb-3">Предпросмотр инструкции</span>
            <Alert
              message={instructionTitle || 'Название инструкции не указано'}
              description={(
                <div>
                  <p className="whitespace-pre-wrap">
                    {instruction || 'Инструкция не заполнена'}
                  </p>
                </div>
              )}
              type="info"
              showIcon
            />
          </div>

          <Form.Item
            name="offices"
            label="Офисы"
            rules={[{ validator: () => Promise.resolve() }]}
          >
            <div className="groups-selector">
              {offices.map((office) => (
                <Tag
                  key={office?.uuid}
                  className={`cursor-pointer ${selectedOffices.includes(office?.uuid) ? 'selected' : ''}`}
                  onClick={() => toggleOffice(office?.uuid)}
                >
                  {office?.params?.title}
                </Tag>
              ))}
            </div>
          </Form.Item>

          <Form.Item
            name="departments"
            label="Отделы"
            rules={[{ validator: () => Promise.resolve() }]}
          >
            <div className="groups-selector">
              {departments.map((department) => (
                <Tag
                  key={department?.uuid}
                  className={`cursor-pointer ${selectedDepartments.includes(department?.uuid) ? 'selected' : ''}`}
                  onClick={() => toggleDepartment(department?.uuid)}
                >
                  {department?.params?.title}
                </Tag>
              ))}
            </div>
          </Form.Item>

          <Form.Item
            name="groups"
            label="Группы пользователей"
            rules={[{ validator: () => Promise.resolve() }]}
          >
            <div className="groups-selector">
              {groups.map((group) => (
                <Tag
                  key={group.uuid}
                  className={selectedGroups.includes(group.uuid) ? 'selected' : ''}
                  onClick={() => toggleGroup(group.uuid)}
                >
                  {group.uinfo?.group_name}
                </Tag>
              ))}
            </div>
          </Form.Item>

          <Form.Item className="form-actions">
            <Space>
              <Button onClick={onCancel}>
                Отмена
              </Button>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Flex>
    </Card>
  );
}

EditEventMetaForm.propTypes = {
  metaEvent: PropTypes.shape({
    params: PropTypes.shape({
      title: PropTypes.string,
      instruction: PropTypes.string,
      instruction_title: PropTypes.string,
      trigger: PropTypes.string,
      groups: PropTypes.arrayOf(PropTypes.string),
      offices: PropTypes.arrayOf(PropTypes.string),
      departments: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
