import _ from 'lodash';

export const getUserRootInfo = (state) => _.get(state, 'storages.userRoot', null);

export const getUserRootUUID = (state) => _.get(state, 'storages.userRoot.uuid', null);

export const getUserRootActorUUID = (state) => _.get(state, 'storages.userRoot.actor', null);

export const getUserRootFetching = (state) => _.get(state, 'storages.isFetchingUser', true);

export const getShowLunchBreak = (state) => _.get(state, 'storages.userRoot.params.showLunchBreak', false);

export const getVacationStorageInfo = (state) => _.get(state, 'storages.vacationStorage', null);

export const getVacationStorageUUID = (state) => _.get(state, 'storages.vacationStorage.uuid', null);

export const getVacationStorageFetching = (state) => _.get(state, 'storages.isFetchingVacationSt', true);

export const getMonthlyStorageInfo = (state) => _.get(state, 'storages.monthlyReportsStorage', null);

export const getMonthlyStorageUUID = (state) => _.get(state, 'storages.monthlyReportsStorage.uuid', null);

export const getMonthlyStorageFetching = (state) => _.get(state, 'storages.isFetchingMonthlyReportsSt', true);

export const getEventsStorageInfo = (state) => _.get(state, 'storages.eventsStorage', null);

export const getEventsStorageUUID = (state) => _.get(state, 'storages.eventsStorage.uuid', null);

export const getEventsStorageFetching = (state) => _.get(state, 'storages.isFetchingEventsSt', true);

export const getRequestsStorageInfo = (state) => _.get(state, 'storages.requestsStorage', null);

export const getRequestsStorageUUID = (state) => _.get(state, 'storages.requestsStorage.uuid', null);

export const getRequestsStorageFetching = (state) => _.get(state, 'storages.isFetchingRequestsSt', true);
