import React, { useEffect, useState } from 'react';
import {
  Button, Card, Flex, Spin,
} from 'antd';
import { PlusOutlined, FileTextOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { head } from 'lodash';
import useURLParams from '../../hooks/useURLParams';
import useMetaeventStorage from '../../api/useMetaeventStorage';
import useMetaeventsAPI from '../../api/useMetaeventsAPI';
import { getMetaeventStorageUUID } from '../../redux/metaeventStorage/metaeventStorageSelectors';
import EventTriggersList from '../events/triggers/EventTriggersList';
import EventMetaList from '../events/list/EventMetaList';
import CreateEventMetaForm from '../events/create/CreateEventMetaForm';
import EventsHeader from '../events/list/EventsHeader';
import './css/EventsManagmentView.css';
import { TRIGGER_SECTIONS } from './triggers';
import EditEventMetaForm from '../events/edit/EditEventMetaForm';
import MetaEventCard from '../events/card/MetaEventCard';

function EventsManagmentView() {
  useMetaeventStorage();
  const {
    createMetaevent, getListOfMetaevents, deleteMetaevent, updateMetaevent, readListOfMetaevents,
  } = useMetaeventsAPI();
  const metaeventStorageUUID = useSelector(getMetaeventStorageUUID);
  const { getURLParams, setSearchParams, clearSearchParams } = useURLParams();
  const { metaevent: metaeventUUID } = getURLParams();

  const [selectedTrigger, setSelectedTrigger] = useState(null);
  const [selectedNewTrigger, setSelectedNewTrigger] = useState(null);
  const [metaevents, setMetaevents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMetaEvent, setSelectedMetaEvent] = useState(null);

  const [expandedMetaEvents, setExpandedMetaEvents] = useState({});
  const [isCreatingMode, setIsCreatingMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [filters, setFilters] = useState({
    office: undefined,
    department: undefined,
    groups: [],
  });

  const [editingMetaEvent, setEditingMetaEvent] = useState(null);

  const initMetaevents = async () => {
    if (metaeventStorageUUID) {
      setIsLoading(true);
      try {
        const config = {
          entity_type: 'metaevent',
          params: {},
        };

        if (selectedTrigger?.value) {
          config.params.trigger = selectedTrigger.value;
        }
        if (filters.office) {
          config.params.office = filters.office;
        }

        if (filters.department) {
          config.params.department = filters.department;
        }
        if (filters?.groups?.length) {
          config.params.groups = filters.groups.map((group) => group);
        }
        if (searchValue) {
          config.search_data = {
            fields: {
              params: {
                title: 'title',
              },
            },
            value: searchValue,
          };
        }

        const res = await getListOfMetaevents(metaeventStorageUUID, config);
        setMetaevents(res?.data || []);
      } catch (error) {
        console.error('Error loading metaevents:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const loadMetaEvent = async (uuid) => {
    setIsLoading(true);
    try {
      const res = await readListOfMetaevents(uuid, {
        entity_type: 'metaevent',
        parent: metaeventStorageUUID,
      });
      if (res?.[0]) {
        setSelectedMetaEvent(res?.[0]);
      }
    } catch (error) {
      console.error('Error loading metaevent:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (metaeventUUID) {
      loadMetaEvent(metaeventUUID);
    } else {
      setSelectedMetaEvent(null);
    }
  }, [metaeventUUID]);

  useEffect(() => {
    if (metaeventStorageUUID) {
      initMetaevents();
    }
  }, [selectedTrigger, metaeventStorageUUID, filters, searchValue]);

  const handleTriggerSelect = (trigger) => {
    setMetaevents([]);
    clearSearchParams(['metaevent']);
    setSelectedTrigger(trigger);
    setIsCreatingMode(false);
    setFilters({
      office: undefined,
      department: undefined,
      groups: [],
    });
  };

  const toggleMetaEvent = (metaEventId) => {
    setExpandedMetaEvents((prev) => ({
      ...prev,
      [metaEventId]: !prev[metaEventId],
    }));
  };

  const handleViewMetaEvent = (metaEvent) => {
    console.log('Просмотр метасобытия:', metaEvent);
  };

  const handleEditMetaEvent = async (metaEventId, values) => {
    try {
      await updateMetaevent(metaEventId, {
        entity_type: 'metaevent',
        params: {
          title: values.title,
          instruction: values.instruction,
          instruction_title: values.instruction_title,
          groups: values.groups || [],
          offices: values.offices || [],
          departments: values.departments || [],
        },
      });
      initMetaevents();
    } catch (error) {
      console.error('Error updating metaevent:', error);
    }
  };

  const handleDeleteMetaEvent = async (metaEvent) => {
    clearSearchParams(['metaevent']);
    await deleteMetaevent(metaEvent.uuid);
    initMetaevents();
  };

  const handleCreateMetaevent = async (values) => {
    try {
      const res = await createMetaevent(metaeventStorageUUID, {
        entity_type: 'metaevent',
        params: {
          title: values.title,
          instruction: values.instruction,
          instruction_title: values.instruction_title,
          trigger: values.trigger,
          groups: values.groups,
          offices: values.offices,
          departments: values.departments,
          assigned_office: values.assigned_office,
          assigned_department: values.assigned_office,
        },
      });

      const triggerToSelect = Object.values(TRIGGER_SECTIONS)
        .flatMap((section) => section.triggers)
        .find((trigger) => trigger.value === values.trigger);
      // console.log('res', res);
      // if (triggerToSelect) {
      //   setSelectedTrigger(triggerToSelect);
      setIsCreatingMode(false);
      setSearchParams({ metaevent: head(res)?.uuid });
      setTimeout(() => {
        initMetaevents();
      }, 0);
      // }
    } catch (error) {
      console.error('Error creating metaevent:', error);
    }
  };

  const handleFilterChange = (filterType, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: value,
    }));
  };

  const handleEditClick = (metaEvent) => {
    setSelectedTrigger(null);
    setEditingMetaEvent(metaEvent);
  };

  const handleCloseCard = () => {
    clearSearchParams(['metaevent']);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
  };

  return (
    <Flex className="events-panel mt-0">
      <EventTriggersList
        selectedTrigger={selectedTrigger}
        onTriggerSelect={handleTriggerSelect}
      />

      {isCreatingMode && (
        <CreateEventMetaForm
          selectedTrigger={selectedNewTrigger}
          onCancel={() => setIsCreatingMode(false)}
          onSubmit={handleCreateMetaevent}
          onTriggerChange={setSelectedNewTrigger}
        />
      )}

      {editingMetaEvent && (
        <EditEventMetaForm
          metaEvent={editingMetaEvent}
          onCancel={() => {
            setEditingMetaEvent(null);
            setSelectedTrigger(Object.values(TRIGGER_SECTIONS)
              .flatMap((section) => section.triggers)
              .find((trigger) => trigger.value === editingMetaEvent.params.trigger));
          }}
          onSubmit={async (values) => {
            await handleEditMetaEvent(editingMetaEvent?.uuid, values);
            setEditingMetaEvent(null);
            setSearchParams({ metaevent: editingMetaEvent?.uuid });
            // setSelectedTrigger(Object.values(TRIGGER_SECTIONS)
            //   .flatMap((section) => section.triggers)
            //   .find((trigger) => trigger.value === editingMetaEvent.params.trigger));
          }}
        />
      )}

      {!isCreatingMode && !editingMetaEvent && (
        <div className="meta-events glass-effect">
          {selectedMetaEvent ? (
            <MetaEventCard
              metaEvent={selectedMetaEvent}
              onClose={handleCloseCard}
              onDeleteMetaEvent={handleDeleteMetaEvent}
              onEditMetaEvent={(metaEvent) => {
                handleEditClick(metaEvent);
                clearSearchParams(['metaevent']);
              }}
            />
          ) : (
            <>
              <EventsHeader
                selectedTrigger={selectedTrigger}
                onCreateClick={() => {
                  setIsCreatingMode(true);
                  // setSelectedTrigger(null);
                }}
                onFilterChange={handleFilterChange}
                filters={filters}
                onSearch={handleSearch}
              />
              <EventMetaList
                metaevents={metaevents}
                selectedTrigger={selectedTrigger}
                expandedMetaEvents={expandedMetaEvents}
                onToggleMetaEvent={toggleMetaEvent}
                onViewMetaEvent={handleViewMetaEvent}
                onEditMetaEvent={handleEditClick}
                onDeleteMetaEvent={handleDeleteMetaEvent}
                isLoading={isLoading}
              />
            </>
          )}
        </div>
      )}
    </Flex>
  );
}

export default EventsManagmentView;
