import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Flex } from 'antd';
import { getDeliveryPartition } from '../../redux/config/selectors';
import PageWrapper from '../../components/PageWrapper';
import UniGeneralButtons from '../../components/commonComponents/uniGeneralButtons/UniGeneralButtons';
import RequestsList from '../../components/requests/RequestsList';
import UsersVacationsRequestCard from '../admin/userVacations/UsersVacationsRequestCard';
import UsersAgreedVacationsRequestCard from '../admin/userVacations/UsersAgreedVacationsRequestCard';
import FormCreateUserVacation from '../admin/userVacations/FormCreateUserVacation';

export default function EventsDashboardPage() {
  const partitionUUID = useSelector(getDeliveryPartition);
  const [viewMode, setViewMode] = useState('terminations');

  const tabs = [
    {
      name: 'vacations',
      title: 'Заявки на отпуск',
    },
    {
      name: 'dayoffs',
      title: 'Заявки на отгул',
    },
    {
      name: 'terminations',
      title: 'Заявки на увольнение',
    },
    {
      name: 'other',
      title: 'Прочие заявки',
    },
  ];

  return (
    <PageWrapper
      title="Панель событий"
    >
      <Flex className="mb-4">
        <UniGeneralButtons
          tabs={tabs}
          viewMode={viewMode}
          switchViewFunc={setViewMode}
        />
      </Flex>

      {viewMode === 'terminations' && (
        <RequestsList
          parent={partitionUUID}
          showTerminateButton
          defaultConfig={{
            depth: 0,
            params: {
              type: 'termination_request',
            },
          }}
          title="Заявки на увольнение"
        />
      )}
      {viewMode === 'dayoffs' && (
        <RequestsList
          parent={partitionUUID}
          defaultConfig={{
            depth: 0,
            params: {
              type: 'dayoff_request',
            },
          }}
          title="Заявки на отгул"
        />
      )}
      {viewMode === 'vacations' && (
        <Flex vertical>
          <UsersVacationsRequestCard />
          <UsersAgreedVacationsRequestCard />
          <FormCreateUserVacation />
        </Flex>
      )}
      {viewMode === 'other' && (
        <RequestsList
          showCustomRequestButton
          parent={partitionUUID}
          defaultConfig={{
            depth: 0,
            params: {
              type: 'custom_request',
            },
          }}
          title="Прочие заявки"
        />
      )}
    </PageWrapper>
  );
}
