/* eslint-disable no-await-in-loop */
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, Card, Flex, Form, Input,
} from 'antd';
import { useEffect, useState } from 'react';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { get, head, startsWith } from 'lodash';
import { getOfficesStorageUUID } from '../../../redux/commonStorages/selectors';
import useOfficesAPI from '../../../api/useOfficesAPI';
// import useAuthActorsRequests from '../../auth/hooks/useAuthActorsRequests';
import antNotification from '../../../utils/antNotification';
// import { removeCookie } from '../../../54origins/cookies54origins';
import ActorFormWrapper from '../../auth/ActorFormWrapper';
import ListOfUsersHRM54 from '../../users/ListOfUsersHRM54';
import ListOfCurrentUsers from '../../users/ListOfCurrentUsers';
import { getDefaultDepartmentUUID } from '../../../redux/departments/selectors/defaultDepartmentSelector';
import useOffices from '../../../hooks/lists/useOffices';
import { getDeliveryPartition } from '../../../redux/config/selectors';
// import { synchronizeWithAuthAndGetActor } from '../../../api/actions/actor';

export default function CreateOfficeCard({
  // checkAuthSessionAndRunFuncForRestart,
  onCancelCallback,
  onCreateCallback,
  cardClassName,
  hideBackButton,
}) {
  const dispatch = useDispatch();

  const [actorForm] = Form.useForm();
  const { setFieldsValue, getFieldsValue } = actorForm;
  const partitionUUID = useSelector(getDeliveryPartition);
  const defaultDepartmentUUID = useSelector(getDefaultDepartmentUUID);
  const {
    createOffice,
    // , getListOfOffices
  } = useOfficesAPI();
  const {
    offices,
  } = useOffices({
    parentUUID: partitionUUID,
  });
  const [users, setUsers] = useState([]);
  const [usersUUIDs, setUsersUUIDs] = useState([]);
  // const { requestCreateActorOnAuth } = useAuthActorsRequests();

  const officesStorageUUID = useSelector(getOfficesStorageUUID);

  // const checkRequestError = (err) => {
  //   const { status } = err || {};

  //   if (status === 403 || status === 401) {
  //     removeCookie('Auth');
  //     onCancelCallback();
  //     checkAuthSessionAndRunFuncForRestart(true);
  //   }
  // };

  // const createActorOnAuthAndEntityService = async (data) => {
  //   const newActor = await requestCreateActorOnAuth(data).catch((err) => {
  //     checkRequestError(err);
  //     return err;
  //   });

  //   const newActorUUID = newActor?.actor?.uuid;

  //   if (newActorUUID) {
  //     await dispatch(synchronizeWithAuthAndGetActor(newActorUUID));
  //   }

  //   return newActor;
  // };

  // const getNewWeight = async () => {
  //   const offices = await getListOfOffices(officesStorageUUID, {
  //     limit: 1,
  //     order_by_params: 'id',
  //     order: 'desc',
  //   });
  //   if (get(offices, 'data.[0].params.id', 0) > 0) {
  //     return get(offices, 'data.[0].params.id', 0) + 1;
  //   }
  //   return 1;
  // };

  const onUserSelect = async (value) => {
    if (!usersUUIDs?.includes(value?.actor)) {
      setUsers((prev) => [...prev, value]);
      setUsersUUIDs((prev) => [...prev, value.actor]);
    } else {
      setUsers((prev) => prev.filter((user) => user.actor !== value?.actor));
      setUsersUUIDs((prev) => prev.filter((userUUID) => userUUID !== value?.actor));
    }
  };

  const createUserRequest = async () => {
    try {
      await actorForm.validateFields();

      // let newWeight = await getNewWeight();

      // let newActor = null;
      let newOffice = null;

      // for (let i = 0; i < 50; i++) {
      //   const candidateActor = await createActorOnAuthAndEntityService({
      //     actor_type: 'group',
      //     uinfo: {
      //       group_name: getFieldsValue().name,
      //       weight: newWeight,
      //     },
      //   });
      //   const isWeightError = startsWith(candidateActor?.message, 'weight:');
      //   if (!candidateActor || (candidateActor?.error && !isWeightError)) {
      //     antNotification.error('Ошибка при создании офиса');
      //     return;
      //   }
      //   if (isWeightError) {
      //     newWeight++;
      //   } else {
      //     newActor = candidateActor;
      //     break;
      //   }
      // }

      // if (newActor) {
      newOffice = await createOffice(officesStorageUUID, {
        params: {
          title: getFieldsValue().name,
          description: getFieldsValue().description,
          contractors: users?.map((user) => user?.value),
          // group_uuid: get(newActor, 'actor.uuid', ''),
          // id: newWeight,
        },
      });
      // }

      if (head(newOffice)
      // && newActor
      ) {
        onCreateCallback({
          // actor: newActor,
          office: head(newOffice),
        });
        antNotification.success('Офис успешно создан');
      } else {
        antNotification.error('Ошибка при создании офиса');
      }
    } catch (error) {
      antNotification.error('Ошибка при создании офиса');
    }
  };

  useEffect(() => {
    setFieldsValue({
      internal: true,
    });
    return () => {
      actorForm.resetFields();
    };
  }, []);

  return (
    <Card
      className={`w-full px-0 h-max blue-base-card ${cardClassName}`}
      title={(
        <Flex
          className="w-full cursor-pointer"
          onClick={onCancelCallback}
          align="center"
        >
          {!hideBackButton && <Icon path={mdiArrowLeft} size={0.9} />}
          <span className="text-2xl ml-2">Новый офис</span>
        </Flex>
      )}
    >
      <ActorFormWrapper actorForm={actorForm}>
        <Form.Item
          name="name"
          label="Название офиса"
          className="my-2 mx-4"
          rules={[
            {
              required: true,
              message: 'Пожалуйста, введите название офиса',
            },
          ]}
        >
          <Input
            placeholder="Введите название"
          />
        </Form.Item>

        <Form.Item
          name="description"
          label="Описание"
          className="my-2 mx-4"
        >
          <Input
            placeholder="Введите описание"
          />
        </Form.Item>
        <Flex
            // justify="space-between"
          gap={8}
          className="mx-4 my-2"
        >
          <Flex
            vertical
          >
            <span
              className="text-lg text-gray-500"
            >
              Доступные пользователи:
            </span>
            <ListOfUsersHRM54
              parent={defaultDepartmentUUID}
              selected={usersUUIDs}
              customFilter={(actor) => !offices
                ?.some((item) => item?.params?.contractors?.includes(actor?.value))}
              onUserSelect={onUserSelect}
            />
          </Flex>
          <Flex
            vertical
            className="ml-2"
          >
            <span
              className="text-lg text-gray-500"
            >
              Подрядчики офиса:
            </span>
            <ListOfCurrentUsers
              users={users}
              onUserChange={onUserSelect}
            />
          </Flex>
        </Flex>
        <Flex className="my-4 mx-4" justify="end">
          <Form.Item className="mb-0" shouldUpdate>
            {() => (
              <Button
                type="primary"
                onClick={createUserRequest}
              >
                Создать
              </Button>
            )}
          </Form.Item>
          <Button
            className="button-secondary-outlined ml-2"
            onClick={onCancelCallback}
          >
            Отмена
          </Button>
        </Flex>
      </ActorFormWrapper>
    </Card>
  );
}
