import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { mdiArrowLeft } from '@mdi/js';
import Icon from '@mdi/react';
import {
  Card, Flex, Select, Form, Input, Button, Space, Tag, Alert,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import axiosRequest from '../../../api/api';
import { TRIGGER_NAMES } from '../../metaevents/triggers';
import OfficeSelector from '../../offices/OfficeSelector';
import DepartmentSelector from '../../departments/DepartmentSelector';
import './CreateEventMetaForm.scss';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import useOfficesAPI from '../../../api/useOfficesAPI';
import { getDeliveryPartition } from '../../../redux/config/selectors';

const { TextArea } = Input;

export default function CreateEventMetaForm({
  selectedTrigger,
  onCancel,
  onSubmit,
  onTriggerChange,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { getListOfOffices } = useOfficesAPI();
  const { getListOfDepartments } = useDepartmentsAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [groups, setGroups] = useState([]);
  const [offices, setOffices] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [instructionTitle, setInstructionTitle] = useState('');
  const [instruction, setInstruction] = useState('');
  const [selectedOffices, setSelectedOffices] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  // Для онбординга
  const [onboardingOffice, setOnboardingOffice] = useState(null);
  const [onboardingDepartment, setOnboardingDepartment] = useState(null);

  const isOnboarding = selectedTrigger?.value === 'start-onboarding';

  const handleSubmit = (values) => {
    if (isOnboarding) {
      console.log(onboardingOffice, onboardingDepartment);
      onSubmit({
        ...values,
        trigger: selectedTrigger.value,
        instruction_title: values.instruction_title,
        instruction: values.instruction,
        assigned_office: onboardingOffice,
        title: values.title,
        assigned_department: onboardingDepartment,
      });
    } else {
      onSubmit({
        ...values,
        trigger: selectedTrigger.value,
        groups: selectedGroups,
        offices: selectedOffices,
        departments: selectedDepartments,
        title: values.title,
        instruction_title: values.instruction_title,
        instruction: values.instruction,
      });
    }
    form.resetFields();
  };

  const toggleGroup = (groupUuid) => {
    setSelectedGroups((prev) => {
      if (prev.includes(groupUuid)) {
        return prev.filter((id) => id !== groupUuid);
      }
      return [...prev, groupUuid];
    });
    form.setFieldValue('groups', selectedGroups);
  };

  const toggleOffice = (officeUuid) => {
    if (isOnboarding) {
      console.log(officeUuid);
      setOnboardingOffice((prev) => (prev === officeUuid ? null : officeUuid));
      form.setFieldValue('office', officeUuid);
    } else {
      setSelectedOffices((prev) => {
        if (prev.includes(officeUuid)) {
          return prev.filter((id) => id !== officeUuid);
        }
        return [...prev, officeUuid];
      });
      form.setFieldValue('offices', selectedOffices);
    }
  };

  const toggleDepartment = (departmentUuid) => {
    if (isOnboarding) {
      setOnboardingDepartment((prev) => (prev === departmentUuid ? null : departmentUuid));
      form.setFieldValue('department', departmentUuid);
    } else {
      setSelectedDepartments((prev) => {
        if (prev.includes(departmentUuid)) {
          return prev.filter((id) => id !== departmentUuid);
        }
        return [...prev, departmentUuid];
      });
      form.setFieldValue('departments', selectedDepartments);
    }
  };

  function getListOfAllGroups() {
    return dispatch(axiosRequest.post(
      'utility/actor/list',
      [
        'GET_LIST_OF_GROUPS_REQUEST',
        {
          type: 'GET_LIST_OF_GROUPS_SUCCESS',
          payload: (res) => {
            console.log(res);
            setGroups(res?.data || []);
            return get(res, 'data', res);
          },
        },
        'GET_LIST_OF_GROUPS_FAILURE',
      ],
      {
        actor_type: 'group',
        not_banned: true,
      },
    ));
  }

  async function getListOfAllOffices() {
    const res = await getListOfOffices(partitionUUID);
    setOffices(res?.data || []);
  }

  async function getListOfAllDepartments() {
    const res = await getListOfDepartments(partitionUUID);
    setDepartments(res?.data || []);
  }

  useEffect(() => {
    getListOfAllGroups();
    getListOfAllOffices();
    getListOfAllDepartments();
  }, []);

  return (
    <Card
      className="blue-base-card h-fit"
      title={(
        <Flex align="center" gap="small">
          <Icon
            onClick={onCancel}
            path={mdiArrowLeft}
            className="cursor-pointer"
            size={1}
          />
          <h3 className="ml-3 text-2xl m-0">
            Новое метасобытие
          </h3>
        </Flex>
)}
    >
      <Flex vertical gap="large" className="p-6">
        <Flex vertical className="content-section" gap="small">
          <div className="text-lg text-gray-500">Тип триггера</div>
          <Select
            placeholder="Выберите тип триггера"
            className="w-full"
            onChange={(value) => onTriggerChange({
              ...selectedTrigger,
              value,
            })}
          >
            {Object.entries(TRIGGER_NAMES).map(([value, info]) => (
              <Select.Option key={value} value={value}>
                {info.name}
              </Select.Option>
            ))}
          </Select>
        </Flex>

        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item
            name="title"
            label="Название метасобытия"
            rules={[{ required: true, message: 'Введите название' }]}
          >
            <Input placeholder="Название метасобытия" />
          </Form.Item>

          {isOnboarding && (
            <>
              <Form.Item
                name="office"
                label="Назначить в офис"
                rules={[{ validator: () => Promise.resolve() }]}
              >
                <div className="groups-selector">
                  {offices.map((office) => (
                    <Tag
                      key={office?.uuid}
                      className={onboardingOffice === office?.uuid ? 'selected' : ''}
                      onClick={() => toggleOffice(office?.uuid)}
                    >
                      {office?.params?.title}
                    </Tag>
                  ))}
                </div>
              </Form.Item>

              <Form.Item
                name="department"
                label="Назначить в отдел"
                rules={[{ validator: () => Promise.resolve() }]}
              >
                <div className="groups-selector">
                  {departments.map((department) => (
                    <Tag
                      key={department?.uuid}
                      className={onboardingDepartment === department?.uuid ? 'selected' : ''}
                      onClick={() => toggleDepartment(department?.uuid)}
                    >
                      {department?.params?.title}
                    </Tag>
                  ))}
                </div>
              </Form.Item>
            </>
          )}

          <div className="p-4 border border-dashed border-gray-400 rounded-md mb-4">
            <Form.Item
              name="instruction_title"
              label="Название инструкции"
              rules={[{ required: true, message: 'Введите название инструкции' }]}
            >
              <Input
                placeholder="Введите название инструкции"
                onChange={(e) => setInstructionTitle(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="instruction"
              label="Инструкция"
              rules={[{ required: true, message: 'Введите инструкцию' }]}
            >
              <TextArea
                rows={6}
                placeholder="Опишите инструкцию для этого метасобытия"
                onChange={(e) => setInstruction(e.target.value)}
              />
            </Form.Item>

            <span className="text-sm text-gray-500 mb-3">Предпросмотр инструкции</span>
            <Alert
              message={instructionTitle || 'Название инструкции не указано'}
              description={(
                <div>
                  <p className="whitespace-pre-wrap">
                    {instruction || 'Инструкция не заполнена'}
                  </p>
                </div>
              )}
              type="info"
              showIcon
            />
          </div>

          {!isOnboarding && (
            <>
              <Form.Item
                name="offices"
                label="Офисы"
                rules={[{ validator: () => Promise.resolve() }]}
              >
                <div className="groups-selector">
                  {offices.map((office) => (
                    <Tag
                      key={office?.uuid}
                      className={selectedOffices.includes(office?.uuid) ? 'selected' : ''}
                      onClick={() => toggleOffice(office?.uuid)}
                    >
                      {office?.params?.title}
                    </Tag>
                  ))}
                </div>
              </Form.Item>

              <Form.Item
                name="departments"
                label="Отделы"
                rules={[{ validator: () => Promise.resolve() }]}
              >
                <div className="groups-selector">
                  {departments.map((department) => (
                    <Tag
                      key={department?.uuid}
                      className={selectedDepartments.includes(department?.uuid) ? 'selected' : ''}
                      onClick={() => toggleDepartment(department?.uuid)}
                    >
                      {department?.params?.title}
                    </Tag>
                  ))}
                </div>
              </Form.Item>

              <Form.Item
                name="groups"
                label="Группы пользователей"
                rules={[{ validator: () => Promise.resolve() }]}
              >
                <div className="groups-selector">
                  {groups.map((group) => (
                    <Tag
                      key={group.uuid}
                      className={selectedGroups.includes(group.uuid) ? 'selected' : ''}
                      onClick={() => toggleGroup(group.uuid)}
                    >
                      {group.uinfo?.group_name}
                    </Tag>
                  ))}
                </div>
              </Form.Item>
            </>
          )}

          <Form.Item className="form-actions">
            <Space>
              <Button onClick={onCancel}>
                Отмена
              </Button>
              <Button type="primary" htmlType="submit">
                Создать
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Flex>
    </Card>
  );
}

CreateEventMetaForm.propTypes = {
  selectedTrigger: PropTypes.shape({
    value: PropTypes.string,
  }),
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onTriggerChange: PropTypes.func.isRequired,
};

CreateEventMetaForm.defaultProps = {
  selectedTrigger: null,
};
