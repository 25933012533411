import React from 'react';
import { Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import useDepartmentRequestsAPI from '../../api/useDepartmentRequestsAPI';

export default function CancelRequest({
  uuid, requestStatus, isDepartmentRoutes, onUpdate,
}) {
  const { updateDepartmentRequest } = useDepartmentRequestsAPI();

  const handleCancel = async () => {
    onUpdate?.();
    await updateDepartmentRequest(uuid, {
      params: {
        status: 'rejected',
      },
    });
  };

  if (requestStatus === 'approved' || requestStatus === 'rejected') {
    return null;
  }

  return (
    <Button
      danger
      icon={<CloseOutlined />}
      onClick={handleCancel}
    >
      Отклонить
    </Button>
  );
}

CancelRequest.propTypes = {
  uuid: PropTypes.string.isRequired,
  requestStatus: PropTypes.string.isRequired,
  isDepartmentRoutes: PropTypes.bool.isRequired,
};
