/* eslint-disable default-param-last */

import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { SERVICE_PARTITION_NAME } from './app_config';
import {
  entityCreate, entityDelete, entityRead, entityUpdate, getListAndReadEntities,
} from './actions/entity';
import useLogsAPI from './useLogsAPI';
import antNotification from '../utils/antNotification';

export default function useOfficesAPI() {
  const dispatch = useDispatch();

  const {
    createLog,
  } = useLogsAPI();

  const readListOfOffices = (entityUUIDs, data = {}, constants) => {
    try {
      return dispatch(
        entityRead({
          data: {
            entity_uuid: entityUUIDs,
            entity_type: 'office',
            ...data,
          },
          constants: constants || [
            'READ_LIST_OF_OFFICES_REQUEST',
            'READ_LIST_OF_OFFICES_SUCCESS',
            'READ_LIST_OF_OFFICES_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список офисов!');
      return null;
    }
  };

  const getListOfOffices = (parent, data = {}, constants) => {
    try {
      return dispatch(
        getListAndReadEntities({
          data: {
            entity_type: 'office',
            parent,
            depth: 0,
            ...data,
          },
          constants: constants || [
            'GET_LIST_OF_OFFICES_REQUEST',
            'GET_LIST_OF_OFFICES_SUCCESS',
            'GET_LIST_OF_OFFICES_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список офисов!');
      return null;
    }
  };

  const createOffice = async (parent, data, constants) => {
    try {
      const newOffice = await dispatch(
        entityCreate({
          data: {
            entity_type: 'office',
            parent,
            ...data,
          },
          constants: constants || [
            'CREATE_OFFICE_REQUEST',
            'CREATE_OFFICE_SUCCESS',
            'CREATE_OFFICE_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(newOffice, '[0].uuid', '')) {
        createLog({
          parent: get(newOffice, '[0].uuid', ''),
          type: 'CREATE',
          entityType: 'office',
          values: get(newOffice, '[0].params', ''),
        });
      }

      return newOffice;
    } catch (e) {
      antNotification.error('Не удалось создать офис!');
      return null;
    }
  };

  const updateOffice = async (entityUUID, data, constants) => {
    try {
      const updatedOffice = await dispatch(
        entityUpdate({
          data: {
            entity_type: 'office',
            entity_uuid: entityUUID,
            ...data,
          },
          constants: constants || [
            'UPDATE_OFFICE_REQUEST',
            'UPDATE_OFFICE_SUCCESS',
            'UPDATE_OFFICE_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(updatedOffice, '[0].uuid', '')) {
        createLog({
          parent: get(updatedOffice, '[0].uuid', ''),
          type: 'UPDATE',
          entityType: 'office',
          values: get(data, 'params', {}),
        });
      }

      return updatedOffice;
    } catch (e) {
      antNotification.error('Не удалось изменить офис!');
      return null;
    }
  };

  const deleteOffice = (entityUUID, data, constants) => {
    try {
      return dispatch(
        entityDelete({
          data: {
            entity_type: 'office',
            entity_uuid: entityUUID,
            ...data,
          },
          constants: constants || [
            'DELETE_OFFICE_REQUEST',
            'DELETE_OFFICE_SUCCESS',
            'DELETE_OFFICE_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось удалить офис!');
      return null;
    }
  };

  return {
    createOffice,
    getListOfOffices,
    updateOffice,
    deleteOffice,
    readListOfOffices,
  };
}
