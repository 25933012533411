import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert, Button, Flex, Form, Input, Modal,
} from 'antd';
import { useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiTextBoxPlus } from '@mdi/js';
import { getProfileInfo, isRootOrAdmin } from '../redux/profile/selectors';
import useMetaeventsAPI from '../api/useMetaeventsAPI';
import { getDeliveryPartition } from '../redux/config/selectors';
import useOfficesAPI from '../api/useOfficesAPI';
import useContractorRequestsAPI from '../api/useContractorRequestsAPI';
import { getRequestsStorageUUID } from '../redux/storages/selectors';
import MetaeventLink from '../components/events/MetaeventLink';

const { TextArea } = Input;

export default function useTriggersCustomRequest() {
  const { getListOfMetaevents } = useMetaeventsAPI();
  const { getListOfOffices } = useOfficesAPI();
  const { createContractorRequest } = useContractorRequestsAPI();

  const partitionUUID = useSelector(getDeliveryPartition);
  const profileActor = useSelector(getProfileInfo);
  const requestsStUUID = useSelector(getRequestsStorageUUID);

  const isAdOrRoot = useSelector(isRootOrAdmin);

  const [trigger, setTrigger] = useState('');
  const [options, setOptions] = useState({});

  function CreateCustomRequestComponent() {
    const [isModalOpen, setModalOpen] = useState(true);
    const [form] = Form.useForm();
    const { saveCallback, metaevent } = options;

    useEffect(() => {
      if (!isModalOpen) {
        setTrigger('');
        setOptions({});
      }
    }, [isModalOpen]);

    const handleSubmit = async (values) => {
      const requestData = {
        params: {
          type: 'custom_request',
          userType: values.request_type,
          isCustom: true,
          status: 'pending',
          description: values.description,
        },
      };

      const newRequest = await createContractorRequest(requestsStUUID, requestData);
      saveCallback?.();
      setModalOpen(false);
    };

    return (
      <Modal
        open={isModalOpen}
        title={(
          <Flex align="center" gap="small">
            <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-blue-50">
              <Icon
                path={mdiTextBoxPlus}
                size={0.8}
                className="text-blue-500"
              />
            </div>
            <span className="text-xl font-medium">
              Создать заявку
            </span>
          </Flex>
        )}
        width={600}
        centered
        destroyOnClose
        footer={null}
        onCancel={() => setModalOpen(false)}
        bodyStyle={{ padding: 0 }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Flex vertical gap="middle">
            {metaevent?.params?.instruction && (
              <Alert
                className="-mb-1"
                message={(
                  <Flex justify="space-between" className="w-full">
                    <span className="font-medium">
                      {metaevent?.params?.instruction_title || 'Инструкция по созданию заявки'}
                    </span>
                    {isAdOrRoot && <MetaeventLink metaevent={metaevent} />}
                  </Flex>
                )}
                description={(
                  <Flex vertical className="-mt-4 -mb-2">
                    <pre className="whitespace-pre-wrap font-sans text-sm text-gray-600 leading-relaxed">
                      {metaevent?.params?.instruction}
                    </pre>
                  </Flex>
                )}
                type="info"
                showIcon
                style={{
                  padding: '12px 16px',
                }}
              />
            )}

            <Form.Item
              name="request_type"
              className="mb-0"
              label={<span className="font-medium">Тип заявки</span>}
              rules={[{ required: true, message: 'Пожалуйста, укажите тип заявки' }]}
            >
              <Input placeholder="Например: Заявка на новое оборудование" />
            </Form.Item>

            <Form.Item
              name="description"
              className="mb-0"
              label={<span className="font-medium">Текст заявки</span>}
            >
              <TextArea
                rows={4}
                placeholder="Опишите детали вашей заявки"
                className="resize-none"
              />
            </Form.Item>

            <Form.Item className="mb-0">
              <Flex justify="flex-end" gap="small">
                <Button onClick={() => setModalOpen(false)}>
                  Отмена
                </Button>
                <Button type="primary" htmlType="submit">
                  Создать
                </Button>
              </Flex>
            </Form.Item>
          </Flex>
        </Form>
      </Modal>
    );
  }

  async function run(type, newOptions = {}) {
    const [metaevents, office] = await Promise.all([
      getListOfMetaevents(partitionUUID, {
        depth: 0,
        params: {
          trigger: type,
        },
      }),
      getListOfOffices(partitionUUID, {
        depth: 0,
        params: {
          contractors: [profileActor?.uuid],
        },
      }),
    ]);

    const userGroups = new Set(profileActor?.uinfo?.groups || []);
    const userDepartment = profileActor?.parent;
    const userOffice = office?.data?.[0] || {};

    const selectedMetaevent = metaevents?.data?.reduce((best, current) => {
      let weight = 0;

      if (userOffice && current.params?.office === userOffice?.uuid) {
        weight += 20;
      }

      if (userDepartment && current.params?.department === userDepartment) {
        weight += 10;
      }

      const metaeventGroups = new Set(current.params?.groups || []);
      if (metaeventGroups?.size > 0 && userGroups?.size > 0) {
        const matchingGroups = [...userGroups].filter((group) => metaeventGroups.has(group));
        const matchRatio = matchingGroups?.length / userGroups?.size;
        weight += matchRatio * 5;
      }

      if (!best || weight > best.weight) {
        return { ...current, weight };
      }

      if (weight === best.weight && dayjs(current.created).isAfter(dayjs(best.created))) {
        return { ...current, weight };
      }

      return best;
    }, null);

    setOptions({
      ...newOptions,
      metaevent: selectedMetaevent?.weight > 0
        ? selectedMetaevent
        : null,
    });
    setTrigger(type);
  }

  const EventComponent = useMemo(() => {
    switch (trigger) {
      case 'create-custom-request':
        return CreateCustomRequestComponent;
      default:
        return () => null;
    }
  }, [trigger]);

  return {
    run,
    EventComponent,
  };
}
