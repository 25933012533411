import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert, Button, Flex, Form, Input, Modal, Space,
} from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import { mdiBedKing } from '@mdi/js';
import MetaeventLink from '../components/events/MetaeventLink';
import { getProfileInfo, isRootOrAdmin } from '../redux/profile/selectors';
import useMetaeventsAPI from '../api/useMetaeventsAPI';
import { getDeliveryPartition } from '../redux/config/selectors';

const { TextArea } = Input;

export default function useTriggersSickDay() {
  const { getListOfMetaevents } = useMetaeventsAPI();
  const partitionUUID = useSelector(getDeliveryPartition);
  const profileActor = useSelector(getProfileInfo);
  const isAdOrRoot = useSelector(isRootOrAdmin);

  const [trigger, setTrigger] = useState('');
  const [options, setOptions] = useState({});

  function CreateSickDayComponent() {
    const [isModalOpen, setModalOpen] = useState(true);
    const [form] = Form.useForm();
    const {
      saveCallback, startDate, metaevent,
    } = options;

    useEffect(() => {
      if (!isModalOpen) {
        setTrigger('');
        setOptions({});
      }
    }, [isModalOpen]);

    const handleSubmit = (values) => {
      saveCallback?.('sickday', startDate, {
        comment: values.comment,
      });
      setModalOpen(false);
    };

    return (
      <Modal
        open={isModalOpen}
        title={(
          <Flex align="center" gap="small">
            <span className="text-xl font-medium">Создать больничный</span>
          </Flex>
        )}
        width={600}
        centered
        destroyOnClose
        footer={null}
        onCancel={() => setModalOpen(false)}
        className="sick-day-modal"
        bodyStyle={{ padding: 0 }}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
        //   className="px-6 py-4"
        >
          <Flex vertical gap="middle">
            {metaevent?.params?.instruction && (
              <Alert
                className="-mb-1"
                message={(
                  <Flex justify="space-between" className="w-full">
                    <span className="font-medium">
                      {metaevent?.params?.instruction_title || 'Инструкция по оформлению больничного'}
                    </span>
                    {isAdOrRoot && <MetaeventLink metaevent={metaevent} />}
                  </Flex>
                )}
                description={(
                  <Flex vertical className="-mt-4 -mb-2">
                    <pre className="whitespace-pre-wrap font-sans text-sm text-gray-600 leading-relaxed">
                      {metaevent?.params?.instruction}
                    </pre>
                  </Flex>
                )}
                type="info"
                showIcon
                style={{
                  padding: '12px 16px',
                }}
              />
            )}

            <Form.Item
              name="comment"
              className="-mb-0"
              label={<span className="font-medium">Комментарий</span>}
              rules={[{ required: true, message: 'Пожалуйста, введите комментарий' }]}
            >
              <TextArea
                rows={4}
                placeholder="Укажите причину и другие важные детали"
                className="resize-none"
              />
            </Form.Item>

            <Form.Item className="mb-0">
              <Flex justify="flex-end" gap="small">
                <Button onClick={() => setModalOpen(false)}>
                  Отмена
                </Button>
                <Button type="primary" htmlType="submit">
                  Сохранить
                </Button>
              </Flex>
            </Form.Item>
          </Flex>
        </Form>
      </Modal>
    );
  }

  async function run(type, newOptions = {}) {
    const metaevents = await getListOfMetaevents(partitionUUID, {
      params: {
        trigger: type,
        groups: profileActor?.uinfo?.groups,
      },
    });

    // Получаем группы текущего пользователя
    const userGroups = new Set(profileActor?.uinfo?.groups || []);

    // Находим метасобытие с наибольшим весом через reduce
    const selectedMetaevent = metaevents?.data?.reduce((best, current) => {
      // Вычисляем вес текущего метасобытия
      const metaeventGroups = new Set(current.params?.groups || []);
      let weight = 0;

      if (metaeventGroups.size > 0 && userGroups.size > 0) {
        const matchingGroups = [...metaeventGroups].filter((group) => userGroups.has(group));
        weight = Math.round((matchingGroups.length / metaeventGroups.size) * 10);
      }

      // Если это первое метасобытие или его вес больше предыдущего лучшего
      if (!best || weight > best.weight) {
        return { ...current, weight };
      }

      // Если веса равны, сравниваем по дате создания
      if (weight === best.weight && dayjs(current.created).isAfter(dayjs(best.created))) {
        return { ...current, weight };
      }

      return best;
    }, null);

    setOptions({ ...newOptions, metaevent: selectedMetaevent?.weight > 0 ? selectedMetaevent : null });
    setTrigger(type);
  }

  const EventComponent = useMemo(() => {
    switch (trigger) {
      case 'create-sick-day':
        return CreateSickDayComponent;
      default:
        return () => null;
    }
  }, [trigger]);

  return {
    run,
    EventComponent,
  };
}
