import React from 'react';

import {
  Alert, Col, Typography,
} from 'antd';
import { bool } from 'prop-types';
import { maxCountOfVacationDays, minCountOfDaysBeforeVacation } from '../utils';

const { Paragraph, Text } = Typography;

function ParagraphText({ children }) {
  return <Paragraph className="!mb-0.5">{children}</Paragraph>;
}

export default function InfoCreateVacation({
  showApprovalAdmin = true,
}) {
  return (
    <>
      <Col span={24}>
        <Alert
          message="Общие правила по согласованиям отпусков"
          description={(
            <>
              <ParagraphText>
                1. Запрос на согласование отпуска обязан осуществляться не позже чем
                {' '}
                <b className="text-red-500">
                  за
                  {' '}
                  {minCountOfDaysBeforeVacation}
                  {' '}
                  календарных дней
                </b>
                {' '}
                до планируемой даты начала
                отпуска. Индивидуальные исключения возможны для
                тех кто на хорошем счету у руководства.
              </ParagraphText>
              <ParagraphText>
                2.
                {' '}
                <b
                  className="text-red-500"
                >
                  МАКСИМАЛЬНЫЙ
                </b>
                {' '}
                годовой отпуск для каждого из подрядчиков составляет
                {' '}
                <b
                  className="text-red-500"
                >
                  {' '}
                  {maxCountOfVacationDays}
                  {' '}
                  календарных дней
                </b>
                {' '}
                (25 - 7 дней от новогодних каникул которые идут в отпуск).
                Отгулы в течение года должны включаться в отпуск.
                Отпуск больше 18 календарных дней возможен только на усмотрение руководителя.
              </ParagraphText>
              <ParagraphText style={{ marginBottom: 2 }}>
                3.
                {' '}
                <b
                  className="text-red-500"
                >
                  Внимание!
                </b>
                {' '}
                Компенсация за отпуск рассчитывается по усмотрению руководителя
                и если есть вопросы к качеству вашей работы и
                отношению компенсация может быть ощутимо ниже вилки!
              </ParagraphText>
              <ParagraphText style={{ marginBottom: 2 }}>
                4. Ваш отпуск
                {' '}
                <b>
                  не должен вредить проекту и создавать
                  проблемы вашим коллегам на проекте
                </b>
                .
                Планируя отпуск обсудите его с руководителем и коллегами,
                чтобы не было перехлестов отпусков и других проблем! Относитесь к отпуску разумно!
              </ParagraphText>
              <ParagraphText style={{ marginBottom: 2 }}>
                5. Создавайте и отправляйте заявку на рассмотрение.
                Через 7 дней поинтересуйтесь, согласовали ли вам отпуск.
              </ParagraphText>
              <ParagraphText style={{ marginBottom: 2 }}>
                6. Хорошего отпуска!
              </ParagraphText>
            </>
          )}
          type="info"
          showIcon
          style={{ paddingInline: '16px', paddingBlock: '16px' }}
        />
      </Col>
      {
        showApprovalAdmin ? (
          <Col span={24}>
            <Alert
              message={(
                <Text>
                  Срок рассмотрения и согласования заявок на
                  отпуск осуществляется администратором и
                  <b> занимает до 14 дней</b>
                </Text>
              )}
              type="warning"
            />
          </Col>
        ) : null
      }
    </>
  );
}

InfoCreateVacation.propTypes = {
  showApprovalAdmin: bool,
};
