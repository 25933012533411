import React, { useEffect, useState } from 'react';

import {
  Alert, Button, Form, Input, Segmented, Select, Typography, Flex,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { head } from 'lodash';

import antNotification from '../../../../utils/antNotification';
import { seniorityList, technologies } from '../helpers/constants';

import useAPI from '../../../../api/useAPI';

import { getUserRootInfo } from '../../../../redux/storages/selectors';
import { SET_ROOT_USER } from '../../../../redux/storages/storagesSlice';
import EditButton from '../../../../components/commonComponents/entityCardComponents/EditButton';

const { Text } = Typography;

// const { TextArea } = Input;

// const initProfileValues = {
//   specialization_1: '',
//   specialization_2: '',
//   expertise: [],
//   seniority: '',
//   education: '',
//   english: '',
//   birthdate: '',
//   preferred_working_hours: '',
//   is_init: true,
// };

export default function ProfileForm({ form }) {
  const dispatch = useDispatch();

  const rootUser = useSelector(getUserRootInfo);

  const { updateDeliveryUser } = useAPI();

  const [hasAdditionalSpec, setAdditionalSpec] = useState(false);

  const [editFields, setEditFields] = useState({
    education: false,
    english: false,
    birthdate: false,
    preferred_working_hours: false,
  });

  const toggleEdit = (field) => {
    setEditFields((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleValueChange = async (field, value) => {
    try {
      const res = await updateDeliveryUser({
        entity_uuid: rootUser?.uuid,
        params: {
          [field]: value,
        },
      });

      if (head(res)) {
        dispatch(SET_ROOT_USER(head(res)));
        antNotification.success('Поле успешно обновлено');
      }
    } catch (error) {
      antNotification.error('Ошибка при обновлении поля');
    }
  };

  const renderEditableField = (field, label, placeholder) => {
    const [value, setValue] = useState(rootUser?.params?.[field] || '');

    useEffect(() => {
      setValue(rootUser?.params?.[field] || '');
    }, [rootUser?.params?.[field]]);

    return (
      <Form.Item
        label={<Text>{label}</Text>}
        className="mb-0"
      >
        <Flex align="center" className="w-full">
          <EditButton
            isEditFlag={editFields[field]}
            onClick={(e, closeEdit) => {
              if (closeEdit) {
                setValue(rootUser?.params?.[field] || '');
              } else if (editFields[field]) {
                handleValueChange(field, value);
              }
              toggleEdit(field);
            }}
          />
          {editFields[field] ? (
            <Input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder={placeholder}
              className="ml-2"
              style={{ width: 350 }}
            />
          ) : (
            <Text className="ml-2 text-blue-500">
              {rootUser?.params?.[field] || 'Не указано'}
            </Text>
          )}
        </Flex>
      </Form.Item>
    );
  };

  const options = [{ label: 'ReactJS', value: 'reactjs' }];

  return (
    <Form
      preserve={false}
      name="basic"
      labelCol={{ span: 6 }}
      autoComplete="off"
    >
      <Form.Item
        wrapperCol={{ offset: 6 }}
      >
        <Alert
          message="Есть дополнительная специализация?"
          action={(
            <Button
              size="small"
              type="primary"
              ghost
              onClick={() => setAdditionalSpec(!hasAdditionalSpec)}
            >
              {!hasAdditionalSpec ? 'Да, добавить' : 'Отменить'}
            </Button>
          )}
          type="info"
          showIcon
        />
      </Form.Item>

      <Form.Item
        label={<Text>Специализация</Text>}
      >
        <Segmented
          options={technologies}
          value={rootUser?.params?.specialization_1}
          onChange={(value) => handleValueChange('specialization_1', value)}
        />
      </Form.Item>

      {hasAdditionalSpec && (
        <Form.Item
          label={<Text>Специализация 2</Text>}
        >
          <Segmented
            options={technologies}
            value={rootUser?.params?.specialization_2}
            onChange={(value) => handleValueChange('specialization_2', value)}
          />
        </Form.Item>
      )}

      <Form.Item
        label={<Text>Уровень</Text>}
      >
        <Segmented
          options={seniorityList}
          value={rootUser?.params?.seniority}
          onChange={(value) => handleValueChange('seniority', value)}
        />
      </Form.Item>

      <Form.Item
        label={<Text>Навыки</Text>}
        wrapperCol={{ span: 14 }}
      >
        <Select
          mode="multiple"
          allowClear
          style={{ width: '100%' }}
          placeholder="Выберите навыки"
          value={rootUser?.params?.expertise}
          onChange={(value) => handleValueChange('expertise', value)}
          options={options}
        />
      </Form.Item>

      {renderEditableField('education', 'Образование', 'Укажите учебное заведение, специальность, годы обучения')}
      {renderEditableField('english', 'Уровень английского', 'Укажите уровень английского')}
      {renderEditableField('birthdate', 'Дата рождения', 'Укажите дату рождения')}
      {renderEditableField('preferred_working_hours', 'Предпочитаемые рабочие часы', 'Укажите предпочитаемые часы работы')}
    </Form>
  );
}
