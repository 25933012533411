import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { string } from 'prop-types';
import DepartmentRequestForm from '../../commonComponents/entityCardComponents/DepartmentRequestForm';

export default function CreateDepartmentRequestButton({ departmentUUID, onCreate }) {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <>
      <Button
        size="small"
        onClick={openModal}
        className="ml-2 mt-1 !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white"
      >
        Создать заявку отдела
      </Button>
      <Modal
        open={isOpen}
        title="Создать заявку отдела"
        onCancel={closeModal}
        footer={null}
        centered
        width={730}
        destroyOnClose
      >
        <DepartmentRequestForm
          departmentUUID={departmentUUID}
          closeModal={closeModal}
          onCreate={onCreate}
        />
      </Modal>
    </>
  );
}

CreateDepartmentRequestButton.propTypes = {
  departmentUUID: string.isRequired,
};
