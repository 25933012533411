import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { SERVICE_PARTITION_NAME } from './app_config';
import {
  entityCreate, entityDelete, entityRead, entityUpdate, getListAndReadEntities,
} from './actions/entity';
import useLogsAPI from './useLogsAPI';
import antNotification from '../utils/antNotification';

export default function useDepartmentRequestsAPI() {
  const dispatch = useDispatch();
  const { createLog } = useLogsAPI();

  const readListOfDepartmentRequests = (entityUUIDs, data = {}, constants) => {
    try {
      return dispatch(
        entityRead({
          data: {
            entity_uuid: entityUUIDs,
            entity_type: 'department_request',
            ...data,
          },
          constants: constants || [
            'READ_LIST_OF_DEPARTMENT_REQUESTS_REQUEST',
            'READ_LIST_OF_DEPARTMENT_REQUESTS_SUCCESS',
            'READ_LIST_OF_DEPARTMENT_REQUESTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список заявок отдела!');
      return null;
    }
  };

  const getListOfDepartmentRequests = (parent, data = {}, constants) => {
    try {
      return dispatch(
        getListAndReadEntities({
          data: {
            entity_type: 'department_request',
            parent,
            depth: 0,
            ...data,
          },
          constants: constants || [
            'GET_LIST_OF_DEPARTMENT_REQUESTS_REQUEST',
            'GET_LIST_OF_DEPARTMENT_REQUESTS_SUCCESS',
            'GET_LIST_OF_DEPARTMENT_REQUESTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список заявок отдела!');
      return null;
    }
  };

  const createDepartmentRequest = async (parent, data, constants) => {
    try {
      const newRequest = await dispatch(
        entityCreate({
          data: {
            entity_type: 'department_request',
            parent,
            ...data,
          },
          constants: constants || [
            'CREATE_DEPARTMENT_REQUEST_REQUEST',
            'CREATE_DEPARTMENT_REQUEST_SUCCESS',
            'CREATE_DEPARTMENT_REQUEST_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(newRequest, '[0].uuid')) {
        createLog({
          parent: get(newRequest, '[0].uuid'),
          type: 'CREATE',
          entityType: 'department_request',
          values: get(newRequest, '[0].params', ''),
        });
      }

      return newRequest;
    } catch (e) {
      antNotification.error('Не удалось создать заявку отдела!');
      return null;
    }
  };

  const updateDepartmentRequest = async (entityUUID, data, constants) => {
    try {
      const updatedRequest = await dispatch(
        entityUpdate({
          data: {
            entity_type: 'department_request',
            entity_uuid: entityUUID,
            ...data,
          },
          constants: constants || [
            'UPDATE_DEPARTMENT_REQUEST_REQUEST',
            'UPDATE_DEPARTMENT_REQUEST_SUCCESS',
            'UPDATE_DEPARTMENT_REQUEST_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(updatedRequest, '[0].uuid')) {
        createLog({
          parent: get(updatedRequest, '[0].uuid'),
          type: 'UPDATE',
          entityType: 'department_request',
          values: get(data, 'params', {}),
        });
      }

      return updatedRequest;
    } catch (e) {
      antNotification.error('Не удалось изменить заявку отдела!');
      return null;
    }
  };

  const deleteDepartmentRequest = (entityUUID, data, constants) => {
    try {
      return dispatch(
        entityDelete({
          data: {
            entity_type: 'department_request',
            entity_uuid: entityUUID,
            ...data,
          },
          constants: constants || [
            'DELETE_DEPARTMENT_REQUEST_REQUEST',
            'DELETE_DEPARTMENT_REQUEST_SUCCESS',
            'DELETE_DEPARTMENT_REQUEST_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось удалить заявку отдела!');
      return null;
    }
  };

  return {
    createDepartmentRequest,
    readListOfDepartmentRequests,
    getListOfDepartmentRequests,
    updateDepartmentRequest,
    deleteDepartmentRequest,
  };
}
