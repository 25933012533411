import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Button, Flex, Input, Typography, Select, Form, Space,
} from 'antd';
import {
  bool, func, shape, string,
} from 'prop-types';
import useDepartmentRequestsAPI from '../../../api/useDepartmentRequestsAPI';

const { TextArea } = Input;
const { Text } = Typography;

const REQUEST_TYPES = [
  { value: 'contractor', label: 'Заявка на подрядчика' },
  { value: 'common', label: 'Общая заявка' },
];

export default function DepartmentRequestForm({
  departmentUUID, requestUUID, params, closeModal, isEditMode, onCreate,
}) {
  const { createDepartmentRequest, updateDepartmentRequest } = useDepartmentRequestsAPI();
  const [form] = Form.useForm();

  useEffect(() => {
    if (!isEmpty(params)) {
      form.setFieldsValue({
        description: params.description,
        type: params.type || 'common',
      });
    }
  }, [params]);

  const onFinish = async (values) => {
    if (isEditMode) {
      await updateDepartmentRequest(requestUUID, {
        params: {
          ...params,
          ...values,
        },
      });
    } else {
      const newRequest = {
        entity_type: 'department_request',
        params: {
          status: 'pending',
          ...values,
        },
      };
      await createDepartmentRequest(departmentUUID, newRequest);
    }
    form.resetFields();
    closeModal();
    onCreate();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        type: 'common',
      }}
    >
      <Form.Item
        name="type"
        label="Тип заявки"
        rules={[{ required: true, message: 'Выберите тип заявки' }]}
      >
        <Select
          options={REQUEST_TYPES}
          placeholder="Выберите тип заявки"
          className="w-full"
        />
      </Form.Item>

      <Form.Item
        name="description"
        label="Описание заявки"
        rules={[
          { required: true, message: 'Введите описание заявки' },
        ]}
      >
        <TextArea
          placeholder="Введите подробное описание заявки..."
          style={{ height: 200, resize: 'none' }}
          showCount
          maxLength={1000}
        />
      </Form.Item>

      <Form.Item className="mb-0">
        <Flex justify="end" gap="small">
          <Button
            danger
            onClick={closeModal}
          >
            Отмена
          </Button>
          <Button
            type="primary"
            htmlType="submit"
          >
            {isEditMode ? 'Сохранить' : 'Создать'}
          </Button>
        </Flex>
      </Form.Item>
    </Form>
  );
}

DepartmentRequestForm.propTypes = {
  departmentUUID: string,
  requestUUID: string,
  closeModal: func.isRequired,
  isEditMode: bool,
  params: shape({
    description: string,
    status: string,
    type: string,
  }),
};

DepartmentRequestForm.defaultProps = {
  departmentUUID: '',
  requestUUID: '',
  isEditMode: false,
  params: {},
};
