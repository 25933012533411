import React, { useEffect, useState } from 'react';
import {
  Card, Flex, Spin, Tag, Tooltip, Popover,
  Button,
} from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import {
  bool, func, string, arrayOf, shape,
} from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiCommentTextOutline } from '@mdi/js';

import BaseTableWithPagination from '../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import useURLParams from '../../hooks/useURLParams';
import useRequests from '../../redux/requests/hooks/useRequests';
import { getResetValueForCurrentKey } from '../../redux/reset/resetSelector';
import { DISABLE_RESET_PROPERTY } from '../../redux/reset/resetSlice';
import AntDesignSearchBox54origins from '../../54origins/components/AntDesignSearchBox54origins';
import useTriggersTermination from '../../events/useTriggersTermination';
import { getDeliveryPartition } from '../../redux/config/selectors';
import useTerminationAPI from '../../api/useTerminationAPI';
import { getProfileInfo } from '../../redux/profile/selectors';
import useTriggersCustomRequest from '../../events/useTriggersCustomRequest';
import useContractorRequestsAPI from '../../api/useContractorRequestsAPI';

export default function RequestsList({
  name = 'requests_list',
  parent,
  defaultConfig = {},
  title = 'Заявки',
  cardClassName = '',
  onRequestClick,
  hideSearch = false,
  showTerminateButton,
  showCustomRequestButton,
  showCreateTerminateButton,
  showCreateCustomRequestButton,
  // additionalColumns = [],
}) {
  const dispatch = useDispatch();
  const needReload = useSelector(getResetValueForCurrentKey(name));

  const partitionUUID = useSelector(getDeliveryPartition);

  const profileInfo = useSelector(getProfileInfo);

  const { getListOfTermination } = useTerminationAPI();
  const { updateContractorRequest } = useContractorRequestsAPI();

  const {
    run: runTermination,
    EventComponent: EventComponentTermination,
  } = useTriggersTermination();

  const {
    run: runCompleteTermination,
    EventComponent: EventComponentCompleteTermination,
  } = useTriggersTermination();

  const {
    run: runTerminationRequest,
    EventComponent: EventComponentTerminationRequest,
  } = useTriggersTermination();

  const {
    run: runCustomRequest,
    EventComponent: EventComponentCustomRequest,
  } = useTriggersCustomRequest();

  const {
    requests,
    isFetching,
    paginationOptions,
    onReloadRequests,
    valueSearch,
    onChangeSearch,
  } = useRequests({
    parentUUID: parent,
    defaultConfig,
  });

  const { setSearchParams, getURLParams } = useURLParams();
  const { activeRequest } = getURLParams();

  const [terminationStatuses, setTerminationStatuses] = useState({});

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === activeRequest,
  });
  const loadTerminationStatuses = async () => {
    const statuses = {};

    const res = await getListOfTermination(partitionUUID, {
      params: {
        status: 'pending',
      },
    });

    res?.data?.map((el) => {
      statuses[el?.parent] = el || null;
    });

    setTerminationStatuses(statuses);
  };
  const columns = [
    {
      key: 'initiator',
      title: 'Подрядчик',
      dataIndex: ['uinfo'],
      width: '20%',
      render: (initiator) => (
        <div>
          <div className="font-medium">{initiator?.first_name}</div>
          <div className="font-medium">{initiator?.last_name}</div>
        </div>
      ),
    },
    {
      key: 'type',
      title: 'Тип заявки',
      dataIndex: ['params', 'type'],
      width: '15%',
      render: (type, record) => {
        const typeLabels = {
          termination_request: 'Увольнение',
          dayoff_request: 'Отгул',
          vacation_request: 'Отпуск',
          custom_request: record?.params?.userType || 'Пользовательская',
        };

        return typeLabels[type] || type;
      },
    },
    {
      key: 'comment',
      title: 'Текст заявки',
      dataIndex: ['params', 'description'],
      width: '15%',
      render: (description, record) => (
        <Popover
          content={(
            <div className="max-w-md">
              {record?.params?.type === 'custom_request' && (
                <div className="mb-2">
                  <span className="font-medium">Тип заявки: </span>
                  <span>{record?.params?.userType}</span>
                </div>
              )}
              <div className="whitespace-pre-wrap">
                {description}
              </div>
            </div>
          )}
          title="Детали заявки"
          trigger="hover"
          placement="right"
        >
          <div className="flex items-center text-gray-500 hover:text-gray-700">
            <Icon
              path={mdiCommentTextOutline}
              size={0.8}
            />
          </div>
        </Popover>
      ),
    },
    {
      key: 'status',
      title: 'Статус',
      dataIndex: ['params', 'status'],
      width: '15%',
      render: (status) => {
        const statusConfig = {
          pending: {
            color: 'gold',
            text: 'На рассмотрении',
          },
          completed: {
            color: 'green',
            text: 'Завершено',
          },
          rejected: {
            color: 'red',
            text: 'Отклонено',
          },
        };

        const config = statusConfig[status] || statusConfig.pending;

        return (
          <Tag color={config.color}>
            {config.text}
          </Tag>
        );
      },
    },
    {
      key: 'created',
      title: 'Создано',
      dataIndex: 'created',
      width: '25%',
      render: (date) => dayjs(date).format('DD.MM.YYYY HH:mm'),
    },
    {
      title: 'Действия',
      key: 'actions',
      width: '20%',
      render: (_, record) => (
        <Flex>
          {showTerminateButton && (
          <Flex gap="small">
            {terminationStatuses?.[record?.actor] ? (
              <Button
                size="small"
                className="text-red-600 border-red-600 hover:!text-red-500 hover:!border-red-500"
                onClick={() => runCompleteTermination('complete-termination', {
                  user: record,
                  onComplete: () => {
                    onReloadRequests();
                    loadTerminationStatuses();
                  },
                })}
              >
                Завершить увольнение
              </Button>
            ) : (
              <Button
                size="small"
                danger
                onClick={() => runTermination('start-termination', {
                  user: record,
                  onComplete: () => {
                    onReloadRequests();
                    loadTerminationStatuses();
                  },
                })}
              >
                Согласовать увольнение
              </Button>
            )}
          </Flex>
          )}
          {showCustomRequestButton && record?.params?.status === 'pending' && (
            <Flex gap="small">
              <Button
                size="small"
                className="text-blue-600 border-blue-600 hover:!text-blue-500 hover:!border-blue-500"
                onClick={async () => {
                  await updateContractorRequest(record?.uuid, {
                    params: {
                      status: 'completed',
                      reviewer: {
                        actor: profileInfo?.uuid,
                        first_name: profileInfo?.uinfo?.first_name,
                        last_name: profileInfo?.uinfo?.last_name,
                        date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                      },
                    },
                  });
                  onReloadRequests();
                }}
              >
                Согласовать заявку
              </Button>
              <Button
                size="small"
                className="text-orange-600 border-orange-600 hover:!text-orange-500 hover:!border-orange-500"
                onClick={async () => {
                  await updateContractorRequest(record?.uuid, {
                    params: {
                      status: 'rejected',
                      reviewer: {
                        actor: profileInfo?.uuid,
                        first_name: profileInfo?.uinfo?.first_name,
                        last_name: profileInfo?.uinfo?.last_name,
                        date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                      },
                    },
                  });
                  onReloadRequests();
                }}
              >
                Отклонить заявку
              </Button>
            </Flex>
          )}
        </Flex>
      ),
    },
  ];

  const onRow = (record) => ({
    onClick: () => {
      if (onRequestClick) {
        onRequestClick(record);
      } else {
        setSearchParams({ request: record.uuid });
      }
    },
  });

  useEffect(() => {
    if (needReload) {
      onReloadRequests();
      dispatch(DISABLE_RESET_PROPERTY(name));
    }
  }, [needReload]);

  useEffect(() => {
    loadTerminationStatuses();
  }, []);

  return (
    <Card
      className={`w-full p-0 pb-3 h-max blue-base-card ${cardClassName}`}
      title={(
        <Flex
          align="center"
        >
          <Flex
            className="text-xl"
          >
            {title}
          </Flex>
          {showCreateTerminateButton && (
            <Button
              // danger
              // type="primary"
              size="small"
              className="ml-4 bg-white border-blue-500 text-blue-500"
              onClick={() => {
                runTerminationRequest('create-termination-request', {
                  user: profileInfo,
                  saveCallback: () => {
                    onReloadRequests();
                  },
                });
              }}
            >
              Создать заявку на увольнение
            </Button>
          )}
          {showCreateCustomRequestButton && (
            <Button
              size="small"
              className="ml-4 bg-white border-blue-500 text-blue-500"
              onClick={() => {
                runCustomRequest('create-custom-request', {
                  user: profileInfo,
                  saveCallback: () => {
                    onReloadRequests();
                  },
                });
              }}
            >
              Создать заявку
            </Button>
          )}
        </Flex>
      )}
    >
      <Flex className="px-2 w-full" vertical>
        <Spin
          className="w-full h-100"
          spinning={isFetching}
        >
          <BaseTableWithPagination
            columns={columns}
            data={requests}
            rowKey="uuid"
            size="small"
            className="w-full h-full !mb-3"
            onRow={onRow}
            pageSizeOptions={[20, 50, 100]}
            rowClassName={rowClassName}
            useCustomPagination
            disablePagination={false}
            {...paginationOptions}
          />
        </Spin>
      </Flex>
      <EventComponentTermination />
      <EventComponentCompleteTermination />
      <EventComponentTerminationRequest />
      <EventComponentCustomRequest />
    </Card>
  );
}

RequestsList.propTypes = {
  parent: string.isRequired,
  title: string,
  cardClassName: string,
  name: string,
  onRequestClick: func,
  hideSearch: bool,
  additionalColumns: arrayOf(shape({
    key: string.isRequired,
    title: string.isRequired,
    dataIndex: arrayOf(string),
    width: string,
    render: func,
  })),
};

RequestsList.defaultProps = {
  title: 'Заявки',
  cardClassName: '',
  name: 'requests_list',
  onRequestClick: null,
  hideSearch: false,
  additionalColumns: [],
};
