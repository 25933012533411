import React, { useEffect } from 'react';
import { Flex } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ArtifactsList from '../../components/artifacts/ArtifactsList';
import PageWrapper from '../../components/PageWrapper';
import DepartmentsList from '../../components/departments/DepartmentsList';
import { getUserRootActorUUID } from '../../redux/storages/selectors';
import { getDepartment, getDepartmentUUID } from '../../redux/departments/selectors/departmentsSelector';
import useURLParams from '../../hooks/useURLParams';
import {
  GET_SINGLE_DEPARTMENT_FAILURE, GET_SINGLE_DEPARTMENT_REQUEST, GET_SINGLE_DEPARTMENT_SUCCESS, RESET_SINGLE_DEPARTMENT,
} from '../../redux/departments/slices/departmentsSlice';
import DepartmentReports from '../../components/departments/DepartmentReportsList';
import useDepartmentsAPI from '../../api/useDepartmentsAPI';

export default function DepartmentReportsPage() {
  const dispatch = useDispatch();

  const userActorUUID = useSelector(getUserRootActorUUID);

  const department = useSelector(getDepartment);
  const departmentUUID = useSelector(getDepartmentUUID);

  const { getURLParams, clearSearchParams, setSearchParams } = useURLParams();

  const { readListOfDepartments } = useDepartmentsAPI();

  const {
    department: activeDepartment,
    artifact: activeArtifact,
  } = getURLParams();

  useEffect(() => {
    if (activeDepartment) {
      readListOfDepartments(activeDepartment, {}, [
        GET_SINGLE_DEPARTMENT_REQUEST,
        GET_SINGLE_DEPARTMENT_SUCCESS,
        GET_SINGLE_DEPARTMENT_FAILURE,
      ]);
    } else {
      dispatch(RESET_SINGLE_DEPARTMENT);
    }
  }, [activeDepartment]);

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Отчеты отдела
        </Flex>
      )}
    >
      <DepartmentsList
        hideAddButton
        hideKPIButton
        hideExpand
        // defaultConfig={{
        //   params: {
        //     usersSearch: [userActorUUID],
        //   },
        // }}
        name="department_list"
        currentDepartment={department}
      />
      {activeDepartment && (
      <DepartmentReports
        department={department}
        departmentUUID={departmentUUID}
        className="mt-2"
      />
      )}
    </PageWrapper>
  );
}
