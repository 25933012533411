import { useDispatch } from 'react-redux';
import { get, omit } from 'lodash';
import useLogsAPI from './useLogsAPI';
import {
  entityCreate, entityDelete, entityRead, entityUpdate, getListAndReadEntities,
} from './actions/entity';
import { SERVICE_PARTITION_NAME } from './app_config';
import antNotification from '../utils/antNotification';

export default function useOnboardingAPI() {
  const dispatch = useDispatch();

  const {
    createLog,
  } = useLogsAPI();

  const readListOfOnboarding = (entityUUIDs, data = {}, constants) => {
    try {
      return dispatch(
        entityRead({
          data: {
            entity_type: 'onboarding',
            entity_uuid: entityUUIDs,
            ...data,
          },
          constants: constants || [
            'READ_LIST_OF_ONBOARDING_REQUEST',
            'READ_LIST_OF_ONBOARDING_SUCCESS',
            'READ_LIST_OF_ONBOARDING_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список онбордингов!');
      return null;
    }
  };

  const getListOfOnboarding = (parent, data = {}, constants) => {
    try {
      return dispatch(
        getListAndReadEntities({
          data: {
            entity_type: 'onboarding',
            parent,
            depth: 0,
            ...data,
          },
          constants: constants || [
            'GET_LIST_OF_ONBOARDING_REQUEST',
            'GET_LIST_OF_ONBOARDING_SUCCESS',
            'GET_LIST_OF_ONBOARDING_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список онбордингов!');
      return null;
    }
  };

  const createOnboarding = async (parent, data, constants) => {
    try {
      const newOnboardingConfig = {
        parent,
        entity_type: 'onboarding',
        ...omit(data, ['files']),
      };

      const newFormData = new FormData();
      newFormData.append('data', JSON.stringify(newOnboardingConfig));

      if (data?.files?.length) {
        data.files.forEach((file) => {
          newFormData.append('file', file);
        });
      }

      const newOnboarding = await dispatch(
        entityCreate({
          data: newFormData,
          constants: constants || [
            'CREATE_ONBOARDING_REQUEST',
            'CREATE_ONBOARDING_SUCCESS',
            'CREATE_ONBOARDING_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(newOnboarding, '[0].uuid')) {
        createLog({
          parent: get(newOnboarding, '[0].uuid'),
          type: 'CREATE',
          entityType: 'onboarding',
          values: get(newOnboarding, '[0].params', ''),
          files: data?.files || [],
        });
      }

      return newOnboarding;
    } catch (e) {
      antNotification.error('Не удалось создать онбординг!');
      return null;
    }
  };

  const updateOnboarding = async (entityUUID, data, constants) => {
    try {
      const updateOnboardingConfig = {
        entity_uuid: entityUUID,
        entity_type: 'onboarding',
        ...omit(data, ['files']),
      };

      const newFormData = new FormData();
      newFormData.append('data', JSON.stringify(updateOnboardingConfig));

      if (data?.files?.length) {
        data.files.forEach((file) => {
          newFormData.append('file', file);
        });
      }

      const updatedOnboarding = await dispatch(
        entityUpdate({
          data: newFormData,
          constants: constants || [
            'UPDATE_ONBOARDING_REQUEST',
            'UPDATE_ONBOARDING_SUCCESS',
            'UPDATE_ONBOARDING_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(updatedOnboarding, '[0].uuid')) {
        createLog({
          parent: get(updatedOnboarding, '[0].uuid'),
          type: 'UPDATE',
          entityType: 'onboarding',
          values: get(data, 'params', {}),
          files: data?.files || [],
        });
      }

      return updatedOnboarding;
    } catch (e) {
      antNotification.error('Не удалось изменить онбординг!');
      return null;
    }
  };

  const deleteOnboarding = (entityUUID, data, constants) => {
    try {
      return dispatch(
        entityDelete({
          data: {
            entity_uuid: entityUUID,
            entity_type: 'onboarding',
            ...data,
          },
          constants: constants || [
            'DELETE_ONBOARDING_REQUEST',
            'DELETE_ONBOARDING_SUCCESS',
            'DELETE_ONBOARDING_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось удалить онбординг!');
      return null;
    }
  };

  return {
    createOnboarding,
    readListOfOnboarding,
    getListOfOnboarding,
    updateOnboarding,
    deleteOnboarding,
  };
}
