import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Button } from 'antd';
import { getDeliveryPartition } from '../../redux/config/selectors';
import { getProfileUUID, getProfileInfo } from '../../redux/profile/selectors';
import PageWrapper from '../../components/PageWrapper';
import UniGeneralButtons from '../../components/commonComponents/uniGeneralButtons/UniGeneralButtons';
import RequestsList from '../../components/requests/RequestsList';
import { getUserRootUUID } from '../../redux/storages/selectors';
import RequestVacationsCard from './vacationsRequests/RequestVacationsCard';
import useTriggersTermination from '../../events/useTriggersTermination';

export default function MyRequestsPage() {
  const partitionUUID = useSelector(getDeliveryPartition);
  const actor = useSelector(getProfileUUID);
  const rootUserUUID = useSelector(getUserRootUUID);

  const [viewMode, setViewMode] = useState('terminations');

  const tabs = [
    {
      name: 'vacations',
      title: 'Заявки на отпуск',
    },
    {
      name: 'dayoffs',
      title: 'Заявки на отгул',
    },
    {
      name: 'terminations',
      title: 'Заявки на увольнение',
    },
    {
      name: 'other',
      title: 'Прочие заявки',
    },
  ];

  return (
    <PageWrapper
      title="Мои заявки"
    >
      <Flex className="mb-4">
        <UniGeneralButtons
          tabs={tabs}
          viewMode={viewMode}
          switchViewFunc={setViewMode}
        />
      </Flex>
      {viewMode === 'terminations' && (
        <RequestsList
          showCreateTerminateButton
          parent={rootUserUUID}
          defaultConfig={{
            depth: 0,
            params: {
              type: 'termination_request',
            },
          }}
          title="Заявки на увольнение"
        />
      )}
      {viewMode === 'dayoffs' && (
        <RequestsList
          parent={rootUserUUID}
          defaultConfig={{
            depth: 0,
            params: {
              type: 'dayoff_request',
            },
          }}
          title="Заявки на отгул"
        />
      )}
      {viewMode === 'vacations' && (
        <RequestVacationsCard actor={actor} />
      )}
      {viewMode === 'other' && (
        <RequestsList
          showCreateCustomRequestButton
          parent={rootUserUUID}
          defaultConfig={{
            depth: 0,
            params: {
              type: 'custom_request',
            },
          }}
          title="Прочие заявки"
        />
      )}
    </PageWrapper>
  );
}
