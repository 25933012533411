import {
  mdiAlertCircleOutline,
  mdiArrowLeft, mdiChevronDown, mdiChevronRight, mdiChevronUp, mdiPencil, mdiTriangle,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Avatar,
  Button, Card, Divider, Dropdown, Flex,
  Modal,
  Space,
  Spin,
  Tabs,
  Tooltip,
} from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalize,
  get, head, isNil, map, split, upperFirst,
} from 'lodash';
import dayjs from 'dayjs';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import {
  bool, func, object, string,
} from 'prop-types';
import {
  useLocation, useHistory,
} from 'react-router-dom';
import EntityTitleForCard from '../commonComponents/entityCardComponents/EntityTitleForCard';
import EntityDescriptionForCard from '../commonComponents/entityCardComponents/EntityDescriptionForCard';
import CommonInfo from '../commonComponents/entityCardComponents/CommonInfo';
import CopyURL from '../commonComponents/CopyURL';
import EmployeesForDepartment from './commonComponents/EmployeesForDepartment';
import useAPI from '../../api/useAPI';
import { getDeliveryPartition } from '../../redux/config/selectors';
import VacationsForDepartment from './commonComponents/VacationsForDepartment';
import EmployeeNameForDepartment from './commonComponents/EmployeeNameForDepartment';
import PanelForDepartment from './commonComponents/PanelForDepartment';
import { SERVICE_PARTITION_NAME } from '../../api/app_config';
import { getListAndReadEntities } from '../../api/actions/entity';
import AssigningUserPopover from '../users/AssigningUserPopover';
import ReportsForDepartment from './commonComponents/ReportsForDepartment';
import useDefaultDepartment from '../../redux/departments/hooks/useDefaultDepartment';
import { isRootOrAdmin } from '../../redux/profile/selectors';
import EmployeeRequestsPanel from './commonComponents/EmployeeRequestsPanel';
import useDepartmentsAPI from '../../api/useDepartmentsAPI';
import {
  DELETE_SINGLE_DEPARTMENT_FAILURE,
  DELETE_SINGLE_DEPARTMENT_REQUEST,
  DELETE_SINGLE_DEPARTMENT_SUCCESS,
  UPDATE_SINGLE_DEPARTMENT_FAILURE,
  UPDATE_SINGLE_DEPARTMENT_REQUEST,
  UPDATE_SINGLE_DEPARTMENT_SUCCESS,
} from '../../redux/departments/slices/departmentsSlice';
import useURLParams from '../../hooks/useURLParams';
import DepartmentDashboard from './commonComponents/DepartmentDashboard';
import DepartmentRequestPanel from './commonComponents/DepartmentRequestPanel';

export default function DepartmentCard({
  isFetching = false,
  hideDeleteButton,
  hideAllTabs,
  hideCopyURLButton,
  hideBackButton,
  hideExpand,
  hideEdit,
  hideTabs = [],
  hideAdminEmployeeColumns,
  hideAddEmployee,
  department = {},
  departmentUUID = '',
  className = '',
  onBackDepartmentCallback,
  onEditDepartmentCallback,
  onDeleteDepartmentCallback,
}) {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);

  const isAdOrRoot = useSelector(isRootOrAdmin);

  const [isGlobalEdit, setIsGlobalEdit] = useState(false);

  const {
    createEntityPermissions,
    updateUserEntity,
  } = useAPI();

  const {
    updateDepartment,
    deleteDepartment,
  } = useDepartmentsAPI();

  const {
    routeNavigateAndClearParams,
  } = useURLParams();

  const { uuid: defaultDepartmentUUID } = useDefaultDepartment(false);

  const {
    uuid,
    params: {
      leader,
      deputy,
      leaderActorUUID,
      deputyActorUUID,
      title,
      description,
    } = {},
  } = department || {};

  const [newTitle, setNewTitle] = useState(title);
  const [newDesc, setNewDesc] = useState(description);
  const [newLeader, setNewLeader] = useState(leader);
  const [newDeputy, setNewDeputy] = useState(deputy);

  const [activeKey, setActiveKey] = useState('1');

  const [showFullCard, setShowFullCard] = useState(true);

  const items = [
    {
      key: '0',
      label: 'Дашборд',
      hidden: hideTabs?.includes('dashboard'),
    },
    {
      key: '1',
      label: 'Подрядчики',
      hidden: hideTabs?.includes('contractors'),
    },
    {
      key: '2',
      label: 'Отпуска',
      hidden: hideTabs?.includes('vacations'),
    },
    {
      key: '3',
      label: 'Отчеты подрядчиков',
      hidden: hideTabs?.includes('contractor_reports'),
    },
    // {
    //   key: '4',
    //   label: 'Статистика',
    // },
    {
      key: '5',
      label: 'Заявки',
      hidden: hideTabs?.includes('contractor_requests'),
    },
  ].filter((el) => !el?.hidden);

  const onChangeLeader = (user) => {
    const newUUID = get(user, 'actor', '');

    if (newUUID === newLeader?.actor) {
      setNewLeader(null);
      return;
    }
    if (newUUID === newDeputy?.actor) {
      setNewDeputy(newLeader);
    }
    setNewLeader({
      actor: user?.actor,
      uuid: user?.value,
      first_name: user?.user?.uinfo?.first_name,
      last_name: user?.user?.uinfo?.last_name,
    });
  };

  const onChangeDeputy = (user) => {
    const newUUID = get(user, 'actor', '');

    if (newUUID === newDeputy?.actor) {
      setNewDeputy(null);
      return;
    }
    if (newUUID === newLeader?.actor) {
      setNewLeader(newDeputy);
    }
    setNewDeputy({
      actor: user?.actor,
      uuid: user?.value,
      first_name: user?.user?.uinfo?.first_name,
      last_name: user?.user?.uinfo?.last_name,
    });
  };

  const onUpdateDepartment = async (data) => {
    const res = await updateDepartment(departmentUUID, {
      parent: partitionUUID,
      params: {
        ...data,
      },
    }, [
      UPDATE_SINGLE_DEPARTMENT_REQUEST,
      UPDATE_SINGLE_DEPARTMENT_SUCCESS,
      UPDATE_SINGLE_DEPARTMENT_FAILURE,
    ]);
    if (onEditDepartmentCallback) {
      onEditDepartmentCallback(head(res));
    }
  };

  const initEmployeesFunc = async () => {
    const res = await dispatch(
      getListAndReadEntities({
        data: {
          entity_type: 'user',
          parent: departmentUUID,
        },
        constants: [
          'GET_EMPLOYEES_FOR_DEPARTMENT_REQUEST',
          'GET_EMPLOYEES_FOR_DEPARTMENT_SUCCESS',
          'GET_EMPLOYEES_FOR_DEPARTMENT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }),
    );

    return res?.data ?? [];
  };

  const onDeleteDepartment = async () => {
    Modal.warning({
      title: `${get(department, ['params', 'title'])}`,
      content: 'Вы уверены, что хотите удалить отдел?',
      width: 'auto',
      centered: true,
      keyboard: true,
      maskClosable: true,
      closable: true,
      okText: 'Да',
      onOk: async (close) => {
        if (!defaultDepartmentUUID) return;
        const users = await initEmployeesFunc();
        await Promise.all(users?.map((user) => updateUserEntity(user?.uuid, {
          parent: defaultDepartmentUUID,
        })));

        await deleteDepartment(departmentUUID, {
          parent: partitionUUID,
        }, [
          DELETE_SINGLE_DEPARTMENT_REQUEST,
          DELETE_SINGLE_DEPARTMENT_SUCCESS,
          DELETE_SINGLE_DEPARTMENT_FAILURE,
        ]);

        onDeleteDepartmentCallback();
        close();
      },
    });
  };

  const onSave = () => {
    const newDepartment = {
      leader: newLeader,
      deputy: newDeputy,
      leaderActorUUID: newLeader?.actor,
      deputyActorUUID: newDeputy?.actor,
      users: [],
      usersSearch: [],
    };

    if (title !== newTitle) {
      newDepartment.title = newTitle;
    }

    if (description !== newDesc) {
      newDepartment.description = newDesc;
    }

    if (newLeader?.actor) {
      newDepartment.usersSearch.push(newLeader?.actor);
      newDepartment.users.push({
        ...newLeader,
        uuid: newLeader?.actor,
        isLeader: true,
      });
    }

    if (newDeputy?.actor) {
      newDepartment.usersSearch.push(newDeputy?.actor);
      newDepartment.users.push({
        ...newDeputy,
        uuid: newDeputy?.actor,
        isLeader: true,
      });
    }

    onUpdateDepartment(newDepartment);
    if (newLeader?.actor !== leaderActorUUID) {
      const data = [];
      if (newLeader?.actor) {
        data.push({
          actor: newLeader?.actor,
          create: true,
          list: true,
          read: true,
          update: true,
          delete: true,
          set: true,
        });
      }
      if (leaderActorUUID) {
        data.push({
          actor: leaderActorUUID,
          create: false,
          list: false,
          read: false,
          update: false,
          delete: false,
          set: false,
        });
      }
      createEntityPermissions({
        data: {
          entity_uuid: departmentUUID,
          data,
        },
      });
    }
    if (newDeputy?.actor !== deputyActorUUID) {
      const data = [];
      if (newDeputy?.actor) {
        data.push({
          actor: newDeputy?.actor,
          create: true,
          list: true,
          read: true,
          update: true,
          delete: true,
          set: false,
        });
      }
      if (deputyActorUUID) {
        data.push({
          actor: deputyActorUUID,
          create: false,
          list: false,
          read: false,
          update: false,
          delete: false,
          set: false,
        });
      }
      createEntityPermissions({
        data: {
          entity_uuid: departmentUUID,
          data,
        },
      });
    }
    setIsGlobalEdit(false);
  };

  const onOpenGlobalEdit = () => {
    setNewTitle(title);
    setNewDesc(description);
    setNewLeader(leader);
    setNewDeputy(deputy);
    setIsGlobalEdit(true);
  };

  useEffect(() => {
    setNewTitle(title);
    setNewDesc(description);
    setNewLeader(leader);
    setNewDeputy(deputy);
  }, [JSON.stringify(department)]);

  return (
    <Flex
      vertical
      className="w-full"
    >
      <Spin
        spinning={isFetching}
        className="w-full"
      >
        <Card
          title={(
            <Flex
              className="w-full"
              justify="space-between"
            >
              <Flex
                align="center"
              >
                <Flex
                  align="center"
                  className="cursor-pointer"
                  onClick={!hideBackButton && onBackDepartmentCallback}
                >
                  {!hideBackButton && <Icon path={mdiArrowLeft} size={0.9} />}
                  <span className="ml-3 text-xl">
                    Отдел
                    {' '}
                    {!showFullCard && <span>{title?.length > 25 ? `${title?.slice(0, 25)}...` : title}</span>}
                  </span>
                </Flex>
                <CommonInfo
                  iconSize={1}
                  style={{
                    width: 25,
                    minWidth: 25,
                    height: 25,
                  }}
                  className="ml-2 mt-1"
                  creator={`${capitalize(get(department, 'uinfo.first_name', ''))} ${get(department, 'uinfo.last_name', '')}`}
                  createdDate={get(department, 'created', '')}
                />
                {!hideCopyURLButton && (
                <CopyURL
                  entityType="department"
                  entityUUID={uuid}
                  className="ml-1 pt-2.5 mt-0.5 pl-2"
                  iconSize={1}
                  style={{
                    width: 25,
                    minWidth: 25,
                    height: 25,
                  }}
                />
                )}
              </Flex>
              {isAdOrRoot && (
              <Flex>
                {isAdOrRoot && (
                <Button
                  size="small"
                  onClick={() => routeNavigateAndClearParams('/department', { v: 'overview', d: departmentUUID })}
                  className="mr-2 !text-blue-500 border !border-blue-500 hover:bg-blue-100"
                >
                  Открыть
                </Button>
                )}
                {isGlobalEdit && !hideEdit && (
                  <>
                    <Button
                      size="small"
                      onClick={() => setIsGlobalEdit(false)}
                      className="mr-2 !text-orange-500 border !border-orange-500 hover:bg-orange-100"
                    >
                      Отмена
                    </Button>
                    <Button
                      size="small"
                      onClick={onSave}
                      className="mr-2 !text-green-500 border !border-green-500 hover:bg-green-100"
                    >
                      Сохранить
                    </Button>
                  </>
                )}
                {!isGlobalEdit && !hideEdit && (
                  <Button
                    size="small"
                    onClick={onOpenGlobalEdit}
                    className="mr-2 !text-orange-500 border !border-orange-500 hover:bg-orange-100"
                  >
                    Редактировать
                  </Button>
                )}
                {!hideDeleteButton && (
                <Button
                  size="small"
                  onClick={onDeleteDepartment}
                  className="!text-red-500 border !border-red-500 hover:bg-red-100"
                >
                  Удалить
                </Button>
                )}
              </Flex>
              )}
            </Flex>
      )}
          className={cn(`w-full h-max p-0 ${className}`, {
            'blue-base-card': true,
          })}
        >
          {showFullCard
        && (
        <Flex
          vertical
          className="py-2"
        >
          <Flex
            className="mx-12 max-w-full overflow-hidden"
            justify="start"
          >
            <EntityTitleForCard
              hideEditButton
              isGlobalEdit={isGlobalEdit}
              onChangeValue={setNewTitle}
              title={title}
              onEditCallback={onUpdateDepartment}
            />
          </Flex>
          <Divider className="my-2" />
          <Flex
            justify="space-between"
            className="mx-12 text-sm font-normal"
          >
            <Flex
              align="center"
            >
              <span
                className="mr-2"
              >
                Руководитель:
              </span>
              {isGlobalEdit && (
              <AssigningUserPopover
                title="Руководитель"
                popoverChildTitle={(
                  <span />
            )}
                parent={partitionUUID}
                selected={newLeader}
                closeOnMouseLeave
                onUserSelect={onChangeLeader}
              />
              )}
              {!isGlobalEdit && leader && (
              <span className="text-blue-500">
                {get(leader, 'first_name', '')}
                {' '}
                {get(leader, 'last_name', '')}
              </span>
              )}
              {!isGlobalEdit && !leader && (
              <span
                className="text-gray-500"
              >
                Нет руководителя
              </span>
              )}
            </Flex>
            <Flex
              align="center"
            >
              <span
                className="mr-2"
              >
                Заместитель:
              </span>
              {isGlobalEdit && (
              <AssigningUserPopover
                title="Заместитель"
                popoverChildTitle={(
                  <span />
            )}
                parent={partitionUUID}
                selected={newDeputy}
                closeOnMouseLeave
                onUserSelect={onChangeDeputy}
              />
              )}
              {!isGlobalEdit && deputy && (
              <span className="text-blue-500">
                {get(deputy, 'first_name', '')}
                {' '}
                {get(deputy, 'last_name', '')}
              </span>
              )}
              {!isGlobalEdit && !deputy && (
              <span
                className="text-gray-500"
              >
                Нет заместителя
              </span>
              )}
            </Flex>
          </Flex>
          <Divider className="my-2" />
          <EntityDescriptionForCard
            hideEdit
            useEllipsis
            onChangeValue={setNewDesc}
            isGlobalEdit={isGlobalEdit}
            className="mx-12"
            description={description}
            onEditCallback={onUpdateDepartment}
          />
          {!hideAllTabs && (
            <>
              <Divider className="my-2" />

              <Flex
                vertical
                className="mx-6 -mt-2 w-full"
              >
                <Tabs
            // type="card"
                  defaultActiveKey="1"
                  activeKey={activeKey}
                  items={items}
                  onChange={setActiveKey}
                  tabBarStyle={{ marginBottom: 0 }}
                />
                {activeKey === '0' && (
                  <DepartmentDashboard
                    departmentUUID={departmentUUID}
                  />
                )}
                {activeKey === '1' && (
                <EmployeesForDepartment
                  hideAdminColumns={hideAdminEmployeeColumns}
                  hideAddEmployee={hideAddEmployee}
                  departmentUUID={departmentUUID}
                  leader={leaderActorUUID}
                  deputy={deputyActorUUID}
                />
                )}
                {activeKey === '2' && (
                <VacationsForDepartment
                  departmentUUID={departmentUUID}
                />
                )}
                {activeKey === '3' && (
                <ReportsForDepartment
                  departmentUUID={departmentUUID}
                  leader={leaderActorUUID}
                  deputy={deputyActorUUID}
                />
                )}
                {/* {activeKey === '4' && (
            <PanelForDepartment
              departmentUUID={departmentUUID}
            />
            )} */}
                {/* )} */}
                {activeKey === '5' && (
                <DepartmentRequestPanel
                  departmentUUID={departmentUUID}
                />
                )}
              </Flex>
            </>
          )}
        </Flex>
        )}
        </Card>

      </Spin>

      {!hideExpand && (
      <Flex
        className="w-full py-1 z-20 cursor-pointer text-blue-500 bg-sky-100"
        justify="center"
        align="center"
        onClick={() => setShowFullCard((prev) => !prev)}
      >
        {showFullCard
          ? (
            <Flex
              align="center"
            >
              <Icon path={mdiChevronUp} size={1.2} />
              <span>
                Свернуть
              </span>
            </Flex>
          ) : (
            <Flex
              align="center"
            >
              <Icon path={mdiChevronDown} size={1.2} />
              <span>
                Развернуть
              </span>
            </Flex>
          )}
      </Flex>
      )}
    </Flex>
  );
}

DepartmentCard.propTypes = {
  isFetching: bool,
  title: string,
  className: string,
  department: object,
  departmentUUID: string,
  hideDeleteButton: bool,
  hideCopyURLButton: bool,
  hideExpand: bool,
  hideAddEmployee: bool,
  hideEdit: bool,
  hideTabs: bool,
  hideBackButton: bool,
  onBackDepartmentCallback: func,
  onEditDepartmentCallback: func,
  onDeleteDepartmentCallback: func,
};
