import { useDispatch } from 'react-redux';
import { get, omit } from 'lodash';
import useLogsAPI from './useLogsAPI';
import {
  entityCreate, entityDelete, entityRead, entityUpdate, getListAndReadEntities,
} from './actions/entity';
import { SERVICE_PARTITION_NAME } from './app_config';
import antNotification from '../utils/antNotification';

export default function useContractorRequestsAPI() {
  const dispatch = useDispatch();

  const {
    createLog,
  } = useLogsAPI();

  const readListOfContractorRequests = (entityUUIDs, data = {}, constants) => {
    try {
      return dispatch(
        entityRead({
          data: {
            entity_type: 'contractor_request',
            entity_uuid: entityUUIDs,
            ...data,
          },
          constants: constants || [
            'READ_LIST_OF_CONTRACTOR_REQUESTS_REQUEST',
            'READ_LIST_OF_CONTRACTOR_REQUESTS_SUCCESS',
            'READ_LIST_OF_CONTRACTOR_REQUESTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список заявок!');
      return null;
    }
  };

  const getListOfContractorRequests = (parent, data = {}, constants) => {
    try {
      return dispatch(
        getListAndReadEntities({
          data: {
            entity_type: 'contractor_request',
            parent,
            ...data,
          },
          constants: constants || [
            'GET_LIST_OF_CONTRACTOR_REQUESTS_REQUEST',
            'GET_LIST_OF_CONTRACTOR_REQUESTS_SUCCESS',
            'GET_LIST_OF_CONTRACTOR_REQUESTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список заявок!');
      return null;
    }
  };

  const createContractorRequest = async (parent, data = {}, constants) => {
    try {
      const createdRequest = await dispatch(
        entityCreate({
          data: {
            entity_type: 'contractor_request',
            parent,
            ...data,
          },
          constants: constants || [
            'CREATE_CONTRACTOR_REQUEST_REQUEST',
            'CREATE_CONTRACTOR_REQUEST_SUCCESS',
            'CREATE_CONTRACTOR_REQUEST_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (createdRequest?.data) {
        await createLog({
          event_type: 'create_contractor_request',
          event_data: {
            contractor_request: createdRequest.data[0],
          },
        });
      }

      return createdRequest;
    } catch (e) {
      antNotification.error('Не удалось создать заявку!');
      return null;
    }
  };

  const updateContractorRequest = async (entityUUID, data = {}, constants) => {
    try {
      const updatedRequest = await dispatch(
        entityUpdate({
          data: {
            entity_uuid: entityUUID,
            entity_type: 'contractor_request',
            ...omit(data, ['entity_uuid']),
          },
          constants: constants || [
            'UPDATE_CONTRACTOR_REQUEST_REQUEST',
            'UPDATE_CONTRACTOR_REQUEST_SUCCESS',
            'UPDATE_CONTRACTOR_REQUEST_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (updatedRequest?.data) {
        await createLog({
          event_type: 'update_contractor_request',
          event_data: {
            contractor_request: updatedRequest.data[0],
          },
        });
      }

      return updatedRequest;
    } catch (e) {
      antNotification.error('Не удалось изменить заявку!');
      return null;
    }
  };

  const deleteContractorRequest = (entityUUID, data = {}, constants) => {
    try {
      return dispatch(
        entityDelete({
          data: {
            entity_uuid: entityUUID,
            entity_type: 'contractor_request',
            ...data,
          },
          constants: constants || [
            'DELETE_CONTRACTOR_REQUEST_REQUEST',
            'DELETE_CONTRACTOR_REQUEST_SUCCESS',
            'DELETE_CONTRACTOR_REQUEST_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось удалить заявку!');
      return null;
    }
  };

  return {
    createContractorRequest,
    readListOfContractorRequests,
    getListOfContractorRequests,
    updateContractorRequest,
    deleteContractorRequest,
  };
}
