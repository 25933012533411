import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  get, isEmpty, isEqual, omit,
} from 'lodash';
import useContractorRequestsAPI from '../../../api/useContractorRequestsAPI';

const useRequests = ({
  parentUUID,
  defaultConfig = {},
}) => {
  const dispatch = useDispatch();

  const { getListOfContractorRequests } = useContractorRequestsAPI();

  const [requests, setRequests] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    limit: 20,
    offset: 0,
    ...defaultConfig,
  });
  const [requestFilterConfig, setFilterConfig] = useState({});

  const prepareFilterConfig = (newFilterConfig) => {
    const fixedConfig = { ...newFilterConfig };
    if (!isEmpty(fixedConfig)) {
      const filterKeys = Object.keys(fixedConfig);
      filterKeys.forEach(
        (key) => fixedConfig[key] = fixedConfig[key]?.filter((item) => item !== null),
      );
    }
    return fixedConfig;
  };

  const initFunc = async () => {
    if (parentUUID) {
      setIsFetching(true);
      const reqTypes = get(requestFilterConfig, ['type'], []);
      const reqStatuses = get(requestFilterConfig, ['status'], []);

      const newConfig = {
        depth: 1,
        params: {
          type: reqTypes,
          status: reqStatuses,
        },
        ...config,
      };

      const res = await getListOfContractorRequests(parentUUID, newConfig);
      console.log(res);
      setRequests(res?.data ?? []);
      setTotal(res?.total ?? 0);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    setConfig((prev) => ({ ...prev, ...defaultConfig }));
  }, [JSON.stringify(defaultConfig)]);

  useEffect(() => {
    initFunc();
  }, [parentUUID, JSON.stringify(config), JSON.stringify(requestFilterConfig)]);

  return {
    requests,
    isFetching,
    paginationOptions: {
      requestFilterConfig,
      filterCallback: (newConfig) => {
        const fixedConfig = prepareFilterConfig(newConfig);

        if (!isEqual(requestFilterConfig, fixedConfig)) {
          setFilterConfig(fixedConfig);
          setConfig((prev) => ({ ...prev, offset: 0 }));
          setCurrentPage(1);
        }
      },
      pageLimit: get(config, 'limit', 5),
      total,
      changePageLimit: (newLimit) => {
        setCurrentPage(1);
        setConfig((prev) => ({ ...prev, offset: 0, limit: newLimit }));
      },
      newCurrentPage: currentPage,
      onChangePageCallback: (newPage) => {
        setCurrentPage(newPage);
        setConfig((prev) => ({ ...prev, offset: (newPage - 1) * prev.limit }));
      },
      getPaginationOptions: (newPart) => {
        const valueSearch = get(newPart, 'search', '');

        if (valueSearch) {
          setConfig((prev) => ({
            ...prev,
            ...omit(newPart, ['search']),
            searchData: {
              value: valueSearch,
              ignore_case: true,
              fields: { params: ['title', 'comment'] },
            },
          }));
        } else {
          setConfig((prev) => ({ ...omit(prev, 'searchData'), ...newPart }));
        }
      },
    },
    onReloadRequests: initFunc,
    valueSearch: get(config, 'search_data.value', ''),
    onChangeSearch: (value) => {
      setCurrentPage(1);
      if (value === '') {
        setConfig((prev) => ({
          ...omit(prev, 'search_data'),
          limit: get(prev, 'limit', 5),
          offset: 0,
        }));
        return;
      }
      setConfig((prev) => ({
        ...prev,
        limit: get(prev, 'limit', 5),
        offset: 0,
        search_data: {
          fields: {
            params: {
              title: 'title',
              comment: 'comment',
            },
          },
          value,
        },
      }));
    },
  };
};

export default useRequests;
