import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert, Button, Flex, Form, Input, Modal, Card,
  Divider,
} from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import {
  mdiAccountRemove, mdiCheck, mdiOfficeBuilding, mdiAccountGroup,
} from '@mdi/js';
import { head } from 'lodash';
import { getProfileInfo, isRootOrAdmin } from '../redux/profile/selectors';
import useMetaeventsAPI from '../api/useMetaeventsAPI';
import { getDeliveryPartition } from '../redux/config/selectors';
import useOfficesAPI from '../api/useOfficesAPI';
import useTerminationAPI from '../api/useTerminationAPI';
import useContractorRequestsAPI from '../api/useContractorRequestsAPI';
import antNotification from '../utils/antNotification';
import useAPI from '../api/useAPI';
import { getRequestsStorageUUID } from '../redux/storages/selectors';
import MetaeventLink from '../components/events/MetaeventLink';

const { TextArea } = Input;

export default function useTriggersTermination() {
  const { getListOfMetaevents, readListOfMetaevents } = useMetaeventsAPI();
  const { createTermination, getListOfTermination, updateTermination } = useTerminationAPI();
  const { createContractorRequest } = useContractorRequestsAPI();

  const partitionUUID = useSelector(getDeliveryPartition);
  const profileActor = useSelector(getProfileInfo);
  const requestsStUUID = useSelector(getRequestsStorageUUID);

  const isAdOrRoot = useSelector(isRootOrAdmin);

  const [trigger, setTrigger] = useState('');
  const [options, setOptions] = useState({});

  function CreateTerminationComponent() {
    const [isModalOpen, setModalOpen] = useState(true);
    const [form] = Form.useForm();
    const [metaevents, setMetaevents] = useState([]);
    const [selectedContractorMetaevent, setSelectedContractorMetaevent] = useState(null);
    const [selectedManagementMetaevent, setSelectedManagementMetaevent] = useState(null);

    const {
      saveCallback, user, metaevent, onComplete,
    } = options;

    useEffect(() => {
      if (!isModalOpen) {
        setTrigger('');
        setOptions({});
      }
    }, [isModalOpen]);

    useEffect(() => {
      loadData();
    }, []);

    useEffect(() => {
      if (selectedContractorMetaevent || selectedManagementMetaevent) {
        form.resetFields();
        form.setFieldsValue({
          contractor_instruction_title: selectedContractorMetaevent?.params?.instruction_title || options.contractorMetaevent?.params?.instruction_title || '',
          contractor_instruction: selectedContractorMetaevent?.params?.instruction || options.contractorMetaevent?.params?.instruction || '',
          management_instruction_title: selectedManagementMetaevent?.params?.instruction_title || options.managementMetaevent?.params?.instruction_title || '',
          management_instruction: selectedManagementMetaevent?.params?.instruction || options.managementMetaevent?.params?.instruction || '',
        });
      }
    }, [selectedContractorMetaevent, selectedManagementMetaevent, options]);

    const loadData = async () => {
      const metaeventsRes = await getListOfMetaevents(partitionUUID, {
        params: {
          trigger: ['start-termination-contractor', 'start-termination-management'],
        },
      });

      const sortedMetaevents = (metaeventsRes?.data || []).reduce((acc, event) => {
        if (event.params?.trigger === 'start-termination-contractor') {
          acc.employee = [...(acc.employee || []), event];
        } else if (event.params?.trigger === 'start-termination-management') {
          acc.management = [...(acc.management || []), event];
        }
        return acc;
      }, { employee: [], management: [] });

      setMetaevents(sortedMetaevents);
    };

    const handleSubmit = async (values) => {
      if (!selectedContractorMetaevent || !selectedManagementMetaevent) {
        antNotification.warning('Пожалуйста, выберите шаблоны инструкций');
        return;
      }

      if (user?.uuid) {
        const contractorChanges = {};
        const managementChanges = {};

        if (values.contractor_instruction_title !== selectedContractorMetaevent.params?.instruction_title
            || values.contractor_instruction_title === '') {
          contractorChanges.instruction_title = values.contractor_instruction_title;
        }
        if (values.contractor_instruction !== selectedContractorMetaevent.params?.instruction
            || values.contractor_instruction === '') {
          contractorChanges.instruction = values.contractor_instruction;
        }

        if (values.management_instruction_title !== selectedManagementMetaevent.params?.instruction_title
            || values.management_instruction_title === '') {
          managementChanges.instruction_title = values.management_instruction_title;
        }
        if (values.management_instruction !== selectedManagementMetaevent.params?.instruction
            || values.management_instruction === '') {
          managementChanges.instruction = values.management_instruction;
        }

        const terminationData = {
          params: {
            contractor_metaevent: selectedContractorMetaevent?.uuid,
            management_metaevent: selectedManagementMetaevent?.uuid,
            status: 'pending',
            created_by: profileActor?.uuid,
            created_at: dayjs().format(),
            ...(Object.keys(contractorChanges).length > 0 && {
              contractor_instruction_title: contractorChanges.instruction_title !== undefined
                ? contractorChanges.instruction_title
                : selectedContractorMetaevent.params?.instruction_title,
              contractor_instruction: contractorChanges.instruction !== undefined
                ? contractorChanges.instruction
                : selectedContractorMetaevent.params?.instruction,
            }),
            ...(Object.keys(managementChanges).length > 0 && {
              management_instruction_title: managementChanges.instruction_title !== undefined
                ? managementChanges.instruction_title
                : selectedManagementMetaevent.params?.instruction_title,
              management_instruction: managementChanges.instruction !== undefined
                ? managementChanges.instruction
                : selectedManagementMetaevent.params?.instruction,
            }),
          },
        };

        const newTermination = await createTermination(user?.uuid, terminationData);

        if (newTermination) {
          saveCallback?.(user?.uuid, newTermination[0]);
          onComplete?.();
          antNotification.success('Процесс увольнения запущен');
          setModalOpen(false);
        }
      }
    };

    return (
      <Modal
        open={isModalOpen}
        title={(
          <Flex align="center" gap="small">
            <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-blue-50">
              <Icon
                path={mdiAccountRemove}
                size={0.8}
                className="text-blue-500"
              />
            </div>
            <span className="text-xl font-medium">
              Увольнение подрядчика
              {' '}
              <span className="text-blue-500">
                {user?.uinfo?.first_name}
                {' '}
                {user?.uinfo?.last_name}
              </span>
            </span>
          </Flex>
        )}
        width={1200}
        centered
        destroyOnClose
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <div className="flex gap-6 px-4 -mt-3">
            <Flex className="flex-1" vertical gap="large">
              <Flex gap="6">
                <div className="flex-1 mr-4">
                  <div>
                    <div className="mb-4">
                      <h3 className="text-lg font-medium text-gray-900">Инструкция для подрядчика</h3>
                      <p className="text-sm text-gray-500 -mt-3">Укажите инструкцию для подрядчика</p>
                    </div>

                    <Flex
                      vertical
                    >
                      <div className="bg-white border border-gray-200 rounded-lg">
                        <Form.Item
                          className="-mt-0"
                          name="contractor_instruction_title"
                          label={<span className="font-medium text-gray-700">Заголовок инструкции</span>}
                          rules={[{ required: true, message: 'Пожалуйста, введите заголовок инструкции' }]}
                        >
                          <Input placeholder="Введите заголовок инструкции..." />
                        </Form.Item>

                        <Form.Item
                          className="-mt-3"
                          name="contractor_instruction"
                          label={<span className="font-medium text-gray-700">Инструкция по увольнению</span>}
                          rules={[{ required: true, message: 'Пожалуйста, введите инструкцию' }]}
                        >
                          <TextArea
                            rows={4}
                            placeholder="Опишите инструкцию по увольнению..."
                            className="resize-none"
                          />
                        </Form.Item>
                      </div>
                    </Flex>
                  </div>
                </div>

                <div className="w-[400px]">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">Инструкция для подрядчика</h3>
                    <p className="text-sm text-gray-500 -mt-3">Выберите шаблон инструкции для увольняемого подрядчика</p>
                    <div
                      className="space-y-3 mt-3 overflow-auto pr-2"
                      style={{ maxHeight: '300px' }}
                    >
                      {metaevents.employee?.map((item) => (
                        <MetaeventCard
                          key={item.uuid}
                          item={item}
                          isSelected={selectedContractorMetaevent?.uuid === item.uuid}
                          onSelect={() => setSelectedContractorMetaevent(item)}
                          color="blue"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Flex>
              <Divider className="-my-6" />

              <Flex gap="6" className="-mt-2">
                <div className="flex-1">
                  <div className="mr-4">
                    <div className="mb-4">
                      <h3 className="text-lg font-medium text-gray-900">Инструкция для руководства</h3>
                      <p className="text-sm text-gray-500 -mt-3">Укажите инструкции для руководства</p>
                    </div>

                    <Flex
                      vertical
                    >
                      <div className="bg-white border border-gray-200 rounded-lg">
                        <Form.Item
                          className="-mt-0"
                          name="management_instruction_title"
                          label={<span className="font-medium text-gray-700">Заголовок инструкции</span>}
                          rules={[{ required: true, message: 'Пожалуйста, введите заголовок инструкции' }]}
                        >
                          <Input placeholder="Введите заголовок инструкции..." />
                        </Form.Item>

                        <Form.Item
                          name="management_instruction"
                          className="-mt-3"
                          label={<span className="font-medium text-gray-700">Инструкция по увольнению</span>}
                          rules={[{ required: true, message: 'Пожалуйста, введите инструкцию' }]}
                        >
                          <TextArea
                            rows={4}
                            placeholder="Опишите инструкцию по увольнению..."
                            className="resize-none"
                          />
                        </Form.Item>
                      </div>
                    </Flex>
                  </div>
                </div>

                <div className="w-[400px]">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900">Инструкция для руководства</h3>
                    <p className="text-sm text-gray-500 -mt-3">Выберите шаблон инструкции для руководства по увольнению</p>
                    <div
                      className="space-y-3 mt-3 overflow-auto pr-2"
                      style={{ maxHeight: '300px' }}
                    >
                      {metaevents.management?.map((item) => (
                        <MetaeventCard
                          key={item.uuid}
                          item={item}
                          isSelected={selectedManagementMetaevent?.uuid === item.uuid}
                          onSelect={() => setSelectedManagementMetaevent(item)}
                          color="blue"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </Flex>
            </Flex>
          </div>

          <div className="flex justify-end gap-3 mt-6 pt-4 border-t">
            <Button onClick={() => setModalOpen(false)}>
              Отмена
            </Button>
            <Button type="primary" onClick={() => form.submit()}>
              Начать процесс увольнения
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }

  function TerminationInfoComponent() {
    const [terminationData, setTerminationData] = useState(null);
    const [metaeventData, setMetaeventData] = useState(null);
    const { user } = options;

    useEffect(() => {
      const loadTerminationData = async () => {
        if (user?.uuid) {
          const terminationRes = await getListOfTermination(user?.uuid, {
            params: {
              status: 'pending',
            },
          });

          if (terminationRes?.data?.length > 0) {
            setTerminationData(terminationRes.data[0]);

            if (terminationRes.data[0]?.params?.contractor_metaevent) {
              const metaeventRes = await readListOfMetaevents(
                terminationRes.data[0]?.params?.contractor_metaevent,
              );

              if (metaeventRes?.data) {
                setMetaeventData(metaeventRes.data);
              }
            }
          }
        }
      };

      loadTerminationData();
    }, [user?.uuid]);

    if (!terminationData) return null;

    return (
      <Card
        title={(
          <Flex align="center" gap="small">
            <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-blue-50">
              <Icon
                path={mdiAccountRemove}
                size={0.8}
                className="text-blue-500"
              />
            </div>
            <span className="text-lg">Увольнение</span>
          </Flex>
        )}
        className="w-full"
      >
        <Flex vertical gap="middle">
          <Alert
            type="info"
            showIcon
            message={(
              <div className="text-xl text-black whitespace-pre-wrap">
                {terminationData.params?.contractor_instruction_title}
              </div>
            )}
            description={(
              <div className="text-base text-gray-600 whitespace-pre-wrap">
                {terminationData.params?.contractor_instruction}
              </div>
                    )}
          />
          <div>
            <div className="text-sm text-gray-500 mb-1">Создано</div>
            <div className="font-medium">
              {dayjs(terminationData.params?.created_at).format('DD.MM.YYYY HH:mm')}
            </div>
          </div>
        </Flex>
      </Card>
    );
  }

  function CompleteTerminationComponent() {
    const [isModalOpen, setModalOpen] = useState(true);
    const [metaeventData, setMetaeventData] = useState(null);
    const [terminationData, setTerminationData] = useState(null);
    const { user, onComplete } = options;

    useEffect(() => {
      const loadData = async () => {
        const terminationRes = await getListOfTermination(user?.uuid, {
          params: {
            status: 'pending',
          },
        });

        if (terminationRes?.data?.[0]) {
          setTerminationData(terminationRes.data[0]);

          const metaeventRes = await getListOfMetaevents(partitionUUID, {
            params: {
              trigger: 'complete-termination',
            },
          });

          if (metaeventRes?.data?.[0]) {
            setMetaeventData(metaeventRes.data[0]);
          }
        }
      };

      loadData();
    }, [user?.uuid]);

    const handleComplete = async () => {
      if (terminationData?.uuid) {
        const updatedTermination = await updateTermination(terminationData.uuid, {
          params: {
            ...terminationData.params,
            status: 'completed',
            completed_at: dayjs().format(),
            completed_by: profileActor?.uuid,
          },
        });

        if (updatedTermination) {
          antNotification.success('Увольнение успешно завершено');
          onComplete?.();
          setModalOpen(false);
        }
      }
    };

    // if (!terminationData || !metaeventData) return null;

    return (
      <Modal
        open={isModalOpen}
        title={(
          <Flex align="center" gap="small">
            <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-blue-50">
              <Icon
                path={mdiAccountRemove}
                size={0.8}
                className="text-blue-500"
              />
            </div>
            <span className="text-xl font-medium">
              Завершение увольнения
              {' '}
              <span className="text-blue-500">
                {user?.uinfo?.first_name}
                {' '}
                {user?.uinfo?.last_name}
              </span>
            </span>
          </Flex>
        )}
        width={800}
        centered
        destroyOnClose
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <Flex vertical gap="middle">
          {metaeventData && (
          <Alert
            type="info"
            showIcon
            message={(
              <div className="text-xl text-black whitespace-pre-wrap">
                {metaeventData?.params?.title}
                {isAdOrRoot && <MetaeventLink metaevent={metaeventData} />}
              </div>
            )}
            description={(
              <div className="text-base text-gray-600 whitespace-pre-wrap">
                {metaeventData?.params?.description}
              </div>
            )}
          />
          )}

          <div className="flex justify-end gap-3 mt-6 pt-4 border-t">
            <Button onClick={() => setModalOpen(false)}>
              Отмена
            </Button>
            <Button type="primary" onClick={handleComplete}>
              Завершить увольнение
            </Button>
          </div>
        </Flex>
      </Modal>
    );
  }

  function CreateTerminationRequestComponent() {
    const [isModalOpen, setModalOpen] = useState(true);
    const [form] = Form.useForm();
    const [metaeventData, setMetaeventData] = useState(null);
    const { user } = options;

    useEffect(() => {
      const loadData = async () => {
        const metaeventRes = await getListOfMetaevents(partitionUUID, {
          params: {
            trigger: 'create-termination-request',
          },
        });

        if (metaeventRes?.data?.[0]) {
          setMetaeventData(metaeventRes.data[0]);
        }
      };

      loadData();
    }, []);

    const handleSubmit = async (values) => {
      if (user?.uuid) {
        const requestData = {
          params: {
            type: 'termination_request',
            isCustom: false,
            status: 'pending',
            description: values.comment,
          },
        };

        const newRequest = await createContractorRequest(requestsStUUID, requestData);

        if (newRequest) {
          antNotification.success('Заявка на увольнение успешно создана');
          setModalOpen(false);
          setTrigger('');
        }
      }
    };

    return (
      <Modal
        open={isModalOpen}
        title={(
          <Flex align="center" gap="small">
            <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-blue-50">
              <Icon
                path={mdiAccountRemove}
                size={0.8}
                className="text-blue-500"
              />
            </div>
            <span className="text-xl font-medium">
              Заявка на увольнение
            </span>
          </Flex>
        )}
        width={600}
        centered
        destroyOnClose
        footer={null}
        onCancel={() => {
          setTrigger('');
          setModalOpen(false);
        }}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          {metaeventData && (
            <Alert
              type="info"
              showIcon
              className="mb-4"
              message={metaeventData?.params?.instruction_title}
              description={(
                <div className="text-base text-gray-600 whitespace-pre-wrap">
                  {metaeventData?.params?.instruction}
                </div>
)}
            />
          )}

          <Form.Item
            name="comment"
            label="Текст заявки"
            rules={[{ required: true, message: 'Пожалуйста, заполните поле' }]}
          >
            <TextArea
              rows={4}
              placeholder=""
              className="resize-none"
            />
          </Form.Item>

          <div className="flex justify-end gap-3 mt-6 pt-4 border-t">
            <Button onClick={() => {
              setTrigger('');
              setModalOpen(false);
            }}
            >
              Отмена
            </Button>
            <Button type="primary" htmlType="submit">
              Отправить заявку
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }

  async function run(type, newOptions = {}) {
    const metaevents = await getListOfMetaevents(partitionUUID, {
      params: {
        trigger: type,
        groups: profileActor?.uinfo?.groups,
      },
    });

    const userGroups = new Set(profileActor?.uinfo?.groups || []);
    const selectedMetaevent = metaevents?.data?.reduce((best, current) => {
      const metaeventGroups = new Set(current.params?.groups || []);
      let weight = 0;

      if (metaeventGroups.size > 0 && userGroups.size > 0) {
        const matchingGroups = [...metaeventGroups].filter((group) => userGroups.has(group));
        weight = Math.round((matchingGroups.length / metaeventGroups.size) * 10);
      }

      if (!best || weight > best.weight) {
        return { ...current, weight };
      }

      if (weight === best.weight && dayjs(current.created).isAfter(dayjs(best.created))) {
        return { ...current, weight };
      }

      return best;
    }, null);

    setOptions({
      ...newOptions,
      metaevent: selectedMetaevent?.weight > 0 ? selectedMetaevent : null,
    });
    setTrigger(type);
  }

  const EventComponent = useMemo(() => {
    switch (trigger) {
      case 'start-termination':
        return CreateTerminationComponent;
      case 'show-termination':
        return TerminationInfoComponent;
      case 'complete-termination':
        return CompleteTerminationComponent;
      case 'create-termination-request':
        return CreateTerminationRequestComponent;
      default:
        return () => null;
    }
  }, [trigger]);

  return {
    run,
    EventComponent,
  };
}

function MetaeventCard({
  item, isSelected, onSelect, color = 'blue',
}) {
  return (
    <div
      className={`p-4 rounded-lg cursor-pointer border-2 transition-all ${
        isSelected
          ? 'border-blue-500 bg-blue-50'
          : 'border-gray-100 bg-gray-100 hover:border-blue-200 hover:bg-white'
      }`}
      onClick={onSelect}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onSelect();
        }
      }}
    >
      <div className="flex items-start justify-between">
        <div>
          <div className="font-medium text-gray-800 mb-1">{item.params?.title}</div>
          <div className="text-sm text-gray-500">{item.params?.description}</div>
          <div className="text-xs text-gray-400 mt-2">
            Создано:
            {' '}
            {dayjs(item.created).format('DD.MM.YYYY HH:mm')}
          </div>
        </div>
        {isSelected && (
          <div className="flex items-center justify-center w-6 h-6 rounded-full bg-blue-500">
            <Icon
              path={mdiCheck}
              size={0.7}
              className="text-white"
            />
          </div>
        )}
      </div>
    </div>
  );
}
