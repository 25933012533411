import React from 'react';
import { Flex, Modal, Typography } from 'antd';
import { bool, func, object } from 'prop-types';
import { formatDate } from '../../../utils/dateTimeUtils';
import EmployeeRequestStatus from '../../commonComponents/entityCardComponents/EmployeeRequestStatus';

const { Text } = Typography;

export default function EmployeeRequestDetails({ isOpen, onCloseModal, request }) {
  const { uinfo, created, params: { status, description } } = request;
  return (
    <Modal
      open={isOpen}
      title="Заявка на нового подрядчика"
      onCancel={onCloseModal}
      footer={null}
      centered
      width={730}
      destroyOnClose
    >
      <Flex vertical gap={6} className="mb-2">
        <Text>
          <Text className="mr-4 text-stone-500">
            Создал:
          </Text>
          {`${uinfo.first_name} ${uinfo.last_name}`}
        </Text>
        <Text>
          <Text className="mr-4 text-stone-500">
            Дата создания заявки:
          </Text>
          {`${formatDate(created)}`}
        </Text>
        <Flex gap={16} align="center">
          <Text className="text-stone-500">
            Статус:
          </Text>
          <EmployeeRequestStatus status={status} />
        </Flex>
        <Text className="mt-2 text-stone-500">
          Комментарий к заявке:
        </Text>
        <Text>
          { description }
        </Text>
      </Flex>
    </Modal>
  );
}

EmployeeRequestDetails.propTypes = {
  isOpen: bool.isRequired,
  onCloseModal: func.isRequired,
  request: object,
};
