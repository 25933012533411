import React, { useEffect, useReducer, useState } from 'react';
import {
  Button, Table, Space, Typography, Tag,
  Tooltip,
  Card,
  Flex,
  Modal,
  Form,
  Input,
} from 'antd';
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  TeamOutlined,
  BankOutlined,
  ApartmentOutlined,
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
  ClockCircleOutlined,
  AccountBookFilled,
} from '@ant-design/icons';
import { capitalize, get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import { useHistory } from 'react-router-dom';
import { is } from 'date-fns/esm/locale';
import PageWrapper from '../../components/PageWrapper';
import useOfficesAPI from '../../api/useOfficesAPI';
import CreateOfficeButton from '../../components/offices/operations/CreateOfficeButton';
import GetSessionTokenModal from '../../components/auth/GetSessionTokenModal';
import axiosRequest from '../../api/api';
import ListOfUsersHRM54 from '../../components/users/ListOfUsersHRM54';
import ListOfCurrentUsers from '../../components/users/ListOfCurrentUsers';
import useDefaultDepartment from '../../redux/departments/hooks/useDefaultDepartment';
import { getDeliveryPartition } from '../../redux/config/selectors';
import useActorsHRM54 from '../../actors/hooks/useActorsHRM54';
import useAPI from '../../api/useAPI';
import {
  actorIsRootOrAdmin, firstName, getUuid, lastName,
} from '../../actors/utils';
import OfficesList from '../../components/offices/OfficesList';
import useAuthSessionHook from '../../components/auth/hooks/useAuthSessionHook';
import CreateOfficeCard from '../../components/offices/cards/CreateOfficeCard';
import useOfficesStorage from '../../hooks/storages/useOfficesStorage';
import useURLParams from '../../hooks/useURLParams';
import { ENABLE_RESET_PROPERTY } from '../../redux/reset/resetSlice';
import OfficeCard from '../../components/offices/cards/OfficeCard';

const { Title, Text } = Typography;

function OfficesPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const { addAndRemoveSearchParams, getURLParams } = useURLParams();
  const { office: activeOfficeUUID } = getURLParams();
  // const [isOpenCreateForm, setIsOpenCreateForm] = useReducer((state) => !state, false);
  const [isOpenCreateForm, setIsOpenCreateForm] = useState(false);
  const { uuid: officesStorageUUID } = useOfficesStorage(true);
  // const { checkAuthSessionAndRunFuncForRestart } = useAuthSessionHook(setIsOpenCreateForm);
  // const partitionUuid = useSelector(getDeliveryPartition);

  return (
    <PageWrapper
      title="Офисы"
    >
      <GetSessionTokenModal />
      <div className="grid grid-cols-2 gap-6">
        <OfficesList
          parent={officesStorageUUID}
          // checkAuthSessionAndRunFuncForRestart={checkAuthSessionAndRunFuncForRestart}
          onOpenOfficeCreate={() => {
            setIsOpenCreateForm(true);
            addAndRemoveSearchParams({ office: '' }, ['office']);
          }}
          onOfficeSelect={(office) => {
            addAndRemoveSearchParams({ office: office.uuid }, ['office']);
          }}
        />

        {activeOfficeUUID && !isOpenCreateForm && (
          <OfficeCard
            officeUUID={activeOfficeUUID}
          />
        )}
        {isOpenCreateForm && (
          <CreateOfficeCard
            // checkAuthSessionAndRunFuncForRestart={checkAuthSessionAndRunFuncForRestart}
            onCancelCallback={() => {
              setIsOpenCreateForm(false);
            }}
            onCreateCallback={(newOffice) => {
              dispatch(ENABLE_RESET_PROPERTY('offices_list'));
              addAndRemoveSearchParams({ office: newOffice?.office?.uuid }, ['office']);
              setIsOpenCreateForm(false);
            }}
            cardClassName="w-full"
          />
        )}
      </div>
    </PageWrapper>
  );
}

export default OfficesPage;
