import { getCookie } from '../54origins/cookies54origins';

export const APP_SERVICE_NAME = 'hrm54';
export const SERVICE_PARTITION_NAME = 'DELIVERY';

export const APP_LABEL = 'HRM54';

// Default DEV app config
export const APP_CONFIG_DEV = {
  [APP_SERVICE_NAME]: `https://${APP_SERVICE_NAME}.09releasetest.54origins.com`,
  entity: 'https://entity.09releasetest.54origins.com',
  auth: 'https://auth.09releasetest.54origins.com',
};

export const APP_CONFIG_PROD = {
  [APP_SERVICE_NAME]: `https://${APP_SERVICE_NAME}.p3.54origins.com`,
  entity: 'https://entity.p3.54origins.com',
  auth: 'https://auth.p3.54origins.com',
};

const apiConfig = APP_CONFIG_DEV;
const appLocation = window.location;

const replaceDomainByService = (service) => (appLocation?.host?.includes(APP_SERVICE_NAME)
  ? appLocation?.origin?.replace(APP_SERVICE_NAME, service) : apiConfig[service]);

export const currentServiceUrl = replaceDomainByService(APP_SERVICE_NAME);
export const entityServiceURL = replaceDomainByService('entity');
export const authServiceURL = replaceDomainByService('auth');
export const anyServiceURL = (service) => replaceDomainByService(service);

export const sessionNameFor = {
  auth: 'Auth',
  entity: 'Entity',
  notification: 'Notification',
  proxyGPT: 'Gpt_proxy',
};

export const getSessionTokenFor = {
  auth: () => getCookie(sessionNameFor.auth),
  entity: () => getCookie(sessionNameFor.entity),
  notification: () => getCookie(sessionNameFor.notification),
  proxyGPT: () => getCookie(sessionNameFor.proxyGPT),
};
