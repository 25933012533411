import { useDispatch, useSelector } from "react-redux";
import { getDeliveryPartition } from "../../redux/config/selectors";
import { isRootOrAdmin } from "../../redux/profile/selectors";
import { getOfficesStorageFetching, getOfficesStorageInfo, getOfficesStorageUUID } from "../../redux/commonStorages/selectors";
import { getOrCreateSingle } from "../../api/actions/entity";
import { getMD5HashFromString } from "../../utils";
import { SERVICE_PARTITION_NAME } from "../../api/app_config";
import { useEffect } from "react";
import { 
  GET_OR_CREATE_OFFICES_STORAGE_FAILURE,
  GET_OR_CREATE_OFFICES_STORAGE_REQUEST,
  GET_OR_CREATE_OFFICES_STORAGE_SUCCESS,
} from "../../redux/commonStorages/commonStoragesSlice";


function useOfficesStorage(isUpdateRequired = true) {
  const dispatch = useDispatch();

  const partitionUuid = useSelector(getDeliveryPartition);

  const isAdminOrRoot = useSelector(isRootOrAdmin);

  const officesStorage = useSelector(getOfficesStorageInfo);
  const officesStorageUUID = useSelector(getOfficesStorageUUID);
  const isFetching = useSelector(getOfficesStorageFetching);

  async function onReloadFunc() {
    if (!partitionUuid || !isAdminOrRoot) {
      return;
    } 
    console.log(123)
    dispatch(
      getOrCreateSingle({
        data: {
          entity_type: 'officesStorage',
          parent: partitionUuid,
          key: getMD5HashFromString(`officesStorage${partitionUuid}`),
        },
        constants: [
          GET_OR_CREATE_OFFICES_STORAGE_REQUEST,
          GET_OR_CREATE_OFFICES_STORAGE_SUCCESS,
          GET_OR_CREATE_OFFICES_STORAGE_FAILURE,
        ],
        partition: SERVICE_PARTITION_NAME,
        doNotCheckError: true,
      }),
    );
  }

  useEffect(() => {
    if (partitionUuid && isUpdateRequired && isAdminOrRoot) {
      onReloadFunc();
    }
  }, [partitionUuid, isUpdateRequired, isAdminOrRoot]);

  return {
    uuid: officesStorageUUID,
    entity: officesStorage,
    isFetching,
    onReloadFunc,
  };
}

export default useOfficesStorage;
