import React from 'react';
import PageWrapper from '../../components/PageWrapper';
import EventsManagmentView from '../../components/metaevents/EventsManagmentView';

export default function EventsPanelPage() {
  return (
    <PageWrapper
      title="Управление событиями"
      needWrapperClassName={false}
      className="-pt-6"
    >
      <EventsManagmentView />
    </PageWrapper>
  );
}
