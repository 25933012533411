export const allLogTypes = [
  'monthlyReportLog',
  'monthlyReportDayLog',
  'vacationLog',
  'departmentLog',
  'instructionLog',
  'fileLog',
  'userLog',
  'employeeRequestLog',
];

export const entityNamesForLogs = {
  monthlyReportLog: 'Отчет',
  monthlyReportDayLog: 'День',
  vacationLog: 'Отпуск',
  departmentLog: 'Отдел',
  instructionLog: 'Инструкция',
  fileLog: 'Файл',
  userLog: 'Подрядчик',
  employeeRequestLog: 'Заявка на подрядчика',
};
