import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  Button, Flex, Input, Typography,
} from 'antd';
import {
  bool, func, shape, string,
} from 'prop-types';
import useEmployeeRequestAPI from '../../../api/useEmployeeRequestAPI';

const { TextArea } = Input;
const { Text } = Typography;

export default function EmployeeRequestForm({
  departmentUUID, requestUUID, params, closeModal, isEditMode,
}) {
  const { createEmployeeRequest, editEmployeeRequest } = useEmployeeRequestAPI();

  const [description, setDescription] = useState('');

  useEffect(() => {
    if (!isEmpty(params)) {
      setDescription(params.description);
    }
  }, []);

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };
  const onCreateRequest = async () => {
    const newRequest = {
      entity_type: 'employeeRequest',
      params: {
        type: 'request',
        status: 'pending',
        property: 'high',
        description,
      },
    };
    createEmployeeRequest(departmentUUID, newRequest);
    setDescription('');
    closeModal();
  };
  const onEditRequest = async () => {
    const newRequest = {
      entity_type: 'employeeRequest',
      params: {
        description,
      },
    };
    editEmployeeRequest(requestUUID, newRequest);
    setDescription('');
    closeModal();
  };

  return (
    <>
      <Flex className="mb-2">
        <Text>
          <Text className="mr-2 text-stone-500">
            Тип заявки:
          </Text>
          На нового подрядчика.
        </Text>
      </Flex>
      <Text className="text-stone-500">
        Комментарий:
      </Text>
      <TextArea
        value={description}
        onChange={onChangeDescription}
        style={{ height: '250px', resize: 'none' }}
        id="employee_request_description"
        className="mt-2"
      />
      <Flex className="mt-4 justify-end">
        <Button
          className="mr-2 !border-red-500 text-red-500 hover:bg-red-500 hover:!text-white"
          onClick={closeModal}
        >
          Отмена
        </Button>
        <Button
          className="border-green-500 text-green-500 hover:bg-green-500 hover:!text-white"
          onClick={isEditMode ? onEditRequest : onCreateRequest}
        >
          { isEditMode ? 'Редактировать' : 'Создать' }
        </Button>
      </Flex>
    </>
  );
}

EmployeeRequestForm.propTypes = {
  departmentUUID: string,
  requestUUID: string,
  closeModal: func.isRequired,
  isEditMode: bool,
  params: shape({
    description: string,
    property: string,
    status: string,
    type: string,
  }),
};

EmployeeRequestForm.defaultProps = {
  departmentUUID: '',
  requestUUID: '',
  isEditMode: false,
  params: {},
};
