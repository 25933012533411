/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/**
 * Начальное состояние хранилища
 * @typedef {Object} CommonStoragesState
 * @property {Object|null} officesStorage - Storage для офисов
 * @property {boolean} isFetchingOfficesSt - Флаг загрузки storage офисов
 */
const initialState = {
  officesStorage: null,
  isFetchingOfficesSt: true,
};

const commonStoragesSlice = createSlice({
  name: 'commonStoragesSlice',
  initialState,
  reducers: {
    RESET_STORAGE(state, action) {
      if (action?.payload === 'officesStorage') {
        state.officesStorage = null;
        state.isFetchingOfficesSt = true;
      }
    },
    GET_OR_CREATE_OFFICES_STORAGE_REQUEST(state) {
      state.officesStorage = null;
      state.isFetchingOfficesSt = true;
    },
    GET_OR_CREATE_OFFICES_STORAGE_SUCCESS(state, action) {
      const { payload } = action;

      state.officesStorage = payload;
      state.isFetchingOfficesSt = false;
    },
    GET_OR_CREATE_OFFICES_STORAGE_FAILURE(state) {
      state.isFetchingOfficesSt = false;
    },
  },
});

export const {
  RESET_STORAGE,
  GET_OR_CREATE_OFFICES_STORAGE_REQUEST,
  GET_OR_CREATE_OFFICES_STORAGE_SUCCESS,
  GET_OR_CREATE_OFFICES_STORAGE_FAILURE,
} = commonStoragesSlice.actions;

export default commonStoragesSlice.reducer;
