import React, { useState } from 'react';
import cn from 'classnames';
import {
  Flex, Spin, Typography, Tag,
} from 'antd';
import { isEmpty } from 'lodash';
import { string } from 'prop-types';
import CreateDepartmentRequestButton from '../operations/CreateDepartmentRequestButton';
import { formatDate } from '../../../utils/dateTimeUtils';
import DepartmentRequestDetails from './DepartmentRequestDetails';
import EmployeeRequestStatus from '../../commonComponents/entityCardComponents/EmployeeRequestStatus';
import BaseTable from '../../../w54_components/BaseTable/BaseTable';
import useDepartmentRequests from '../../../redux/departmentRequest/hooks/useDepartmentRequests';

const { Text } = Typography;

const REQUEST_TYPE_LABELS = {
  contractor: { label: 'на подрядчика', color: 'blue' },
  common: { label: 'общая', color: 'default' },
};

export default function DepartmentRequestPanel({ departmentUUID }) {
  const [selectedRequest, setSelectedRequest] = useState({});

  const {
    requests,
    onReloadRequests,
  } = useDepartmentRequests({
    parentUUID: departmentUUID,
  });

  const onRow = (request) => ({
    onClick: () => {
      setSelectedRequest({ ...request });
    },
  });

  const columns = [
    {
      title: 'Создал',
      key: 'uinfo',
      dataIndex: ['uinfo'],
      width: '25%',
      render: (cell) => (
        <Text>
          {`${cell.first_name} ${cell.last_name}`}
        </Text>
      ),
    },
    {
      title: 'Заявка',
      key: 'type',
      dataIndex: ['params', 'type'],
      width: '15%',
      render: (type) => {
        const typeConfig = REQUEST_TYPE_LABELS[type] || { label: 'Общая', color: 'default' };
        return (
          <Tag color={typeConfig.color}>
            {typeConfig.label}
          </Tag>
        );
      },
    },
    {
      title: 'Статус',
      key: 'status',
      dataIndex: ['params', 'status'],
      width: '20%',
      render: (status) => (
        <EmployeeRequestStatus status={status} />
      ),
    },
    {
      title: 'Создана',
      key: 'date',
      dataIndex: ['created'],
      width: '25%',
      render: (cell) => (
        <Text>
          {formatDate(cell)}
        </Text>
      ),
    },
  ];

  const onCreateCallback = () => {
    onReloadRequests();
  };

  return (
    <Flex
      vertical
      className="w-full h-100"
    >
      <Flex className="my-2">
        <CreateDepartmentRequestButton
          onCreate={onCreateCallback}
          departmentUUID={departmentUUID}
        />
      </Flex>
      <Spin
        className="w-full h-100"
        spinning={false}
      >
        <BaseTable
          data={requests}
          columns={columns}
          rowClassName="cursor-pointer"
          size="small"
          rowKey="uuid"
          onRow={onRow}
          className={cn('w-full h-full !mb-3')}
          useCustomPagination
          disablePagination
        />
      </Spin>
      {!isEmpty(selectedRequest) && (
        <DepartmentRequestDetails
          isOpen={!isEmpty(selectedRequest)}
          onCloseModal={() => setSelectedRequest({})}
          request={selectedRequest}
        />
      )}
    </Flex>
  );
}

DepartmentRequestPanel.propTypes = {
  departmentUUID: string.isRequired,
};
