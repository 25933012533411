import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import {
  Alert, Badge, Flex, Spin, Typography, Empty, Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import {
  mdiBedKing, mdiCalendarClock, mdiAccountSchool, mdiBeach, mdiChevronDown, mdiChevronUp,
} from '@mdi/js';
import { head } from 'lodash';
import useVacationsAPI from '../../../api/useVacationsAPI';
import useAPI from '../../../api/useAPI';
import useOnboardingAPI from '../../../api/useOnboardingAPI';
import { enumerateDaysBetweenDates } from '../../../utils/commonUtils';
import { getFullNameOrInitials } from '../../../54origins/utils54origins';

const { Title } = Typography;

export default function DepartmentDashboard({
  departmentUUID = '',
}) {
  const { getListOfVacations } = useVacationsAPI();
  const { getMonthlyReport } = useAPI();
  const { getListOfOnboarding } = useOnboardingAPI();

  const [eventsByDay, setEventsByDay] = useState({});
  const [loading, setLoading] = useState(true);
  const [hoveredEvent, setHoveredEvent] = useState(null);

  const loadEvents = async () => {
    setLoading(true);
    try {
      const startDate = dayjs().format('YYYY-MM-DD');
      const endDate = dayjs().add(14, 'days').format('YYYY-MM-DD');

      const [vacations, monthlyReports, onboardings] = await Promise.all([
        getListOfVacations(departmentUUID, {
          params: {
            rangeDates: enumerateDaysBetweenDates(startDate, endDate),
            status: ['pending', 'pre-agreed', 'approved'],
          },
          depth: 0,
        }),
        getMonthlyReport(departmentUUID, {
          params: {
            date: [
              dayjs().format('YYYY-MM'),
              dayjs().add(1, 'month').format('YYYY-MM'),
            ],
          },
          depth: 0,
        }),
        getListOfOnboarding(departmentUUID),
      ]);

      // Создаем объект с событиями по дням
      const events = {};
      const dates = enumerateDaysBetweenDates(startDate, endDate);

      // Инициализируем все дни пустыми массивами
      dates.forEach((date) => {
        events[date] = [];
      });

      // Добавляем отпуска
      (vacations?.data || []).forEach((v) => {
        const vacationDates = enumerateDaysBetweenDates(v.params.start_date, v.params.end_date);
        vacationDates.forEach((date) => {
          if (events[date]) {
            events[date].push({
              type: 'vacation',
              title: `Отпуск: ${getFullNameOrInitials(v, 'fullname')}`,
              icon: mdiBeach,
              color: '#0EA5E9',
              originalEvent: v,
            });
          }
        });
      });

      // Обрабатываем больничные и отгулы из месячных отчетов
      (monthlyReports?.data || []).forEach((report) => {
        const { days = [], uinfo } = report.params;
        days.forEach((day) => {
          if (events[head(day?.rangeDates)] && day.type) {
            if (day.type === 'sickday') {
              events[head(day?.rangeDates)].push({
                type: 'sickday',
                title: `Больничный: ${getFullNameOrInitials(report, 'fullname')}`,
                icon: mdiBedKing,
                color: '#DC2626',
                originalEvent: { ...day, report_date: report.params.date },
              });
            } else if (day.type === 'dayoff') {
              events[head(day?.rangeDates)].push({
                type: 'dayoff',
                title: `Отгул: ${getFullNameOrInitials(report, 'fullname')}`,
                icon: mdiCalendarClock,
                color: '#EA580C',
                originalEvent: { ...day, report_date: report.params.date },
              });
            }
          }
        });
      });

      // Добавляем онбординги
      (onboardings?.data || []).forEach((o) => {
        if (events[o.params.start_date]) {
          events[o.params.start_date].push({
            type: 'onboarding',
            title: `Онбординг: ${getFullNameOrInitials(o, 'fullname')}`,
            icon: mdiAccountSchool,
            color: '#059669',
            originalEvent: o,
          });
        }
      });

      setEventsByDay(events);
    } catch (error) {
      console.error('Error loading events:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (departmentUUID) {
      loadEvents();
    }
  }, [departmentUUID]);

  const hasEvents = Object.values(eventsByDay).some((events) => events.length > 0);

  const getEventDetails = (event, originalEvent) => {
    const details = [];

    switch (event.type) {
      case 'vacation':
        details.push(
          `Период: ${dayjs(originalEvent.params.start_date).format('DD.MM.YYYY')} - ${dayjs(originalEvent.params.end_date).format('DD.MM.YYYY')}`,
          `Статус: ${originalEvent.params.status === 'approved' ? 'Подтвержден' : 'На согласовании'}`,
          originalEvent.params.comment && `Комментарий: ${originalEvent.params.comment}`,
          `Создан: ${dayjs(originalEvent.created).format('DD.MM.YYYY HH:mm')}`,
        );
        break;
      case 'sickday':
        details.push(
          `Дата: ${dayjs(head(originalEvent.rangeDates)).format('DD.MM.YYYY')}`,
          originalEvent.comment && `Комментарий: ${originalEvent.comment}`,
          `Отмечен в отчете за: ${dayjs(originalEvent.report_date).format('MMMM YYYY')}`,
        );
        break;
      case 'dayoff':
        details.push(
          `Дата: ${dayjs(head(originalEvent.rangeDates)).format('DD.MM.YYYY')}`,
          originalEvent.comment && `Комментарий: ${originalEvent.comment}`,
          `Отмечен в отчете за: ${dayjs(originalEvent.report_date).format('MMMM YYYY')}`,
        );
        break;
      case 'onboarding':
        details.push(
          `Дата начала: ${dayjs(originalEvent.params.start_date).format('DD.MM.YYYY')}`,
          originalEvent.params.description && `Описание: ${originalEvent.params.description}`,
          `Создан: ${dayjs(originalEvent.created).format('DD.MM.YYYY HH:mm')}`,
        );
        break;
      default:
        break;
    }

    return details.filter(Boolean).join('\n');
  };

  const handleMouseEnter = (eventKey) => {
    setHoveredEvent(eventKey);
  };

  const handleMouseLeave = () => {
    setHoveredEvent(null);
  };

  return (
    <Spin spinning={loading}>
      <Flex vertical gap="large" className="mb-4">
        {/* <Alert
          message="События департамента"
          description="Отпуска, больничные, отгулы и онбординги подрядчиков на ближайшие 14 дней"
          type="info"
          showIcon
        /> */}

        {!hasEvents && !loading && (
          <Empty description="Нет событий на ближайшие 14 дней" />
        )}

        {Object.entries(eventsByDay).map(([date, events]) => (
          <div key={date} className="bg-white rounded-xl border border-gray-200 shadow-sm -mb-6">
            <div className="bg-gray-50 border-b border-gray-200 rounded-t-xl mb-2">
              <Title level={5} className="!mb-0 !text-gray-700">
                {dayjs(date).format('DD MMMM, dddd')}
              </Title>
            </div>

            <div className="">
              <Flex vertical gap="middle">
                {events.length > 0 ? (
                  events.map((event, index) => {
                    const eventKey = `${date}-${index}`;
                    const isHovered = hoveredEvent === eventKey;

                    return (
                      <div
                        key={eventKey}
                        onMouseEnter={() => handleMouseEnter(eventKey)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <div
                          className="flex items-center p-3 rounded-lg hover:bg-gray-50 transition-colors"
                          style={{
                            backgroundColor: `${event.color}10`,
                            borderLeft: `4px solid ${event.color}`,
                          }}
                        >
                          <Flex vertical gap="middle">
                            <Flex>
                              <div
                                className="flex items-center justify-center w-8 h-8 rounded-lg mr-3"
                                style={{ backgroundColor: `${event.color}20` }}
                              >
                                <Icon
                                  path={event.icon}
                                  size={0.9}
                                  style={{ color: event.color }}
                                />
                              </div>
                              <span className="text-gray-700 font-medium flex-grow">
                                {event.title}
                              </span>
                            </Flex>
                            {isHovered && (
                              <div className="p-3 mt-1 transition-all">
                                <div className="text-sm text-gray-600">
                                  {getEventDetails(event, event.originalEvent).split('\n').map((line, i) => (
                                    <div key={i} className="mb-1">
                                      {line}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </Flex>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex items-center p-3 rounded-lg bg-gray-50">
                    <Flex align="center" className="text-gray-500">
                      <span className="text-sm">
                        На этот день событий нет
                      </span>
                    </Flex>
                  </div>
                )}
              </Flex>
            </div>
          </div>
        ))}
      </Flex>
    </Spin>
  );
}

DepartmentDashboard.propTypes = {
  departmentUUID: string,
};
