import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  get, isEmpty, isEqual, omit,
} from 'lodash';
import useOfficesAPI from '../../api/useOfficesAPI';
import { getDeliveryPartition } from '../../redux/config/selectors';

const useOffices = ({
  parentUUID,
  defaultConfig = {},
}) => {
  const dispatch = useDispatch();

  const { getListOfOffices } = useOfficesAPI();

  const [offices, setOffices] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    limit: 20,
    offset: 0,
    ...defaultConfig,
  });

  const initFunc = async () => {
    if (parentUUID) {
      setIsFetching(true);

      const res = await getListOfOffices(parentUUID, config, [
        'GET_OFFICES_FOR_PAGE_REQUEST',
        'GET_OFFICES_FOR_PAGE_SUCCESS',
        'GET_OFFICES_FOR_PAGE_FAILURE',
      ]);

      setOffices(res?.data ?? []);
      setTotal(res?.total ?? 0);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    setConfig((prev) => ({ ...omit(prev, 'search_data'), ...defaultConfig }));
  }, [JSON.stringify(defaultConfig)]);

  useEffect(() => {
    initFunc();
  }, [parentUUID, JSON.stringify(config)]);

  return {
    offices,
    isFetching,
    paginationOptions: {
      pageLimit: get(config, 'limit', 20),
      total,
      changePageLimit: (newLimit) => {
        setCurrentPage(1);
        setConfig((prev) => ({ ...prev, offset: 0, limit: newLimit }));
      },
      newCurrentPage: currentPage,
      onChangePageCallback: (newPage) => {
        setCurrentPage(newPage);
        setConfig((prev) => ({ ...prev, offset: (newPage - 1) * prev.limit }));
      },
      getPaginationOptions: (newPart) => {
        const valueSearch = get(newPart, 'search', '');

        if (valueSearch) {
          setConfig((prev) => ({
            ...prev,
            ...newPart,
            searchData: {
              value: valueSearch,
              ignore_case: true,
              fields: { params: ['title'] },
            },
          }));
        } else {
          setConfig((prev) => ({ ...prev, ...newPart }));
        }
      },
    },
    onReloadOffices: initFunc,
  };
};

export default useOffices;
