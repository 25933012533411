/* eslint-disable default-param-last */

import { useDispatch } from 'react-redux';
import { get, isArray } from 'lodash';
import { SERVICE_PARTITION_NAME } from './app_config';
import {
  entityCreate, entityDelete, entityRead, entityUpdate, getListAndReadEntities,
} from './actions/entity';
import useLogsAPI from './useLogsAPI';
import antNotification from '../utils/antNotification';

export default function useDepartmentsAPI() {
  const dispatch = useDispatch();

  const {
    createLog,
  } = useLogsAPI();

  const readListOfDepartments = (entityUUIDs, data = {}, constants) => {
    try {
      const config = {
        entity_type: 'department',
        ...data,
      };
      if (isArray(entityUUIDs)) {
        config.entity_uuids = entityUUIDs;
      } else {
        config.entity_uuid = entityUUIDs;
      }
      return dispatch(
        entityRead({
          data: config,
          constants: constants || [
            'READ_LIST_OF_DEPARTMENTS_REQUEST',
            'READ_LIST_OF_DEPARTMENTS_SUCCESS',
            'READ_LIST_OF_DEPARTMENTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список отделов!');
      return null;
    }
  };

  const getListOfDepartments = (parent, data = {}, constants) => {
    try {
      return dispatch(
        getListAndReadEntities({
          data: {
            entity_type: 'department',
            parent,
            depth: 0,
            ...data,
          },
          constants: constants || [
            'GET_LIST_OF_DEPARTMENTS_REQUEST',
            'GET_LIST_OF_DEPARTMENTS_SUCCESS',
            'GET_LIST_OF_DEPARTMENTS_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось получить список отделов!');
      return null;
    }
  };

  const createDepartment = async (parent, data, constants) => {
    try {
      const newDepartment = await dispatch(
        entityCreate({
          data: {
            entity_type: 'department',
            parent,
            ...data,
          },
          constants: constants || [
            'CREATE_DEPARTMENT_REQUEST',
            'CREATE_DEPARTMENT_SUCCESS',
            'CREATE_DEPARTMENT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(newDepartment, '[0].uuid', '')) {
        createLog({
          parent: get(newDepartment, '[0].uuid', ''),
          type: 'CREATE',
          entityType: 'department',
          values: get(newDepartment, '[0].params', ''),
        });
      }

      return newDepartment;
    } catch (e) {
      antNotification.error('Не удалось создать отдел!');
      return null;
    }
  };

  const updateDepartment = async (entityUUID, data, constants) => {
    try {
      const updatedDepartment = await dispatch(
        entityUpdate({
          data: {
            entity_type: 'department',
            entity_uuid: entityUUID,
            ...data,
          },
          constants: constants || [
            'UPDATE_DEPARTMENT_REQUEST',
            'UPDATE_DEPARTMENT_SUCCESS',
            'UPDATE_DEPARTMENT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );

      if (get(updatedDepartment, '[0].uuid', '')) {
        createLog({
          parent: get(updatedDepartment, '[0].uuid', ''),
          type: 'UPDATE',
          entityType: 'department',
          values: get(data, 'params', {}),
        });
      }

      return updatedDepartment;
    } catch (e) {
      antNotification.error('Не удалось изменить отдел!');
      return null;
    }
  };

  const deleteDepartment = (entityUUID, data, constants) => {
    try {
      return dispatch(
        entityDelete({
          data: {
            entity_type: 'department',
            entity_uuid: entityUUID,
            ...data,
          },
          constants: constants || [
            'DELETE_DEPARTMENT_REQUEST',
            'DELETE_DEPARTMENT_SUCCESS',
            'DELETE_DEPARTMENT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
        }),
      );
    } catch (e) {
      antNotification.error('Не удалось удалить отдел!');
      return null;
    }
  };

  return {
    createDepartment,
    getListOfDepartments,
    updateDepartment,
    deleteDepartment,
    readListOfDepartments,
  };
}
