import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  Alert, Button, Col, Divider, Form, Input, Modal, Row, Segmented, Typography, Flex, Space,
} from 'antd';
import { capitalize, head } from 'lodash';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { mdiFileDocumentEdit } from '@mdi/js';
import Icon from '@mdi/react';
import { getBusinessDays } from '../../../../utils/dateTimeUtils';
import { enumerateDaysBetweenDates } from '../../../../utils/commonUtils';
import useAPI from '../../../../api/useAPI';
import { getArrByType, getHours } from '../../dayOff/utils';
import AntUploaderFiles from '../../../../components/commonComponents/AntUploaderFiles';
import { getMonthlyStorageUUID, getUserRootInfo, getVacationStorageUUID } from '../../../../redux/storages/selectors';
import useVacationsAPI from '../../../../api/useVacationsAPI';
import { entityRead, getListAndReadEntities } from '../../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../../api/app_config';
import { isRootOrAdmin } from '../../../../redux/profile/selectors';
import MetaeventLink from '../../../../components/events/MetaeventLink';

const { Paragraph } = Typography;

const optionsTypeForm = [{
  label: 'Данные календаря',
  value: 'calendar',
}, {
  label: 'Данные предыдущего отчета',
  value: 'lastreport',
}];

function UserStatisticsForm({
  isAheadOfTime,
  isModalOpen,
  onCancelModal,
  currentMonth,
  onFinish,
  formSettings,
  actor,
}) {
  console.log(isModalOpen);
  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const validTotalWorks = +Form.useWatch('totalWorks', form);

  const userRootInfo = useSelector(getUserRootInfo);

  const vacationStorageUUID = useSelector(getVacationStorageUUID);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const isAdOrRoot = useSelector(isRootOrAdmin);

  const [userDayOff, setUserDayOff] = useState([]);
  const [monthlyReport, setMonthlyReport] = useState(null);
  const [vacationsArr, setVacationsArr] = useState([]);
  const [filesList, setFiles] = useState([]);
  const [selectedTypeForm, setSelectedTypeForm] = useState('calendar');
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  // const [metaevent, setMetaevent] = useState(null);

  const {
    getMonthlyReport,
  } = useAPI();

  const {
    getListOfVacations,
  } = useVacationsAPI();

  const {
    title, commentLabel, instruction, instructionTitle, metaevent,
  } = formSettings;

  const begin = dayjs(currentMonth).startOf('month');
  const end = dayjs(currentMonth).endOf('month');
  const startMonth = dayjs(currentMonth).startOf('month').format('YYYY-MM-DD');
  const endMonth = dayjs(currentMonth).endOf('month').format('YYYY-MM-DD');

  const isWeekday = (date) => {
    const dayOfWeek = dayjs(date).day();
    return dayOfWeek >= 1 && dayOfWeek <= 5;
  };

  const userStatistic = useMemo(() => {
    const data = {
      office_work: 0,
      remote_work: 0,
      work_on_day_off: 0,
      totalWorks: 0,
      sickday: 0,
      holidays: 0,
      vacation: 0,
      projectDayOffs: 0,
      dayoff: 0,
      localHoliday: 0,
      totalDayOff: 0,
      comment: '',
      bench_1_month: 0,
      bench_over_1_month: 0,
      bench_over_3_month: 0,
      totalBenches: 0,
      internalWork: 0,
      clientWork: 0,
    };

    const allDaysWithType = [...getArrByType([
      ...vacationsArr, ...userDayOff,
    ], enumerateDaysBetweenDates(startMonth, endMonth))]
      .map(([key, value]) => ({ ...value }));

    data.office_work = getHours(allDaysWithType.filter((item) => item.type === 'office_work'));
    data.remote_work = getHours(allDaysWithType.filter((item) => item.type === 'remote_work'));
    data.work_on_day_off = getHours(allDaysWithType.filter((item) => item.type === 'work_on_day_off'));

    data.totalWorks = data.office_work + data.remote_work + data.work_on_day_off;

    data.bench_1_month = (allDaysWithType.filter((item) => item.type === 'bench_1_month').length || 0) * 8;
    data.bench_over_1_month = (allDaysWithType.filter((item) => item.type === 'bench_over_1_month').length || 0) * 8;
    data.bench_over_3_month = (allDaysWithType.filter((item) => item.type === 'bench_over_3_month').length || 0) * 8;

    data.totalBenches = data.bench_1_month + data.bench_over_1_month
      + data.bench_over_3_month;

    data.sickday = (allDaysWithType.filter((item) => item.type === 'sickday').length || 0) * 8;
    // data.holidays = (allDaysWithType.filter((item) => item.type === 'holidays').length || 0) * 8;
    data.vacation = (allDaysWithType.filter((item) => item.type === 'vacation' && isWeekday(item?.date)).length || 0) * 8;
    data.dayoff = (allDaysWithType.filter((item) => item.type === 'dayoff').length || 0) * 8;

    data.totalDayOff = data.sickday + data.vacation
      + data.dayoff + data.bench_1_month + data.bench_over_1_month
      + data.bench_over_3_month;

    return data;
  }, [
    JSON.stringify(vacationsArr),
    JSON.stringify(userDayOff),
    JSON.stringify(startMonth),
    JSON.stringify(endMonth),
    isModalOpen,
  ]);

  const getListAgreedVacations = async () => {
    const configRequest = {
      actor,
      params: {
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          dayjs(startMonth).format('YYYY-MM-DD'),
          dayjs(endMonth).format('YYYY-MM-DD'),
        ),
      },
    };
    const res = await getListOfVacations(
      vacationStorageUUID,
      configRequest,
      [
        'GET_VACATIONS_REQUEST',
        'GET_VACATIONS_SUCCESS',
        'GET_VACATIONS_FAILURE',
      ],
    );
    setVacationsArr(res?.data);
  };

  const getListDayOffsAndReport = async () => {
    const configRequest = {
      actor,
      params: {
        date: dayjs(startMonth).format('YYYY-MM'),
      },
      with_files: true,
      depth: 0,
    };
    const res = await getMonthlyReport(monthlyStorageUUID, configRequest);

    setMonthlyReport(res?.data?.[0]);
    setUserDayOff(res?.data?.[0]?.params?.days || []);
  };

  const onSubmit = async (values) => {
    setDisabledSubmit(true);

    let data;

    if (selectedTypeForm === 'calendar') {
      data = {
        ...userStatistic,
        ...values,
      };
    } else {
      data = {
        ...monthlyReport?.params?.statistics,
        ...values,
      };
    }
    await onFinish(
      data,
      filesList,
      monthlyReport?.uuid,
      monthlyReport?.files,
    );

    setFiles([]);
    setDisabledSubmit(false);
  };

  const onChangeTypeForm = (type) => {
    setSelectedTypeForm(type);

    if (type === 'calendar') {
      form.setFieldsValue({
        ...userStatistic,
        comment: '',
      });
    } else {
      form.setFieldsValue({
        office_work: 0,
        // remote_work: 0,
        // work_on_day_off: 0,
        totalWorks: 0,
        sickday: 0,
        // holidays: 0,
        vacation: 0,
        // projectDayOffs: 0,
        dayoff: 0,
        // localHoliday: 0,
        totalDayOff: 0,
        comment: '',
        bench_1_month: 0,
        bench_over_1_month: 0,
        bench_over_3_month: 0,
        totalBenches: 0,
        ...(monthlyReport?.params?.statistics ?? {}),
      });
    }
  };

  // const initMetaeventInstruction = async () => {
  //   const res = await dispatch(entityRead({
  //     data: {
  //       entity_uuid: userRootInfo?.params?.personal_metaevent_create_monthly_report,
  //     },
  //     constants: [
  //       'READ_METAEVENTS_REQUEST',
  //       'READ_METAEVENTS_SUCCESS',
  //       'READ_METAEVENTS_FAILURE',
  //     ],
  //     partition: SERVICE_PARTITION_NAME,
  //   }));
  //   console.log('инструкция', res);
  //   setMetaevent(head(res));
  // };

  // useEffect(() => {
  //   if (userRootInfo?.params?.personal_metaevent_create_monthly_report) {
  //     initMetaeventInstruction();
  //   }
  // }, [userRootInfo?.params?.personal_metaevent_create_monthly_report]);

  useEffect(() => {
    if (actor && vacationStorageUUID && monthlyStorageUUID) {
      getListDayOffsAndReport();
      getListAgreedVacations();
    }
  }, [
    actor,
    vacationStorageUUID,
    startMonth,
    isModalOpen,
    monthlyStorageUUID,
  ]);

  useEffect(() => {
    form.setFieldsValue({
      ...userStatistic,
      comment: '',
    });
  }, [
    JSON.stringify(userStatistic),
    isModalOpen,
  ]);

  useEffect(() => {
    setSelectedTypeForm('calendar');
  }, [isModalOpen]);

  return (
    <Modal
      width={1000}
      title={(
        <Flex align="center" gap="middle" className="py-2">
          <div className="flex items-center justify-center w-10 h-10 rounded-xl bg-gradient-to-r from-blue-500 to-blue-600">
            <Icon
              path={mdiFileDocumentEdit}
              size={1}
              className="text-white"
            />
          </div>
          <div>
            <div className="text-xl font-semibold">{title}</div>
            <div className="text-sm text-gray-500">
              Отчетный период:
              {' '}
              {dayjs(currentMonth).format('MMMM YYYY')}
            </div>
          </div>
        </Flex>
      )}
      open={isModalOpen}
      onCancel={() => {
        setFiles([]);
        onCancelModal();
      }}
      destroyOnClose
      centered
      footer={null}
      className="monthly-report-modal"
    >
      <div className="bg-gray-50 -mx-6 px-6 -mt-2 border-y border-gray-100">
        <Flex align="center" gap="middle">
          <div className="text-gray-600">
            План рабочих часов:
            <span className="font-semibold ml-1">
              {getBusinessDays(end, begin) * 8}
            </span>
          </div>
          {monthlyReport?.params?.status === 'submitted' && (
            <Segmented
              options={optionsTypeForm}
              value={selectedTypeForm}
              defaultValue="calendar"
              onChange={onChangeTypeForm}
              className="bg-white"
            />
          )}
        </Flex>
      </div>

      {instruction && (
        <Alert
          className="mt-2"
          message={(
            <Flex justify="space-between" className="w-full">
              <span className="font-medium">
                {instructionTitle}
              </span>
              {isAdOrRoot && <MetaeventLink metaevent={metaevent} />}
            </Flex>
          )}
          description={(
            <div className="whitespace-pre-wrap text-sm text-gray-600">
              {instruction}
            </div>
          )}
          type="info"
          showIcon
        />
      )}

      {isAheadOfTime && (
        <Alert
          className="mt-4"
          message="Внимание! Досрочная отправка отчета"
          description="Отправка отчета досрочно крайне нежелательна. Пожалуйста, укажите причину в поле комментария."
          type="warning"
          showIcon
        />
      )}

      <Form
        preserve={false}
        form={form}
        name="report"
        autoComplete="off"
        layout="vertical"
        initialValues={{
          ...userStatistic,
          comment: '',
          feedback: '',
        }}
        onFinish={onSubmit}
        className="mt-2"
      >
        <div className="grid grid-cols-2 gap-8">
          {/* Left Column */}
          <div>
            <div className="bg-white rounded-xl border border-gray-200 p-0 mt-3">
              <div className="text-base font-semibold mb-0">Проектные часы</div>
              <Flex vertical gap="middle">
                <Form.Item
                  name="totalWorks"
                  label={<span className="font-medium">Общие проектные часы</span>}
                  rules={[{
                    required: true,
                    pattern: /^\.?(?!-)\d+(?:\.\d{1,2})?$/,
                    message: 'Обязательное поле. Целое или дробное число.',
                  }]}
                  className="mb-0"
                  normalize={(value) => value.replace(/[^\d.]/g, '')}
                >
                  <Input
                    size="large"
                    maxLength={6}
                    placeholder="0"
                    className="text-center"
                    suffix="часов"
                  />
                </Form.Item>
                <div className="text-sm text-gray-500 -mt-4 -mb-4">
                  В отчете будет округлено до:
                  {' '}
                  {Math.ceil(validTotalWorks)}
                  {' '}
                  ч.
                </div>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="clientWork"
                      label={<span className="font-medium">Клиентский проект</span>}
                      normalize={(value) => value.replace(/[^\d]/g, '')}
                    >
                      <Input size="large" placeholder="0" suffix="ч." />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="internalWork"
                      label={<span className="font-medium">Внутренний проект</span>}
                      normalize={(value) => value.replace(/[^\d]/g, '')}
                    >
                      <Input size="large" placeholder="0" suffix="ч." />
                    </Form.Item>
                  </Col>
                </Row>
              </Flex>
            </div>
          </div>

          {/* Right Column */}
          <div>
            <div className="bg-white rounded-xl border border-gray-200 pt-1 mt-2 -mb-4">
              <div className="text-base font-semibold mb-0">Нерабочие часы и бенч</div>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    name="dayoff"
                    label={<span className="font-medium">Отгул</span>}
                  >
                    <Input size="large" placeholder="0" suffix="ч." />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="sickday"
                    label={<span className="font-medium">Больничный</span>}
                  >
                    <Input size="large" placeholder="0" suffix="ч." />
                  </Form.Item>
                </Col>
                <Col span={12} className="-mt-5">
                  <Form.Item
                    name="vacation"
                    label={<span className="font-medium">Отпуск</span>}
                  >
                    <Input size="large" placeholder="0" suffix="ч." />
                  </Form.Item>
                </Col>
                <Col span={12} className="-mt-5">
                  <Form.Item
                    name="totalBenches"
                    label={<span className="font-medium">Бенч</span>}
                  >
                    <Input size="large" placeholder="0" suffix="ч." />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl border border-gray-200 p-0 -mt-2">
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <Form.Item
                name="comment"
                label={<span className="text-base ">{commentLabel}</span>}
              >
                <TextArea
                  placeholder="Введите комментарий..."
                  rows={4}
                  className="resize-none"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="-mt-10">
              <Form.Item
                name="feedback"
                className="mt-0"
                label={<span className="font-medium">Предложения по улучшению</span>}
                extra="Здесь вы можете оставить свои предложения по улучшению рабочих процессов, инструментов или других аспектов работы"
              >
                <TextArea
                  placeholder="Ваши идеи и предложения..."
                  rows={4}
                  className="resize-none"
                />
              </Form.Item>
            </Col>
            <Col span={24} className="-mt-12">
              <Form.Item
                label={<span className="font-medium">Файлы (больничный)</span>}
              >
                <AntUploaderFiles
                  hideTitle
                  filesList={filesList}
                  setFiles={setFiles}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Flex justify="space-between" align="center" className="mt-0">
          <Alert
            message="Изменения не сохраняются после выхода"
            type="warning"
            showIcon
            className="mb-0"
          />
          <Space>
            <Button
              size="large"
              onClick={() => {
                setFiles([]);
                onCancelModal();
              }}
            >
              Отмена
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              disabled={disabledSubmit}
            >
              Отправить отчет
            </Button>
          </Space>
        </Flex>
      </Form>
    </Modal>
  );
}

export default UserStatisticsForm;

UserStatisticsForm.propTypes = {

};
