import React, { useState } from 'react';
import {
  Button, Form, Input, Modal,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import useDepartmentRequestsAPI from '../../api/useDepartmentRequestsAPI';

const { TextArea } = Input;

export default function EditRequest({
  uuid, row, isDepartmentRoutes, onUpdate,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const { updateDepartmentRequest } = useDepartmentRequestsAPI();

  const handleEdit = async (values) => {
    onUpdate?.();
    await updateDepartmentRequest(uuid, {
      params: {
        ...row.params,
        ...values,
      },
    });
    setIsModalOpen(false);
  };

  if (row.params.status === 'approved' || row.params.status === 'rejected') {
    return null;
  }

  return (
    <>
      <Button
        icon={<EditOutlined />}
        onClick={() => setIsModalOpen(true)}
      >
        Редактировать
      </Button>
      <Modal
        title="Редактирование заявки"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleEdit}
          initialValues={{
            description: row.params.description,
          }}
        >
          <Form.Item
            name="description"
            label="Описание"
            rules={[{ required: true, message: 'Введите описание' }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item className="mb-0 text-right">
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

EditRequest.propTypes = {
  uuid: PropTypes.string.isRequired,
  row: PropTypes.shape({
    params: PropTypes.shape({
      status: PropTypes.string,
      description: PropTypes.string,
    }),
  }).isRequired,
  isDepartmentRoutes: PropTypes.bool.isRequired,
};
