import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import config from './config/reducer';
import profileInfo from './profile/reducer';
import publicUI from './publicInterfaces/reducers';
import userVacations from './userVacation/reducer';
import vacations from './vacations/reducer';
import monthlyReport from './monthlyReport/reducer';
import globalUpdate from './globalUpdate/reducer';
import actors from './actors/reducer';
import publicUserEntity from './publicUserEntity/reducer';
import reports from './reports/reducer';
import storagesSlice from './storages/storagesSlice';
import masqueradingSlice from './masquerading/masqueradingSlice';
import reports54Actors from './reports54Actors/reducer';
import groups from './groups/reducer';
import departmentsSlice from './departments/slices/departmentsSlice';
import artifactsSlice from './departments/slices/artifactsSlice';
import permissionsSlice from './permissions/permissionsSlice';
import artifactsStorageSlice from './departments/slices/artifactsStorageSlice';
import resetSlice from './reset/resetSlice';
import defaultDepartmentSlice from './departments/slices/defaultDepartmentSlice';
import employeeRequest from './employeeRequest/employeeRequestSlice';
import pageAccessSlice from './pages/pageAccessSlice';
import metaeventStorageSlice from './metaeventStorage/metaeventStorageSlice';
import getAuthSessionModalSlicer from './auth/slicers/getAuthSessionModalSlicer';
import commonStoragesSlice from './commonStorages/commonStoragesSlice';

const createRootReducer = (history) => combineReducers({
  config,
  profile: profileInfo,
  globalUpdate,
  generalStorages: combineReducers({
    metaeventStorage: metaeventStorageSlice,
  }),
  Admin: combineReducers({
    getAuthSessionModalSlicer,
  }),
  storages: storagesSlice,
  commonStorages: commonStoragesSlice,
  actors,
  groups,
  pageAccess: pageAccessSlice,
  Masquerading: masqueradingSlice,
  publicUserEntity,
  monthlyReport,
  reports,
  userVacations,
  reports54Actors,
  vacations,
  Departments: combineReducers({
    artifacts: artifactsSlice,
    artifactsStorage: artifactsStorageSlice,
    listOfDepartments: departmentsSlice,
    defaultDepartment: defaultDepartmentSlice,
  }),
  Permissions: combineReducers({
    PSP: permissionsSlice,
  }),
  reset: resetSlice,
  public_interfaces: publicUI,
  router: connectRouter(history),
  employeeRequest,
});

export default createRootReducer;
