import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Input } from 'antd';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons';

import { TRIGGER_SECTIONS } from '../../metaevents/triggers';
import './EventTriggersList.css';

const { Search } = Input;

export default function EventTriggersList({
  selectedTrigger = null,
  onTriggerSelect = () => {},
}) {
  const [collapsedSections, setCollapsedSections] = useState({});
  const [searchValue, setSearchValue] = useState('');

  const filteredSections = useMemo(() => {
    if (!searchValue) return TRIGGER_SECTIONS;

    const searchLower = searchValue.toLowerCase();
    const filtered = {};

    Object.entries(TRIGGER_SECTIONS).forEach(([key, section]) => {
      const filteredTriggers = section.triggers.filter(
        (trigger) => trigger.title.toLowerCase().includes(searchLower)
          || trigger.description.toLowerCase().includes(searchLower),
      );

      if (filteredTriggers.length > 0) {
        filtered[key] = {
          ...section,
          triggers: filteredTriggers,
        };
      }
    });

    return filtered;
  }, [searchValue]);

  const onToggleSection = (sectionKey) => {
    setCollapsedSections((prev) => ({
      ...prev,
      [sectionKey]: !prev[sectionKey],
    }));
  };

  return (
    <Flex vertical className="events-types glass-effect h-[calc(100vh-180px)] bg-white border border-[rgba(226,232,240,0.8)]">
      <Flex
        vertical
        className="section-header px-6 py-4 bg-white border-b border-[rgba(226,232,240,0.8)]"
        style={{ borderBottom: '1px solid rgba(226,232,240,0.8)' }}
      >
        <Flex align="center" className="header-title mb-4">
          <span className="text-2xl font-semibold">Триггеры</span>
        </Flex>
        <Search
          placeholder="Поиск триггеров"
          allowClear
          style={{ height: '30px' }}
          className="mt-1"
          onChange={(e) => setSearchValue(e.target.value)}
          prefix={<SearchOutlined className="text-gray-400" />}
        />
      </Flex>
      <Flex vertical className="pt-2.5 events-list flex-1 overflow-y-auto bg-white divide-y divide-[rgba(226,232,240,0.8)]">
        {Object.entries(filteredSections).map(([key, section]) => (
          <Flex vertical key={key} className="trigger-section -mt-2.5">
            <Flex justify="space-between" align="center" className="trigger-section-header px-6 pb-1.5 bg-gray-50">
              <span className="text-xs font-medium text-gray-400 uppercase tracking-wider">{section.title}</span>
              <Flex
                align="center"
                className={`collapse-button text-gray-400 text-xs p-0.5 cursor-pointer transition-all hover:text-gray-500 ${collapsedSections[key] ? 'collapsed' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onToggleSection(key);
                }}
                role="button"
                tabIndex={0}
                aria-label={`Свернуть/развернуть секцию ${section.title}`}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    onToggleSection(key);
                  }
                }}
              >
                <CaretDownOutlined className="transition-transform" />
              </Flex>
            </Flex>
            <Flex vertical className={`trigger-section-content ${collapsedSections[key] ? 'collapsed' : ''}`}>
              {section.triggers.map((trigger) => (
                <Flex
                  key={trigger.id}
                  className={`event-card px-6 py-4 bg-white cursor-pointer transition-all hover:translate-y-[-2px] hover:shadow-lg ${
                    selectedTrigger?.id === trigger.id ? 'selected-card' : ''
                  }`}
                  onClick={() => onTriggerSelect(trigger)}
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      onTriggerSelect(trigger);
                    }
                  }}
                >
                  <Flex
                    align="center"
                    justify="center"
                    className="event-card-icon w-[42px] h-[42px] rounded-lg mr-4 text-xl transition-all relative"
                    style={{
                      backgroundColor: `${trigger.color}15`,
                      color: trigger.color,
                    }}
                  >
                    {trigger.icon}
                  </Flex>
                  <Flex vertical className="event-card-content flex-1">
                    <h3 className="m-0 mb-1 text-sm font-semibold text-gray-700 tracking-tight">{trigger.title}</h3>
                    <p className="m-0 text-sm text-gray-500 leading-normal">{trigger.description}</p>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}

EventTriggersList.propTypes = {
  selectedTrigger: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    color: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  onTriggerSelect: PropTypes.func,
};

EventTriggersList.defaultProps = {
  selectedTrigger: null,
  onTriggerSelect: () => {},
};
