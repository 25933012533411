import React from 'react';

import {
  Avatar, Card, Col, Form, Row, Space, Spin, Typography, Button,
  Flex,
} from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiAccountRemove } from '@mdi/js';

import { getProfileInfo } from '../../../redux/profile/selectors';
import useTriggersTermination from '../../../events/useTriggersTermination';

import PageWrapper from '../../../components/PageWrapper';
import ProfileForm from './forms/ProfileForm';
import { capitalizeFirstChar } from '../../../utils';
import { getRequestsStorageUUID } from '../../../redux/storages/selectors';

const { Title, Text, Paragraph } = Typography;
function ProfilePage() {
  const { t } = useTranslation();

  const [profileForm] = Form.useForm();

  const profileInfo = useSelector(getProfileInfo);
  const userRootUUID = useSelector(getRequestsStorageUUID);

  const {
    run: runTerminationRequest,
    EventComponent: EventComponentTerminationRequest,
  } = useTriggersTermination();

  const getData = (key, defaultValue = '') => _.get(profileInfo, key, defaultValue);

  const profileData = {
    uuid: getData('uuid'),
    first_name: getData('uinfo.first_name'),
    last_name: getData('uinfo.last_name'),
    email: getData('uinfo.email'),
    created: dayjs(getData('created'), 'YYYY-MM-DD HH:mm:ss')
      .locale('ru')
      .format('LLL'),
    full_name: `${getData('uinfo.first_name')} ${getData(
      'uinfo.last_name',
    )}`,
  };

  return (
    <PageWrapper
      title="Профиль"
      fixed
    >
      <Row gutter={[24, 16]}>
        <Col span={24}>
          <Spin spinning={false}>
            <Card style={{ marginBottom: 16 }}>
              <Space size="large">
                <Avatar shape="square" size="large" />
                <div>
                  <Title
                    level={5}
                    style={{
										  margin: 0,
                    }}
                  >
                    {capitalizeFirstChar(
										  profileData.first_name,
                    )}
                    {' '}
                    {capitalizeFirstChar(
										  profileData.last_name,
                    )}
                  </Title>
                  <div>
                    <Text type="secondary">
                      {profileData.uuid}
                    </Text>
                  </div>
                  <div>
                    <Text type="secondary">
                      {profileData.email}
                    </Text>
                  </div>
                </div>
              </Space>
            </Card>

            <Card
              title="Пользовательская информация"
            >
              <ProfileForm form={profileForm} />
            </Card>

            <Card
              className="mt-4"
              title={(
                <span className="text-lg font-medium text-gray-800">
                  Дополнительные возможности
                </span>
              )}
            >
              <div className="py-4 -mt-3 w-full">
                <Card
                  className="h-full  w-full transition-all  border-red-200"
                  bodyStyle={{ height: '100%' }}
                >
                  <Flex vertical align="center" gap="middle">
                    <div className="flex items-center justify-center w-12 h-12 rounded-full bg-red-50">
                      <Icon
                        path={mdiAccountRemove}
                        size={1.2}
                        className="text-red-500"
                      />
                    </div>
                    <Title level={5} className="text-center m-0">
                      Увольнение
                    </Title>
                    <Paragraph className="text-center text-gray-500 mb-4">
                      Отправьте заявку на увольнение с описанием причины
                    </Paragraph>
                    <Button
                      danger
                      className=""
                      onClick={() => runTerminationRequest('create-termination-request', {
                        user: profileInfo,
                      })}
                    >
                      Создать заявку на увольнение
                    </Button>
                  </Flex>
                </Card>
              </div>
            </Card>
          </Spin>
        </Col>
      </Row>
      <EventComponentTerminationRequest />
    </PageWrapper>
  );
}

export default ProfilePage;
