import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { omit } from 'lodash';

import { Modal } from 'antd';
import useAPI from '../../../api/useAPI';

import StartWorkCard from './StartWorkCard';

import { getShowLunchBreak } from '../../../redux/storages/selectors';
import { getProfileInfo } from '../../../redux/profile/selectors';
import { getFetchingMonthlyReport, getMonthlyReport } from '../../../redux/monthlyReport/selectors';

import MonthlyReportConstants from '../../../redux/monthlyReport/constants';
import { capitalizeFirstChar } from '../../../utils';
import antNotification from '../../../utils/antNotification';

import './startWorkRoot.scss';
import { dateFormat2 } from '../../../54origins/dateFormats54origins';
import useCheckDailyReport from '../../../hooks/useCheckDailyReport';
import useTriggersDailyReports from '../../../events/useTriggersDailyReports';

export default function StartWorkRoot() {
  const { updateDayInMonthlyReport } = useAPI();
  const { hasWorkRecord } = useCheckDailyReport();

  const myProfileData = useSelector(getProfileInfo);
  const isFetching = useSelector(getFetchingMonthlyReport);
  const showLunchBreak = useSelector(getShowLunchBreak);
  const monthlyReport = useSelector(getMonthlyReport);

  const [isModalOpen, setModalOpen] = useState(false);
  const [day, setDay] = useState(null);

  const actorUuid = myProfileData?.uuid;

  const {
    run: runCreateDailyReport,
    EventComponent: EventComponentCreateDailyReport,
  } = useTriggersDailyReports();

  const handleCancel = () => {
    localStorage.setItem('dateOfShowingStartWorkModal', dateFormat2());
    setModalOpen(false);
  };

  const updateDayCallback = async (data) => {
    try {
      const userDayOffs = [...(monthlyReport?.params?.days || [])];

      const newDay = {
        ...data.timeState,
        comment: data?.dailyPlanningText ?? '',
        actorUuid,
        type: data?.kindOfDay,
        created: dayjs().toString(),
        rangeDates: [dayjs().format('YYYY-MM-DD')],
        wasChoiceOfDay: true,
        ...(data?.extraOptions ?? {}),
      };

      const oldDay = userDayOffs.find(({ rangeDates }) => rangeDates?.[0] === dateFormat2());

      if (oldDay) {
        newDay.logs = [{
          ...omit(oldDay, 'logs'),
        }, ...(oldDay?.logs ?? [])];
      }

      const newDays = [...userDayOffs].filter(({ rangeDates }) => rangeDates[0] !== dateFormat2());

      await updateDayInMonthlyReport(
        monthlyReport?.uuid,
        [...newDays, newDay],
        [
          MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_REQUEST,
          MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_SUCCESS,
          MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_FAILURE,
        ],
      );

      // initFunc();
      antNotification.success('День успешно записан!');
    } catch (e) {
      antNotification.error('Ошибка!');
    }
    localStorage.setItem('dateOfShowingStartWorkModal', dateFormat2());
    setModalOpen(false);
  };

  const getPrevDayCallback = (currentDay = dayjs()) => {
    const sorteredDays = monthlyReport?.params?.days ?? [];

    if (!sorteredDays?.length) return null;

    return sorteredDays
      ?.sort((a, b) => dayjs(a?.rangeDates?.[0]).unix() - dayjs(b?.rangeDates?.[0]).unix())
      ?.reduce((acc, item) => {
        if (item?.hoursWorked && dayjs(item?.rangeDates?.[0]).isBefore(dayjs(currentDay))) {
          return item;
        }
        return acc;
      }, null) ?? null;
  };

  useEffect(() => {
    if (!isFetching && monthlyReport?.uuid) {
      const days = monthlyReport?.params?.days ?? [];
      const currentDay = days?.find((el) => el?.rangeDates?.[0] === dateFormat2()) ?? null;

      const dateOfShowingStartWorkModal = localStorage.getItem('dateOfShowingStartWorkModal') || '';

      setDay(currentDay);

      if (!currentDay?.wasChoiceOfDay && dateOfShowingStartWorkModal !== dateFormat2()) {
        // setModalOpen(true);

        runCreateDailyReport('create-daily-report', {
          constants: [
            MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_REQUEST,
            MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_SUCCESS,
            MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_FAILURE,
          ],
          data: currentDay,
          days: monthlyReport?.params?.days || [],
          date: dayjs().format('YYYY-MM-DD'),
          monthlyReportUUID: monthlyReport?.uuid,
          getPrevDayCallback,
          isStartWorkRecord: false,
          titleSaveBtn: 'Сохранить',
        });
      } else {
        setModalOpen(false);
      }
    }
  }, [JSON.stringify(monthlyReport)]);

  return (
    <>
      {!isFetching ? (
        <div
          aria-hidden
          onClick={() => {
            setModalOpen(true);
            runCreateDailyReport('create-daily-report', {
              constants: [
                MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_REQUEST,
                MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_SUCCESS,
                MonthlyReportConstants.UPDATE_MONTHLY_DAY_REPORT_FAILURE,
              ],
              data: day,
              days: monthlyReport?.params?.days || [],
              date: dayjs().format('YYYY-MM-DD'),
              monthlyReportUUID: monthlyReport?.uuid,
              getPrevDayCallback,
              isStartWorkRecord: false,
              titleSaveBtn: 'Сохранить',
            });
          }}
          className={`button-open-start-work-record ${hasWorkRecord ? 'color-update-record' : 'color-create-record'}`}
        >
          {hasWorkRecord ? 'Изменить рабочий день' : 'Начать рабочий день'}
        </div>
      ) : null}
      {/* <Modal
        title={capitalizeFirstChar(dayjs().format('dddd, DD MMMM YYYY'))}
        open={isModalOpen}
        footer={null}
        centered
        onCancel={handleCancel}
        width={950}
        destroyOnClose
      >
        <div className="w-100">
          <StartWorkCard
            defaultDisabled={hasWorkRecord}
            showDeleteButton={false}
            showLunchBreak={showLunchBreak}
            defaultData={day}
            currentDay={dayjs().format('YYYY-MM-DD')}
            titleSaveBtn="Сохранить"
            updateDayCallback={updateDayCallback}
            getPrevDayCallback={getPrevDayCallback}
          />
        </div>
      </Modal> */}
      <EventComponentCreateDailyReport />
    </>
  );
}
