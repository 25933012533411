import React, { useEffect, useMemo, useState } from 'react';
import {
  Alert, Button, Flex, Form, Input, Modal, Tag, Card, Progress,
} from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import {
  mdiAccountSchool, mdiCheck, mdiOfficeBuilding, mdiAccountGroup,
} from '@mdi/js';
import { head } from 'lodash';
import { getProfileInfo, isRootOrAdmin } from '../redux/profile/selectors';
import useMetaeventsAPI from '../api/useMetaeventsAPI';
import { getDeliveryPartition } from '../redux/config/selectors';
import useDepartmentsAPI from '../api/useDepartmentsAPI';
import useOfficesAPI from '../api/useOfficesAPI';
import useOnboardingAPI from '../api/useOnboardingAPI';
import paramsURL from '../utils/ParamsURL';
import antNotification from '../utils/antNotification';
import useAPI from '../api/useAPI';
import MetaeventLink from '../components/events/MetaeventLink';

const { TextArea } = Input;

export default function useTriggersOnboarding() {
  const { getListOfMetaevents, readListOfMetaevents } = useMetaeventsAPI();
  const { getListOfDepartments } = useDepartmentsAPI();
  const { getListOfOffices, updateOffice } = useOfficesAPI();

  const { createOnboarding, getListOfOnboarding, updateOnboarding } = useOnboardingAPI();

  const partitionUUID = useSelector(getDeliveryPartition);
  const profileActor = useSelector(getProfileInfo);
  const isAdOrRoot = useSelector(isRootOrAdmin);

  const [trigger, setTrigger] = useState('');
  const [options, setOptions] = useState({});

  function CreateOnboardingComponent() {
    const [isModalOpen, setModalOpen] = useState(true);
    const [form] = Form.useForm();
    const [offices, setOffices] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [metaevents, setMetaevents] = useState([]);
    const [selectedMetaevent, setSelectedMetaevent] = useState(null);
    const [selectedOffice, setSelectedOffice] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    const {
      saveCallback, user, metaevent, onComplete,
    } = options;

    useEffect(() => {
      if (!isModalOpen) {
        setTrigger('');
        setOptions({});
      }
    }, [isModalOpen]);

    useEffect(() => {
      loadData();
    }, []);

    useEffect(() => {
      if (selectedMetaevent) {
        form.resetFields();

        const newOffice = selectedMetaevent.params?.assigned_office || null;
        const newDepartment = selectedMetaevent.params?.assigned_department || null;

        setSelectedOffice(newOffice);
        setSelectedDepartment(newDepartment);

        form.setFieldsValue({
          instruction_title: selectedMetaevent.params?.instruction_title || '',
          instruction: selectedMetaevent.params?.instruction || '',
          office: newOffice,
          department: newDepartment,
        });
      }
    }, [selectedMetaevent]);

    const loadData = async () => {
      const [officesRes, departmentsRes, metaeventsRes] = await Promise.all([
        getListOfOffices(partitionUUID),
        getListOfDepartments(partitionUUID),
        getListOfMetaevents(partitionUUID, {
          params: {
            trigger: 'start-onboarding',
          },
        }),
      ]);

      setOffices(officesRes?.data || []);
      setDepartments(departmentsRes?.data || []);
      setMetaevents(metaeventsRes?.data || []);
    };

    const handleSubmit = async (values) => {
      if (user?.uuid) {
        const config = {
          params: {
            status: 'active',
          },
        };

        if (selectedMetaevent?.uuid) {
          config.params.metaevent = selectedMetaevent.uuid;

          if (values.instruction_title !== selectedMetaevent.params?.instruction_title) {
            config.params.instruction_title = values.instruction_title;
          }

          if (values.instruction !== selectedMetaevent.params?.instruction) {
            config.params.instruction = values.instruction;
          }

          if (values.office !== selectedMetaevent.params?.assigned_office) {
            config.params.assigned_office = values.office;
          }

          if (values.department !== selectedMetaevent.params?.assigned_department) {
            config.params.assigned_department = values.department;
          }
        } else {
          config.params = {
            instruction_title: values.instruction_title,
            instruction: values.instruction,
            assigned_office: values.office,
            assigned_department: values.department,
          };
        }

        await createOnboarding(user.uuid, config);
      }

      saveCallback?.(user?.uuid, {
        instruction_title: values.instruction_title,
        instruction: values.instruction,
        assigned_office: values.office,
        assigned_department: values.department,
      });

      onComplete?.();
      setModalOpen(false);
    };

    const toggleOffice = (officeUuid) => {
      const newValue = selectedOffice === officeUuid ? null : officeUuid;
      setSelectedOffice(newValue);
      form.setFieldsValue({ office: newValue });
    };

    const toggleDepartment = (departmentUuid) => {
      const newValue = selectedDepartment === departmentUuid ? null : departmentUuid;
      setSelectedDepartment(newValue);
      form.setFieldsValue({ department: newValue });
    };

    return (
      <Modal
        open={isModalOpen}
        title={(
          <Flex align="center" gap="small">
            <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-blue-50">
              <Icon
                path={mdiAccountSchool}
                size={0.8}
                className="text-blue-500"
              />
            </div>
            <span className="text-xl font-medium">
              Планирование онбординга для
              {' '}
              <span className="text-blue-500">
                {user?.uinfo?.first_name}
                {' '}
                {user?.uinfo?.last_name}
              </span>
            </span>
          </Flex>
        )}
        width={1200}
        centered
        destroyOnClose
        footer={null}
        onCancel={() => setModalOpen(false)}
        className="onboarding-modal"
      >
        <div className="flex gap-6 px-4 -mt-3">
          <div className="flex-1">
            <div className="mb-0">
              <h3 className="text-lg font-medium text-gray-900">Форма онбординга</h3>
              <p className="text-sm text-gray-500 -mt-3">Настройте параметры онбординга для подрядчика</p>
            </div>

            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
            >
              <div className="bg-white border border-gray-200 rounded-lg">
                <Form.Item
                  name="instruction_title"
                  className="mb-2"
                  label={<span className="font-medium text-gray-700">Заголовок инструкции</span>}
                  rules={[{ required: true, message: 'Пожалуйста, введите заголовок инструкции' }]}
                >
                  <Input
                    placeholder="Введите заголовок инструкции..."
                    // className="mb-2"
                  />
                </Form.Item>

                <Form.Item
                  name="instruction"
                  label={<span className="font-medium text-gray-700">Инструкция</span>}
                  rules={[{ required: true, message: 'Пожалуйста, введите инструкцию' }]}
                >
                  <TextArea
                    rows={6}
                    placeholder="Опишите основные шаги онбординга..."
                    className="resize-none"
                  />
                </Form.Item>
              </div>

              <div className="bg-white border border-gray-200 rounded-lg">
                <Form.Item
                  name="office"
                  label={<span className="font-medium text-gray-700">Назначить в офис</span>}
                >
                  <div className="groups-selector">
                    {offices.map((office) => (
                      <Tag
                        key={office.uuid}
                        className={selectedOffice === office.uuid ? 'selected' : ''}
                        onClick={() => toggleOffice(office.uuid)}
                      >
                        {office.params?.title}
                      </Tag>
                    ))}
                  </div>
                </Form.Item>
              </div>

              <div className="bg-white border border-gray-200 rounded-lg">
                <Form.Item
                  name="department"
                  label={<span className="font-medium text-gray-700">Назначить в отдел</span>}
                >
                  <div className="groups-selector">
                    {departments.map((department) => (
                      <Tag
                        key={department.uuid}
                        className={selectedDepartment === department.uuid ? 'selected' : ''}
                        onClick={() => toggleDepartment(department.uuid)}
                      >
                        {department.params?.title}
                      </Tag>
                    ))}
                  </div>
                </Form.Item>
              </div>
            </Form>
          </div>

          <div className="w-[400px] h-fit">
            <div className="">
              <h3 className="text-lg font-medium text-gray-900">Метасобытия онбординга</h3>
              <p className="text-sm text-gray-500 -mt-3">Выберите метасобытие, которое станет основой для онбординга</p>
            </div>

            <div className="bg-white border border-gray-200 rounded-lg">
              <div
                className="space-y-3 overflow-y-auto custom-scrollbar pr-2"
                style={{
                  maxHeight: 'calc(4.5 * (24px + 1rem + 2rem + 1.5rem))', // высота карточки * 4.5
                  // 24px - базовая высота текста
                  // 1rem - внутренние отступы
                  // 2rem - высота контента
                  // 1.5rem - отступы между карточками
                }}
              >
                {metaevents.map((item) => (
                  <div
                    key={item.uuid}
                    className={`p-4 rounded-lg cursor-pointer border-2 transition-all ${
                      selectedMetaevent?.uuid === item.uuid
                        ? 'border-blue-500 bg-blue-50 shadow-sm'
                        : 'border-gray-100 bg-gray-100 hover:border-blue-200 hover:bg-white'
                    }`}
                    onClick={() => setSelectedMetaevent(item)}
                  >
                    <div className="flex items-start justify-between">
                      <div>
                        <div className="font-medium text-gray-800 mb-1">{item.params?.title}</div>
                      </div>
                      {selectedMetaevent?.uuid === item.uuid && (
                        <div className="flex items-center justify-center w-6 h-6 rounded-full bg-blue-500">
                          <Icon
                            path={mdiCheck}
                            size={0.7}
                            className="text-white"
                          />
                        </div>
                      )}
                    </div>

                    <div className="mt-0 border-t border-gray-100">
                      <div className="flex items-center gap-4 text-sm text-gray-500">
                        {/* {item.params?.assigned_office && ( */}
                        <div className="flex items-center gap-1">
                          <Icon path={mdiOfficeBuilding} size={0.6} />
                          <span>
                            Офис:
                            {' '}
                            {offices.find((o) => o.uuid === item.params.assigned_office)?.params?.title || 'нет'}
                          </span>
                        </div>
                        {/* // )} */}
                        {/* {item.params?.assigned_department && ( */}
                        <div className="flex items-center gap-1">
                          <Icon path={mdiAccountGroup} size={0.6} />
                          <span>
                            Отдел:
                            {' '}
                            {departments.find((d) => d.uuid === item.params.assigned_department)?.params?.title || 'нет'}
                          </span>
                        </div>
                        {/* )} */}
                      </div>
                      <div className="mt-2 text-xs text-gray-400">
                        Создано:
                        {' '}
                        {dayjs(item.created).format('DD.MM.YYYY')}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="px-6">
          <div className="flex justify-end gap-3 border-t">
            <Button onClick={() => setModalOpen(false)}>
              Отмена
            </Button>
            <Button type="primary" onClick={() => form.submit()}>
              Запустить онбординг
            </Button>
          </div>
        </div>
      </Modal>
    );
  }

  function OnboardingInfoComponent() {
    const [onboardingData, setOnboardingData] = useState(null);
    const [metaeventData, setMetaeventData] = useState(null);
    const [offices, setOffices] = useState([]);
    const [departments, setDepartments] = useState([]);

    const { user } = options;

    const loadData = async () => {
      try {
        if (user?.uuid && partitionUUID) {
        // Загружаем все данные параллельно
          const [onboardingRes, officesRes, departmentsRes] = await Promise.all([
            getListOfOnboarding(user?.uuid, {
              params: {
                status: 'active',
              },
            }),
            getListOfOffices(partitionUUID),
            getListOfDepartments(partitionUUID),
          ]);

          setOffices(officesRes?.data || []);
          setDepartments(departmentsRes?.data || []);

          const activeOnboarding = onboardingRes?.data?.[0];
          if (activeOnboarding) {
            setOnboardingData(activeOnboarding);

            // Если есть метасобытие, загружаем его
            if (activeOnboarding.params?.metaevent) {
              const metaeventRes = await readListOfMetaevents(activeOnboarding?.params?.metaevent);
              setMetaeventData(head(metaeventRes));
            }
          }
        }
      } catch (error) {
        console.error('Error loading onboarding data:', error);
      }
    };

    useEffect(() => {
      loadData();
    }, [user?.uuid, partitionUUID]);

    if (!onboardingData) return null;

    return (
      <Card
        title={(
          <Flex align="center" gap="small">
            <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-blue-50">
              <Icon
                path={mdiAccountSchool}
                size={0.8}
                className="text-blue-500"
              />
            </div>
            <span className="text-lg">Онбординг</span>
          </Flex>
        )}
        className="w-full"
      >
        <Flex vertical gap="middle">
          <Alert
            type="info"
            showIcon
            message={(
              <div className="font-medium">
                {onboardingData.params?.instruction_title || metaeventData?.params?.instruction_title}
                {isAdOrRoot && <MetaeventLink metaevent={metaeventData} />}
              </div>
            )}
            description={(
              <div className="text-sm text-gray-600 whitespace-pre-wrap">
                {onboardingData.params?.instruction || metaeventData?.params?.instruction}
              </div>
            )}
          />

          <Flex gap="large">
            <div>
              <div className="text-sm text-gray-500 mb-1">Будущий офис</div>
              <div className="font-medium">
                {offices.find((o) => o.uuid === (
                  onboardingData.params?.assigned_office
                  || metaeventData?.params?.assigned_office
                ))?.params?.title || 'Не назначен'}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-500 mb-1">Будущий отдел</div>
              <div className="font-medium">
                {departments.find((d) => d.uuid === (
                  onboardingData.params?.assigned_department
                  || metaeventData?.params?.assigned_department
                ))?.params?.title || 'Не назначен'}
              </div>
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  function CompleteOnboardingComponent() {
    const [isModalOpen, setModalOpen] = useState(true);
    const {
      user, onboarding, offices, departments, onComplete,
    } = options;

    const {
      updateUserEntity,
      createEntityPermissions,
    } = useAPI();

    useEffect(() => {
      if (!isModalOpen) {
        setTrigger('');
        setOptions({});
      }
    }, [isModalOpen]);

    const handleSubmit = async () => {
      try {
        await updateOnboarding(onboarding?.uuid, {
          params: {
            status: 'completed',
          },
        });
        if (onboarding?.params?.assigned_office) {
          const oldContractors = offices?.find((o) => o?.uuid === onboarding?.params?.assigned_office) || [];
          await updateOffice(onboarding?.params?.assigned_office, {
            params: {
              contractors: [...oldContractors, user?.actor],
            },
          });
        }
        if (onboarding.params?.assigned_department) {
          await createEntityPermissions({
            data: {
              entity_uuid: onboarding.params?.assigned_department,
              data: [{
                actor: user?.actor,
                create: true,
                list: true,
                read: true,
                update: false,
                delete: false,
                set: false,
              }],
            },
          });
          await updateUserEntity(user?.uuid, {
            parent: onboarding?.params?.assigned_department,
          });
        }

        onComplete?.();
        antNotification.success('Онбординг успешно завершен');
        setModalOpen(false);
      } catch (error) {
        antNotification.error('Не удалось завершить онбординг');
      }
    };

    return (
      <Modal
        open={isModalOpen}
        title={(
          <Flex align="center" gap="small">
            <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-blue-50">
              <Icon
                path={mdiAccountSchool}
                size={0.8}
                className="text-blue-500"
              />
            </div>
            <span className="text-lg">
              Завершение онбординга
            </span>
          </Flex>
        )}
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalOpen(false)}>
            Отмена
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
          >
            Завершить
          </Button>,
        ]}
      >
        <div className="mt-2">
          <p className="text-gray-600">
            После завершения онбординга пользователь будет назначен в:
          </p>
          <div className="mt-3 space-y-2">
            <Flex gap="middle">
              <Icon path={mdiOfficeBuilding} size={0.8} className="text-gray-400" />
              <div>
                <div className="text-sm text-gray-500">Офис</div>
                <div className="font-medium">
                  {offices?.find((o) => o.uuid === onboarding.params?.assigned_office)?.params?.title || 'Не назначен'}
                </div>
              </div>
            </Flex>
            <Flex gap="middle">
              <Icon path={mdiAccountGroup} size={0.8} className="text-gray-400" />
              <div>
                <div className="text-sm text-gray-500">Отдел</div>
                <div className="font-medium">
                  {departments?.find((d) => d.uuid === onboarding.params?.assigned_department)?.params?.title || 'Не назначен'}
                </div>
              </div>
            </Flex>
          </div>
        </div>
      </Modal>
    );
  }

  async function run(type, newOptions = {}) {
    const metaevents = await getListOfMetaevents(partitionUUID, {
      params: {
        trigger: type,
        groups: profileActor?.uinfo?.groups,
      },
    });

    const userGroups = new Set(profileActor?.uinfo?.groups || []);
    const selectedMetaevent = metaevents?.data?.reduce((best, current) => {
      const metaeventGroups = new Set(current.params?.groups || []);
      let weight = 0;

      if (metaeventGroups.size > 0 && userGroups.size > 0) {
        const matchingGroups = [...metaeventGroups].filter((group) => userGroups.has(group));
        weight = Math.round((matchingGroups.length / metaeventGroups.size) * 10);
      }

      if (!best || weight > best.weight) {
        return { ...current, weight };
      }

      if (weight === best.weight && dayjs(current.created).isAfter(dayjs(best.created))) {
        return { ...current, weight };
      }

      return best;
    }, null);

    setOptions({
      ...newOptions,
      metaevent: selectedMetaevent?.weight > 0 ? selectedMetaevent : null,
    });
    setTrigger(type);
  }

  const EventComponent = useMemo(() => {
    switch (trigger) {
      case 'start-onboarding':
        return CreateOnboardingComponent;
      case 'show-onboarding':
        return OnboardingInfoComponent;
      case 'complete-onboarding':
        return CompleteOnboardingComponent;
      default:
        return () => null;
    }
  }, [trigger]);

  return {
    run,
    EventComponent,
  };
}
