import React, { useEffect, useState } from 'react';

import {
  Button,
  Card, Flex,
  Spin,
  Tooltip,
} from 'antd';

import { get, isNil } from 'lodash';
import dayjs from 'dayjs';
import cn from 'classnames';
import {
  array, bool, func, object, string,
} from 'prop-types';

import Icon from '@mdi/react';
import {
  mdiArrowAll, mdiArrowLeft, mdiChevronDown, mdiChevronRight, mdiChevronUp, mdiMagnify,
} from '@mdi/js';

import { useDispatch, useSelector } from 'react-redux';
import useURLParams from '../../hooks/useURLParams';
import AddDepartmentButton from './operations/AddDepartmentButton';

import BaseTableWithPagination from '../../w54_components/BaseTableWithPagination/BaseTableWithPagination';
import useAPI from '../../api/useAPI';
import { getDeliveryPartition } from '../../redux/config/selectors';
import useDepartments from '../../redux/departments/hooks/useDepartments';
import { getResetValueForCurrentKey } from '../../redux/reset/resetSelector';
import { DISABLE_RESET_PROPERTY } from '../../redux/reset/resetSlice';
import CountDepartmentKPIButton from './operations/CountDepartmentKPIButton';

export default function DepartmentsList({
  hideAddButton,
  hideKPIButton,
  hideExpand,
  showMoreButton,
  hiddenColumns = [],
  defaultConfig = {},
  name = 'department_list',
  currentDepartment,
}) {
  const dispatch = useDispatch();

  const needReload = useSelector(getResetValueForCurrentKey(name));

  const {
    isFetching,
    departments,
    paginationOptions,
    onReloadDepartments,
  } = useDepartments({
    defaultConfig,
    isReduxMode: false,
  });

  const {
    getURLParams,
    addAndRemoveSearchParams,
    routeNavigateAndClearParams,
  } = useURLParams();

  const { department } = getURLParams();

  const [hovered, setHovered] = useState(false);
  const [showFullCard, setShowFullCard] = useState(false);

  const gerLabel = (user) => `${get(user, ['first_name'], '')} ${get(user, ['last_name'], '')}`;

  const columns = [
    {
      key: 'title',
      title: 'Название',
      hidden: hiddenColumns?.includes('title'),
      dataIndex: ['params', 'title'],
      width: '40%',
      render: (cell) => (
        <span
          style={{
            wordBreak: 'break-word',
          }}
        >
          {cell}
        </span>
      ),
    },
    {
      key: 'kpi',
      title: 'KPI',
      hidden: hiddenColumns?.includes('kpi'),
      dataIndex: ['params', 'project_kpi'],
      width: '10%',
      render: (cell) => cell || '0',
    },
    {
      key: 'leader',
      title: 'Руководитель',
      hidden: hiddenColumns?.includes('leader'),
      dataIndex: ['params', 'leader'],
      width: '25%',
      render: (cell) => {
        const label = gerLabel(cell);

        return (
          <Flex className="w-full">
            <Tooltip
              title={label}
            >
              {label?.length < 25 ? label : `${label?.slice(0, 25)}...`}
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      key: 'deputy',
      title: 'Заместитель',
      hidden: hiddenColumns?.includes('deputy'),
      dataIndex: ['params', 'deputy'],
      width: '25%',
      render: (cell) => {
        const label = gerLabel(cell);

        return (
          <Flex className="w-full">
            <Tooltip
              title={label}
            >
              {label?.length < 25 ? label : `${label?.slice(0, 25)}...`}
            </Tooltip>
          </Flex>
        );
      },
    },
    {
      key: 'created',
      title: 'Дата',
      hidden: hiddenColumns?.includes('created'),
      dataIndex: ['created'],
      width: '10%',
      render: (cell) => dayjs(cell).format('YYYY-MM-DD'),
    },
    // {
    //   key: 'actions',
    //   title: '',
    //   hidden: !showMoreButton,
    //   render: (cell, row) => (
    //     <Flex
    //       style={{ border: '1px solid blue' }}
    //       className="px-1 cursor-pointer text-blue-500 hover:bg-blue-500 hover:text-white !border-blue-500 rounded-md"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         routeNavigateAndClearParams('/department', { v: 'overview', d: row?.uuid });
    //       }}
    //     >
    //       {/* <span>
    //         Открыть
    //       </span> */}
    //       <Icon path={mdiMagnify} size={1} />
    //     </Flex>
    //   ),
    // },
  ].filter((el) => !el?.hidden);

  const onRow = (record) => ({
    onClick: () => {
      const uuid = get(record, 'uuid', '');

      if (uuid !== department) {
        addAndRemoveSearchParams({ department: uuid }, ['department']);
      } else {
        addAndRemoveSearchParams({}, ['department']);
      }
    },
  });

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === department,
  });

  useEffect(() => {
    if (needReload) {
      onReloadDepartments();
      dispatch(DISABLE_RESET_PROPERTY(name));
    }
  }, [needReload]);

  return (
    <>
      <Card
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        className="w-full h-max p-0 pb-2 blue-base-card"
        title={(
          <Flex
            align="center"
          >
            <Flex
              className="text-xl"
            >
              Отделы
            </Flex>

            {!hideAddButton && (
            <AddDepartmentButton
              buttonClassName="ml-2 mt-1"
              onCreateDepartmentCallback={onReloadDepartments}
            />
            ) }
            {!hideKPIButton && (
            <CountDepartmentKPIButton
              departments={departments}
              buttonClassName="ml-2 mt-1"
              onCountKPICallback={onReloadDepartments}
            />
            )}
          </Flex>
      )}
      >
        <Flex
          className="mx-2"
          vertical
        >
          <Spin
            className="w-full h-100"
            spinning={isFetching}
          >
            <BaseTableWithPagination
              columns={columns}
              data={department && !hovered && !showFullCard && !hideExpand ? [currentDepartment] : departments}
              rowKey="uuid"
              size="small"
              className="transition-transform duration-300 ease-in-out"
              headerRowClassName="universal_header_table_row color-gray"
              onRow={onRow}
              rowClassName={rowClassName}
              hideListSizeOption={false}
              useCustomPagination
              pageSizeOptions={[10, 25, 50]}
              disablePagination={department && !hovered && !showFullCard && !hideExpand}
              {...paginationOptions}
            />
          </Spin>
        </Flex>
      </Card>
      {department && !hideExpand && (
      <Flex
        className="w-full py-1 z-20 cursor-pointer text-blue-500 bg-sky-100"
        justify="center"
        align="center"
        onClick={() => setShowFullCard((prev) => !prev)}
      >
        {showFullCard
          ? (
            <Flex
              align="center"
            >
              <Icon path={mdiChevronUp} size={1.2} />
              <span>
                Свернуть
              </span>
            </Flex>
          ) : (
            <Flex
              align="center"
            >
              <Icon path={mdiChevronDown} size={1.2} />
              <span>
                Развернуть
              </span>
            </Flex>
          )}
      </Flex>
      )}
    </>
  );
}

DepartmentsList.propTypes = {
  defaultConfig: object,
  currentDepartment: object,
  name: string,
  hideExpand: bool,
  hideAddButton: bool,
  hideKPIButton: bool,
  hiddenColumns: array,
};
