import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List, Spin, Typography, Space, Tag, Divider, Button,
} from 'antd';
import {
  UserOutlined,
  ClockCircleOutlined,
  FileTextOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import useEventsAPI from '../../../api/useEventsAPI';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import { TRIGGER_NAMES } from '../triggers';

const { Text, Paragraph } = Typography;

export default function MetaeventEvents({ metaeventUuid, maxHeight }) {
  const { getListOfEvents } = useEventsAPI();
  const partitionUUID = useSelector(getDeliveryPartition);
  const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedEvents, setExpandedEvents] = useState(new Set());

  const toggleInstruction = (eventId) => {
    const newExpanded = new Set(expandedEvents);
    if (newExpanded.has(eventId)) {
      newExpanded.delete(eventId);
    } else {
      newExpanded.add(eventId);
    }
    setExpandedEvents(newExpanded);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      if (metaeventUuid) {
        setIsLoading(true);
        try {
          const res = await getListOfEvents(partitionUUID, {
            params: {
              metaevent: metaeventUuid,
            },
          });
          setEvents(res?.data || []);
        } catch (error) {
          console.error('Error loading events:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchEvents();
  }, [metaeventUuid]);

  const renderEventItem = (event) => {
    const isExpanded = expandedEvents.has(event.uuid);
    const hasInstruction = event.params.metaeventParams?.instruction_title || event.params.metaeventParams?.instruction;

    return (
      <div className="event-item border rounded-lg p-4 mb-4 bg-white shadow-sm">
        <div className="flex items-center justify-between mb-3">
          <Tag color={TRIGGER_NAMES[event.params.type]?.color}>
            {TRIGGER_NAMES[event.params.type]?.name || event.params.type}
          </Tag>
          <Space>
            <ClockCircleOutlined className="text-gray-400" />
            <Text type="secondary">
              {new Date(event.created).toLocaleString('ru-RU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </Text>
          </Space>
        </div>

        <div className="flex items-center gap-2">
          <UserOutlined className="text-blue-500" />
          <Text>
            {event.uinfo
              ? `${event.uinfo.first_name} ${event.uinfo.last_name}`
              : event.actor}
          </Text>
        </div>

        {hasInstruction && (
          <>
            <Divider className="my-3" />
            <div className="instruction-section">
              <Button
                type="text"
                className="p-0 h-auto mb-2"
                onClick={() => toggleInstruction(event.uuid)}
                icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
              >
                <Text type="secondary">Инструкция на момент создания</Text>
              </Button>

              {isExpanded && (
                <div className="instruction-content mt-2">
                  {event.params.metaeventParams?.instruction_title && (
                    <div className="mb-2">
                      <Space>
                        <FileTextOutlined className="text-blue-500" />
                        <Text strong>{event.params.metaeventParams.instruction_title}</Text>
                      </Space>
                    </div>
                  )}

                  {event.params.metaeventParams?.instruction && (
                    <div className="bg-gray-50 p-3 rounded">
                      <Paragraph className="whitespace-pre-line mb-0">
                        {event.params.metaeventParams.instruction}
                      </Paragraph>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="metaevent-events w-full -ml-6">
      <Spin spinning={isLoading}>
        <List
          style={{
            maxHeight: maxHeight || 400,
            overflowY: 'auto',
          }}
          dataSource={events}
          renderItem={renderEventItem}
          locale={{ emptyText: 'Нет событий' }}
        />
      </Spin>
    </div>
  );
}

MetaeventEvents.propTypes = {
  metaeventUuid: PropTypes.string.isRequired,
  maxHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

MetaeventEvents.defaultProps = {
  maxHeight: 400,
};
