import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Flex, Tabs, Tag, Space, Typography, Spin, Divider, Popconfirm,
} from 'antd';
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';
import {
  ApartmentOutlined,
  DeleteOutlined,
  EditOutlined,
  HomeOutlined,
  TeamOutlined,
  BankOutlined,
} from '@ant-design/icons';
import { capitalize, get, isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import MetaeventEvents from '../../metaevents/events/MetaeventEvents';
import MetaeventLogs from '../../metaevents/logs/MetaeventLogs';
import CommonInfo from '../../commonComponents/entityCardComponents/CommonInfo';
import CopyURL from '../../commonComponents/CopyURL';
import EntityTitleForCard from '../../commonComponents/entityCardComponents/EntityTitleForCard';
import EntityDescriptionForCard from '../../commonComponents/entityCardComponents/EntityDescriptionForCard';
import useAPI from '../../../api/useAPI';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';
import useOfficesAPI from '../../../api/useOfficesAPI';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import axiosRequest from '../../../api/api';

const { Text } = Typography;

export default function MetaEventCard({
  metaEvent,
  onClose,
  isLoading = false,
  hideBackButton,
  showFullCard,
  hideCopyURLButton,
  isGlobalEdit,
  onDeleteMetaEvent,
  onEditMetaEvent,
}) {
  const [offices, setOffices] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [groupsMap, setGroupsMap] = useState({});
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);

  const { readListOfEntities } = useAPI();
  const { getListOfDepartments } = useDepartmentsAPI();
  const { getListOfOffices, updateOffice } = useOfficesAPI();

  const {
    title = '',
    instruction = '',
    instruction_title = '',
    offices: officeUUIDs = [],
    departments: departmentUUIDs = [],
    groups: groupUUIDs = [],
  } = metaEvent?.params || {};

  function getListOfAllGroups() {
    return dispatch(axiosRequest.post(
      'utility/actor/list',
      [
        'GET_LIST_OF_GROUPS_REQUEST',
        {
          type: 'GET_LIST_OF_GROUPS_SUCCESS',
          payload: (res) => {
            const groups = {};
            res?.data?.forEach((group) => {
              groups[group.uuid] = group.uinfo.group_name;
            });
            setGroupsMap(groups);
            return res;
          },
        },
        'GET_LIST_OF_GROUPS_FAILURE',
      ],
      {
        actor_type: 'group',
        not_banned: true,
      },
    ));
  }

  const loadData = async () => {
    const [officesRes, departmentsRes] = await Promise.all([
      getListOfOffices(partitionUUID),
      getListOfDepartments(partitionUUID),
      getListOfAllGroups(),
    ]);
    setOffices(officesRes?.data?.filter((el) => officeUUIDs?.includes(el?.uuid)) || []);
    setDepartments(departmentsRes?.data?.filter((el) => departmentUUIDs?.includes(el?.uuid)) || []);
  };

  useEffect(() => {
    if (partitionUUID) {
      loadData();
    }
  }, [JSON.stringify(officeUUIDs), JSON.stringify(departmentUUIDs), JSON.stringify(groupUUIDs)]);

  const items = [
    {
      key: 'events',
      label: (
        <span>
          <ApartmentOutlined />
          События
        </span>
      ),
      children: (
        <Flex className="-mt-6">
          <MetaeventEvents metaeventUuid={metaEvent.uuid} />
        </Flex>
      ),
    },
    {
      key: 'logs',
      label: (
        <span>
          <TeamOutlined />
          История
        </span>
      ),
      children: (
        <Flex className="-mt-6">
          <MetaeventLogs metaeventUuid={metaEvent.uuid} />
        </Flex>
      ),
    },
  ];

  const onUpdateMetaevent = () => {};

  return (
    <Card
      className="w-full h-max p-0 blue-base-card"
      title={(
        <Flex
          className="w-full"
          justify="space-between"
        >
          <Flex
            align="center"
          >
            <Flex
              align="center"
              className="cursor-pointer"
              onClick={onClose}
            >
              {!hideBackButton && <Icon path={mdiArrowLeft} size={0.9} />}
              <span className="ml-3 text-xl">
                Метасобытие
              </span>
            </Flex>
            <CommonInfo
              iconSize={1}
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
              className="ml-2 mt-1"
              creator={`${capitalize(get(metaEvent, 'uinfo.first_name', ''))} ${get(metaEvent, 'uinfo.last_name', '')}`}
              createdDate={get(metaEvent, 'created', '')}
            />
          </Flex>
          <Flex
            align="center"
            gap="small"
          >
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => onEditMetaEvent(metaEvent)}
              className="text-blue-500 hover:text-blue-400"
            >
              Редактировать
            </Button>
            <Popconfirm
              title="Удаление метасобытия"
              description="Вы уверены, что хотите удалить это метасобытие? Это действие нельзя будет отменить."
              okText="Да, удалить"
              cancelText="Отмена"
              placement="topRight"
              okButtonProps={{ danger: true }}
              onConfirm={async () => {
                await onDeleteMetaEvent(metaEvent);
              }}
            >
              <Button
                type="text"
                icon={<DeleteOutlined />}
                className="delete-btn !text-red-500 !hover:bg-red-100"
              >
                Удалить
              </Button>
            </Popconfirm>
          </Flex>
        </Flex>
      )}
    >
      <Spin
        className="w-full h-full"
        spinning={isLoading}
      >
        <Flex
          vertical
          className="py-2"
        >
          <Flex
            className="mx-12 max-w-full overflow-hidden"
            justify="start"
          >
            <EntityTitleForCard
              hideEditButton
              isGlobalEdit={isGlobalEdit}
              title={title}
              onEditCallback={onUpdateMetaevent}
            />
          </Flex>
          <Divider className="my-2" />
          <EntityDescriptionForCard
            hideEdit
            useEllipsis
            isGlobalEdit={isGlobalEdit}
            title="Заголовок инструкции"
            className="mx-12"
            description={instruction_title}
            onEditCallback={onUpdateMetaevent}
          />
          <Divider className="my-2" />
          <EntityDescriptionForCard
            hideEdit
            useEllipsis
            isGlobalEdit={isGlobalEdit}
            title="Инструкции"
            className="mx-12"
            description={instruction}
            onEditCallback={onUpdateMetaevent}
          />

          {(!isEmpty(offices) || !isEmpty(departments) || !isEmpty(groupUUIDs)) && (
            <>
              <Divider className="my-2" />
              <Flex vertical className="mx-12">
                {!isEmpty(offices) && (
                  <Flex vertical className="mb-4">
                    <Text type="secondary" className="mb-2">
                      <BankOutlined className="mr-2" />
                      Офисы:
                    </Text>
                    <Space size={[0, 4]} wrap>
                      {offices.map((office) => (
                        <Tag
                          key={office.uuid}
                          icon={<HomeOutlined />}
                          color="blue"
                        >
                          {office.params.title}
                        </Tag>
                      ))}
                    </Space>
                  </Flex>
                )}
                {!isEmpty(departments) && (
                  <Flex vertical className="mb-4">
                    <Text type="secondary" className="mb-2">
                      <TeamOutlined className="mr-2" />
                      Отделы:
                    </Text>
                    <Space size={[0, 4]} wrap>
                      {departments.map((department) => (
                        <Tag
                          key={department.uuid}
                          icon={<ApartmentOutlined />}
                          color="purple"
                        >
                          {department.params.title}
                        </Tag>
                      ))}
                    </Space>
                  </Flex>
                )}
                {!isEmpty(groupUUIDs) && (
                  <Flex vertical>
                    <Text type="secondary" className="mb-2">
                      <TeamOutlined className="mr-2" />
                      Группы:
                    </Text>
                    <Space size={[0, 4]} wrap>
                      {groupUUIDs.map((groupId) => (
                        <Tag
                          key={groupId}
                          icon={<TeamOutlined />}
                          color="green"
                        >
                          {groupsMap[groupId] || groupId}
                        </Tag>
                      ))}
                    </Space>
                  </Flex>
                )}
              </Flex>
            </>
          )}

          <Divider className="my-2" />

          <Tabs
            defaultActiveKey="events"
            items={items}
            className="px-6"
          />
        </Flex>
      </Spin>
    </Card>
  );
}

MetaEventCard.propTypes = {
  metaEvent: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    params: PropTypes.shape({
      title: PropTypes.string.isRequired,
      instruction: PropTypes.string,
      instruction_title: PropTypes.string,
      groups: PropTypes.arrayOf(PropTypes.string),
      offices: PropTypes.arrayOf(PropTypes.string),
      departments: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    uinfo: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
    }),
    created: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onEditMetaEvent: PropTypes.func,
};

MetaEventCard.defaultProps = {
  isLoading: false,
  onEditMetaEvent: () => {},
};
