import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Select, Space, Row, Col, Flex,
} from 'antd';
import {
  PlusOutlined,
  BankOutlined,
  TeamOutlined,
  ApartmentOutlined,
} from '@ant-design/icons';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import axiosRequest from '../../../api/api';
import { getListAndReadEntities } from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import './EventsHeader.css';
import AntDesignSearchBox54origins from '../../../54origins/components/AntDesignSearchBox54origins';
import useOfficesAPI from '../../../api/useOfficesAPI';
import useDepartmentsAPI from '../../../api/useDepartmentsAPI';

function EventsHeader({
  selectedTrigger,
  onCreateClick,
  onFilterChange,
  filters,
  onSearch,
}) {
  const dispatch = useDispatch();
  const partitionUuid = useSelector(getDeliveryPartition);

  const [groups, setGroups] = useState([]);
  const [offices, setOffices] = useState([]);
  const [departments, setDepartments] = useState([]);

  const { getListOfOffices } = useOfficesAPI();
  const { getListOfDepartments } = useDepartmentsAPI();

  function getListOfAllGroups() {
    return dispatch(axiosRequest.post(
      'utility/actor/list',
      [
        'GET_LIST_OF_GROUPS_REQUEST',
        {
          type: 'GET_LIST_OF_GROUPS_SUCCESS',
          payload: (res) => {
            setGroups(res?.data?.map((group) => ({
              label: group.uinfo.group_name,
              value: group.uuid,
            })) || []);
            return get(res, 'data', res);
          },
        },
        'GET_LIST_OF_GROUPS_FAILURE',
      ],
      {
        actor_type: 'group',
        not_banned: true,
      },
    ));
  }

  async function getOffices() {
    const res = await getListOfOffices(partitionUuid, {
      depth: 0,
    });

    const formattedOffices = res?.data?.map((office) => ({
      label: office?.params?.title,
      value: office.uuid,
    })) || [];
    setOffices(formattedOffices);
  }

  async function getDepartments() {
    if (!partitionUuid) return;

    const res = await getListOfDepartments(partitionUuid, {
      depth: 0,
    });

    const formattedDepartments = res?.data?.map((dept) => ({
      label: dept.params.title,
      value: dept.uuid,
    })) || [];
    setDepartments(formattedDepartments);
  }

  useEffect(() => {
    getListOfAllGroups();
    getOffices();
    getDepartments();
  }, []);

  return (
    <Flex className="events-header shadow-sm px-6 py-2" vertical>
      <Row gutter={[16, 16]} align="middle" className="-mb-2">
        <Col flex="auto">
          <div className="flex items-center gap-4">
            <span className="text-2xl font-semibold text-gray-800">Метасобытия</span>
            {selectedTrigger && (
              <span className="text-2xl font-semibold">
                "
                {selectedTrigger?.title}
                "
              </span>
            )}
          </div>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={onCreateClick}
            className="mt-2 border-none"
          >
            Создать
          </Button>
        </Col>
      </Row>

      <Row gutter={[16, 16]} align="middle" className="-mt-6 mb-2 filters-row">
        <Col flex="360px">
          <AntDesignSearchBox54origins
            placeholder="Поиск по метасобытиям..."
            onSearch={onSearch}
            className="search-input"
          />
        </Col>
        <Col flex="auto">
          <Space size="middle" className="w-full">
            <div className="filter-item flex-1">
              <BankOutlined className="filter-icon text-blue-500" />
              <Select
                placeholder="Выберите офис"
                allowClear
                style={{ width: '100%' }}
                value={filters.office}
                onChange={(value) => onFilterChange('office', value)}
                options={offices}
                className="filter-select"
              />
            </div>
            <div className="filter-item flex-1">
              <ApartmentOutlined className="filter-icon text-blue-500" />
              <Select
                placeholder="Выберите отдел"
                allowClear
                style={{ width: '100%' }}
                value={filters.department}
                onChange={(value) => onFilterChange('department', value)}
                options={departments}
                className="filter-select"
              />
            </div>
            <div className="filter-item flex-1">
              <TeamOutlined className="filter-icon text-blue-500" />
              <Select
                placeholder="Выберите группы"
                allowClear
                mode="multiple"
                style={{ width: '100%' }}
                value={filters.groups}
                onChange={(value) => onFilterChange('groups', value)}
                options={groups}
                className="filter-select"
                maxTagCount="responsive"
              />
            </div>
          </Space>
        </Col>
      </Row>
    </Flex>
  );
}

EventsHeader.propTypes = {
  selectedTrigger: PropTypes.shape({
    title: PropTypes.string,
  }),
  onCreateClick: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    office: PropTypes.string,
    department: PropTypes.string,
    groups: PropTypes.arrayOf(PropTypes.string),
  }),
};

EventsHeader.defaultProps = {
  selectedTrigger: null,
  filters: {
    office: undefined,
    department: undefined,
    groups: [],
  },
};

export default EventsHeader;
