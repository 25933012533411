import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { EyeOutlined } from '@ant-design/icons';
import { isRootOrAdmin } from '../../redux/profile/selectors';

export default function MetaeventLink({ metaevent }) {
  const isAdminOrRoot = useSelector(isRootOrAdmin);

  if (!isAdminOrRoot || !metaevent?.uuid) return null;

  const handleClick = (e) => {
    e.stopPropagation();
    window.open(`/hr/metaevents?metaevent=${metaevent.uuid}`, '_blank');
  };

  return (
    <Button
      type="link"
      size="small"
      icon={<EyeOutlined />}
      onClick={handleClick}
      className=""
    >
      Перейти к метасобытию
    </Button>
  );
}

MetaeventLink.propTypes = {
  metaevent: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
  }),
};

MetaeventLink.defaultProps = {
  metaevent: null,
};
