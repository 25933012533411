import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, Button, Flex,
  Card, Typography, Tag,
} from 'antd';
import { head } from 'lodash';
import { bool } from 'prop-types';
import { BankOutlined } from '@ant-design/icons';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import { entityRead } from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import { getUserRootUUID } from '../../../redux/storages/selectors';
import ArtifactCard from '../../../components/artifacts/ArtifactCard';
import DepartmentCard from '../../../components/departments/DepartmentCard';
import EntityDepartmentPopover from '../../../components/commonComponents/entityCardComponents/EntityDepartmentPopover';
import {
  GET_SINGLE_ARTIFACT_FAILURE, GET_SINGLE_ARTIFACT_REQUEST, GET_SINGLE_ARTIFACT_SUCCESS, RESET_SINGLE_ARTIFACT,
} from '../../../redux/departments/slices/artifactsSlice';
import { getArtifact, getArtifactUUID } from '../../../redux/departments/selectors/artifactsSelector';
import useArtifactsAPI from '../../../api/useArtifactsAPI';
import useAPI from '../../../api/useAPI';
import useOfficesAPI from '../../../api/useOfficesAPI';

const { Text, Title } = Typography;

export default function MyDepartmentRoot({
  isLeaderOrDeputy,
}) {
  const dispatch = useDispatch();

  const { getRootUser } = useAPI();

  const { updateArtifact, readListOfArtifacts } = useArtifactsAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const artifact = useSelector(getArtifact);
  const artifactUUID = useSelector(getArtifactUUID);

  const userRootUUID = useSelector(getUserRootUUID);

  const [myDepartment, setMyDepartment] = useState(null);

  const [isFetching, setIsFetching] = useState(true);

  const [office, setOffice] = useState(null);
  const [loading, setLoading] = useState(false);
  const { getListOfOffices } = useOfficesAPI();

  const onChangeDepartment = async ({ department }) => {
    const res = await updateArtifact(userRootUUID, {
      parent: department,
      entity_type: artifact?.entity_type,
    }, [
      'UPDATE_USER_DEPARTMENT_REQUEST',
      'UPDATE_USER_DEPARTMENT_SUCCESS',
      'UPDATE_USER_DEPARTMENT_FAILURE',
    ]);

    readListOfArtifacts(
      userRootUUID,
      { },
      [
        GET_SINGLE_ARTIFACT_REQUEST,
        GET_SINGLE_ARTIFACT_SUCCESS,
        GET_SINGLE_ARTIFACT_FAILURE,
      ],
    );
  };

  const initFunc = async () => {
    try {
      setIsFetching(true);
      const department = await dispatch(entityRead({
        data: {
          entity_type: 'department',
          entity_uuid: artifact?.parent,
          parent: partitionUUID,
        },
        constants: [
          'GET_DEPARTMENT_REQUEST',
          'GET_DEPARTMENT_SUCCESS',
          'GET_DEPARTMENT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }));

      if (head(department)?.entity_type === 'department') {
        setMyDepartment(head(department) ?? null);
      }
    } catch (e) {
      console.error(e);
      setMyDepartment(null);
    }
    setIsFetching(false);
  };

  const loadOffice = async () => {
    setLoading(true);
    try {
      const res = await getListOfOffices(partitionUUID);
      if (res?.data?.[0]) {
        setOffice(res.data[0]);
      }
    } catch (error) {
      console.error('Error loading office:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (partitionUUID && artifact?.parent) {
      initFunc();
    }
  }, [partitionUUID, artifact?.parent]);

  useEffect(() => {
    if (userRootUUID) {
      readListOfArtifacts(
        userRootUUID,
        { },
        [
          GET_SINGLE_ARTIFACT_REQUEST,
          GET_SINGLE_ARTIFACT_SUCCESS,
          GET_SINGLE_ARTIFACT_FAILURE,
        ],
      );
    } else {
      dispatch(RESET_SINGLE_ARTIFACT);
    }
  }, [userRootUUID]);

  useEffect(() => {
    if (partitionUUID) {
      loadOffice();
    }
  }, [partitionUUID]);

  return (
    <Flex
      className="w-full"
    >
      <Flex
        className="w-full"
        justify="space-between"
      >
        {artifact && (
        <Flex className="w-1/2">
          <ArtifactCard
            title="Данные"
            artifact={artifact}
            artifactUUID={artifactUUID}
            hideDeleteButton
            hideCopyURLButton
            hideBackButton
            onUpdateArtCallback={() => {}}
            onDeleteArtCallback={() => {}}
            onBackArtCallback={() => {}}
          />
        </Flex>
        )}
        <Flex
          vertical
          className="w-1/2 ml-6"
        >
          <Flex className="w-full mb-4">
            <Card
              loading={loading}
              className="w-full blue-base-card"
              title={(
                <span className="text-xl ml-3">
                  Офис
                </span>
              )}
            >
              <Flex align="center">
                <span className="text-base px-12 py-4">
                  Название:
                  {' '}
                  <span className="text-blue-500">
                    {office?.params?.title || 'Не назначен'}
                  </span>
                </span>
              </Flex>
            </Card>
          </Flex>
          {isLeaderOrDeputy && (
          <Alert
            type={myDepartment ? 'info' : 'warning'}
            showIcon
            className="mb-4"
            message={(
              <Flex
                align="center"
                justify="space-between"
                className="w-full"
              >
                <span>
                  {myDepartment ? `Ваши рабочие часы учитываются в отделе ${myDepartment?.params?.title}.` : 'Выберите отдел, в котором будут учитываться ваши рабочие часы!'}
                </span>
                <EntityDepartmentPopover
                  department={myDepartment?.uuid}
                  onEditCallback={onChangeDepartment}
                  content={myDepartment ? (
                    <Button
                      size="small"
                      className="text-blue-500 border-blue-500 hove"
                    >
                      Сменить отдел
                    </Button>
                  )
                    : (
                      <Button
                        size="small"
                        className="text-yellow-500 border-yellow-500 hove"
                      >
                        Выбрать отдел
                      </Button>
                    )}
                />
              </Flex>
)}
          />
          )}
          {myDepartment && (
          <DepartmentCard
            hideAdminEmployeeColumns
            isFetching={isFetching}
            department={myDepartment}
            departmentUUID={myDepartment?.uuid}
            hideExpand
            hideEdit
            hideDeleteButton
            hideAddEmployee
            hideCopyURLButton
            hideBackButton
            hideAllTabs
            hideTabs={['contractors', 'vacations', 'contractor_reports', 'contractor_requests']}
            className="!mt-0 w-full"
            onEditDepartmentCallback={() => {}}
            onBackDepartmentCallback={() => {}}
            onDeleteDepartmentCallback={() => {}}
          />
          )}
          {!isLeaderOrDeputy && !myDepartment && (
          <Alert
            className="w-1/2 ml-6 h-max"
            type="warning"
            showIcon
            message={(
              <span
                className="text-lg"
              >
                Вы не состоите в отделе! Обратитесь к руководителю для добавления вас в отдел.
              </span>
)}
          />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

MyDepartmentRoot.propTypes = {
  isLeaderOrDeputy: bool,
};
