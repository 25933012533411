import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import { getProfileInfo, getProfileUUID } from '../redux/profile/selectors';
import useMetaeventsAPI from '../api/useMetaeventsAPI';
import { getDeliveryPartition } from '../redux/config/selectors';
import useAPI from '../api/useAPI';
import useLogsAPI from '../api/useLogsAPI';
import antNotification from '../utils/antNotification';
import { getEventsStorageUUID } from '../redux/storages/selectors';
import UserStatisticsForm from '../pages/user/statistics/personal/UserStatisticsForm';
import useOfficesAPI from '../api/useOfficesAPI';
import useEventsAPI from '../api/useEventsAPI';

export default function useTriggersMonthlyReport() {
  const { getListOfMetaevents } = useMetaeventsAPI();
  const { createLog } = useLogsAPI();
  const { updateMonthlyReport } = useAPI();

  const { createEvent } = useEventsAPI();
  const { getListOfOffices } = useOfficesAPI();
  const partitionUUID = useSelector(getDeliveryPartition);
  const profileActor = useSelector(getProfileInfo);

  const eventsStorageUUID = useSelector(getEventsStorageUUID);
  const profileActorUUID = useSelector(getProfileUUID);

  const [trigger, setTrigger] = useState('');
  const [options, setOptions] = useState({});

  function CreateMonthlyReportComponent() {
    const [isModalOpen, setIsModalOpen] = useState(true);

    const {
      actor, monthlyReport, currentMonth, title, lastMonth, metaevent, saveCallback,
    } = options;

    const onOk = async (values = {}, filesForReport = [], uuid = '', files = []) => {
      try {
        if (actor) {
          const config = {
            actor,
            entity_type: 'monthlyReport',
            entity_uuid: uuid || monthlyReport?.uuid,
            params: {
              status: 'submitted',
              id: (monthlyReport?.id || 0) + 1,
              statistics: {
                ...values,
                created: dayjs().toString(),
                totalWorks: Math.ceil(values?.totalWorks) ?? 0,
                fullTotalWorks: values?.totalWorks ?? 0,
                totalDayOff: +(values?.sickday ?? 0) + +(values?.vacation ?? 0)
                + +(values?.dayoff ?? 0),
              },
            },
          };

          const newFormData = new FormData();

          newFormData.append('data', JSON.stringify(config));

          if (filesForReport.length !== 0) {
            for (let i = 0; i < filesForReport.length; i += 1) {
              newFormData.append('file', filesForReport[i]);
            }
          }

          await updateMonthlyReport(newFormData);
          createLog({
            parent: uuid || monthlyReport?.uuid,
            type: monthlyReport?.params?.statistics ? 'UPDATE' : 'CREATE',
            entityType: 'monthlyReport',
            values: {
              date: monthlyReport?.params?.date,
              status: 'submitted',
              id: (monthlyReport?.id || 0) + 1,
              statistics: {
                ...values,
                totalWorks: Math.ceil(values?.totalWorks) ?? 0,
                fullTotalWorks: values?.totalWorks ?? 0,
                totalDayOff: +(values?.sickday ?? 0) + +(values?.vacation ?? 0)
                + +(values?.dayoff ?? 0),
              },
            },
            files: filesForReport,
          });

          createEvent(eventsStorageUUID, {
            actor: actor || profileActorUUID,
            params: {
              type: 'create-monthly-report',
              month: dayjs(currentMonth).format('YYYY-MM'),
              monthlyReport: monthlyReport?.uuid,
              monthlyReportId: (monthlyReport?.id || 0) + 1,
              metaevent: metaevent?.uuid,
              metaeventParams: metaevent?.params,
            },
          });
          antNotification.success('Отчет оправлен.');
          saveCallback?.();
        }
      } catch (e) {
        antNotification.error('Ошибка!');
      }
      setIsModalOpen(false);
    };

    useEffect(() => {
      setIsModalOpen(true);
    }, []);

    useEffect(() => {
      if (!isModalOpen) {
        setTrigger('');
        setOptions({});
      }
    }, [isModalOpen]);

    return (
      <UserStatisticsForm
        actor={actor}
        isModalOpen={isModalOpen}
        onCancelModal={() => {
          setIsModalOpen(false);
          setTrigger('');
        }}
        currentMonth={currentMonth}
        onFinish={(values, filesForReport, uuid) => {
          onOk(values, filesForReport, uuid);
          setTrigger('');
          setIsModalOpen(false);
        }}
        formSettings={{
          title,
          totalWorksLabel: capitalize('Отработанные часы: '),
          commentLabel: capitalize('Комментарий (указать причину отправки отчета досрочно)'),
          instruction: metaevent?.params?.instruction,
          instructionTitle: metaevent?.params?.instruction_title,
          metaevent,
        }}
        isAheadOfTime={dayjs(currentMonth).isAfter(lastMonth) && !dayjs().isAfter(dayjs(currentMonth).endOf('month').subtract(2, 'day'))}
      />
    );
  }

  async function run(type, newOptions = {}) {
    const [metaevents, office] = await Promise.all([
      getListOfMetaevents(partitionUUID, {
        depth: 0,
        params: {
          trigger: type,
        },
      }),
      getListOfOffices(partitionUUID, {
        depth: 0,
        params: {
          contractors: [profileActor?.uuid],
        },
      }),
    ]);

    const userGroups = new Set(profileActor?.uinfo?.groups || []);
    const userDepartment = profileActor?.parent;
    const userOffice = office?.data?.[0] || {};

    const selectedMetaevent = metaevents?.data?.reduce((best, current) => {
      let weight = 0;

      if (userOffice && current.params?.office === userOffice?.uuid) {
        weight += 20;
      }

      if (userDepartment && current.params?.department === userDepartment) {
        weight += 10;
      }

      const metaeventGroups = new Set(current.params?.groups || []);
      if (metaeventGroups?.size > 0 && userGroups?.size > 0) {
        const matchingGroups = [...userGroups].filter((group) => metaeventGroups.has(group));
        const matchRatio = matchingGroups?.length / userGroups?.size;
        weight += matchRatio * 5;
      }

      if (!best || weight > best.weight) {
        return { ...current, weight };
      }

      if (weight === best.weight && dayjs(current.created).isAfter(dayjs(best.created))) {
        return { ...current, weight };
      }

      return best;
    }, null);

    setOptions({
      ...newOptions,
      metaevent: selectedMetaevent?.weight > 0
        ? selectedMetaevent
        : null,
    });
    setTrigger(type);
  }

  const EventComponent = useMemo(() => {
    switch (trigger) {
      case 'create-monthly-report':
        return CreateMonthlyReportComponent;
      default:
        return () => null;
    }
  }, [trigger]);

  return {
    run,
    EventComponent,
  };
}
