import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  List, Spin, Typography, Space, Tag, Button, Divider,
} from 'antd';
import {
  UserOutlined,
  ClockCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  PlayCircleOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import useLogsAPI from '../../../api/useLogsAPI';
import { getDeliveryPartition } from '../../../redux/config/selectors';

const { Text, Paragraph } = Typography;

const ACTION_CONFIG = {
  CREATE: { name: 'Создание', color: 'blue', icon: <PlusOutlined /> },
  UPDATE: { name: 'Обновление', color: 'green', icon: <EditOutlined /> },
  DELETE: { name: 'Удаление', color: 'red', icon: <DeleteOutlined /> },
  EXECUTE: { name: 'Выполнение', color: 'purple', icon: <PlayCircleOutlined /> },
};

const FIELD_NAMES = {
  title: 'Название',
  instruction: 'Инструкция',
  instruction_title: 'Заголовок инструкции',
  trigger: 'Триггер',
  groups: 'Группы',
  office: 'Офис',
  department: 'Отдел',
  enabled: 'Активно',
  priority: 'Приоритет',
};

export default function MetaeventLogs({ metaeventUuid, maxHeight }) {
  const { getListOfLogs } = useLogsAPI();
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedLogs, setExpandedLogs] = useState(new Set());

  useEffect(() => {
    const fetchLogs = async () => {
      if (metaeventUuid) {
        setIsLoading(true);
        try {
          const res = await getListOfLogs({ parent: metaeventUuid, affixTypes: 'metaeventLog' });
          setLogs(res?.data || []);
        } catch (error) {
          console.error('Error loading logs:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchLogs();
  }, [metaeventUuid]);

  const toggleLog = (uuid) => {
    setExpandedLogs((prev) => {
      const newExpanded = new Set(prev);
      if (newExpanded.has(uuid)) {
        newExpanded.delete(uuid);
      } else {
        newExpanded.add(uuid);
      }
      return newExpanded;
    });
  };

  const renderValue = (value) => {
    // Обработка массивов
    if (Array.isArray(value)) {
      return (
        <Space size={[0, 4]} wrap>
          {value.map((item) => (
            <Tag key={`${item}`} size="small">
              {item}
            </Tag>
          ))}
        </Space>
      );
    }

    // Обработка булевых значений
    if (typeof value === 'boolean') {
      return (
        <Tag color={value ? 'green' : 'red'}>
          {value ? 'Да' : 'Нет'}
        </Tag>
      );
    }

    // Обработка чисел
    if (typeof value === 'number') {
      return <Text>{value.toString()}</Text>;
    }

    // Обработка многострочного текста
    if (typeof value === 'string' && value.includes('\n')) {
      return (
        <div className="bg-gray-50 p-3 rounded">
          <Paragraph className="whitespace-pre-line mb-0">
            {value}
          </Paragraph>
        </div>
      );
    }

    // Обработка обычного текста
    return <Text>{value}</Text>;
  };

  const renderLogItem = (log) => {
    const isExpanded = expandedLogs.has(log.uuid);
    const action = ACTION_CONFIG[log.params.type];

    return (
      <div className="log-item border rounded-lg p-4 mb-4 shadow-sm">
        <div className="flex items-center justify-between mb-3">
          <Space>
            <Tag color={action.color} icon={action.icon}>
              {action.name}
            </Tag>
            <Text type="secondary" copyable={{ text: log.uuid }}>
              {log.uuid}
            </Text>
          </Space>
          <Space>
            <Space>
              <ClockCircleOutlined className="text-gray-400" />
              <Text type="secondary">
                {new Date(log.created).toLocaleString('ru-RU', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Text>
            </Space>
            <Button
              type="text"
              size="small"
              icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
              onClick={() => toggleLog(log.uuid)}
            />
          </Space>
        </div>

        <div className="flex items-center gap-2 mb-2">
          <UserOutlined className="text-blue-500" />
          <Text>
            {log.uinfo
              ? `${log.uinfo.first_name} ${log.uinfo.last_name}`
              : log.actor_uuid}
          </Text>
        </div>

        {isExpanded && (
          <>
            <Divider className="my-3" />
            <div className="log-values">
              {Object.entries(log.params.values || {}).map(([key, value]) => (
                <div key={key} className="mb-4">
                  <Text type="secondary" className="mb-1 block">
                    {FIELD_NAMES[key] || key}
                    :
                  </Text>
                  {renderValue(value)}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="metaevent-logs w-full -ml-6">
      <Spin spinning={isLoading}>
        <List
          style={{
            maxHeight: maxHeight || 400,
            overflowY: 'auto',
          }}
          dataSource={logs}
          renderItem={renderLogItem}
          locale={{ emptyText: 'Нет записей' }}
        />
      </Spin>
    </div>
  );
}

MetaeventLogs.propTypes = {
  metaeventUuid: PropTypes.string.isRequired,
  maxHeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

MetaeventLogs.defaultProps = {
  maxHeight: 400,
};
