import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Flex, Modal } from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { getProfileInfo, isRootOrAdmin, getProfileUUID } from '../redux/profile/selectors';
import antNotification from '../utils/antNotification';
import useMetaeventsAPI from '../api/useMetaeventsAPI';
import { getDeliveryPartition } from '../redux/config/selectors';
import StartWorkCard from '../components/layout/startWorkRecord/StartWorkCard';
import MetaeventLink from '../components/events/MetaeventLink';
import { getShowLunchBreak, getEventsStorageUUID } from '../redux/storages/selectors';
import useLogsAPI from '../api/useLogsAPI';
import useAPI from '../api/useAPI';
import useOfficesAPI from '../api/useOfficesAPI';
import useEventsAPI from '../api/useEventsAPI';

export default function useTriggersDailyReports() {
  const { getListOfMetaevents } = useMetaeventsAPI();
  const { createLog } = useLogsAPI();
  const { updateDayInMonthlyReport } = useAPI();
  const { getListOfOffices } = useOfficesAPI();
  const partitionUUID = useSelector(getDeliveryPartition);
  const profileActor = useSelector(getProfileInfo);
  const profileActorUUID = useSelector(getProfileUUID);
  const showLunchBreak = useSelector(getShowLunchBreak);
  const { createEvent } = useEventsAPI();

  const eventsStorageUUID = useSelector(getEventsStorageUUID);

  const isAdOrRoot = useSelector(isRootOrAdmin);

  const [trigger, setTrigger] = useState('');
  const [options, setOptions] = useState({});

  function CreateDailyReportComponent() {
    const [isModalOpen, setModalOpen] = useState(true);
    const {
      data, saveCallback, date, metaevent,
      monthlyReportUUID, days = [],
      isStartWorkRecord,
      titleSaveBtn,
      getPrevDayCallback,
      constants,
      actor,
    } = options;

    const updateDayCallback = async (workData = {}) => {
      try {
        const {
          kindOfDay: type,
        } = workData;
        const oldDay = days.find(({ rangeDates }) => rangeDates[0] === dayjs(date).format('YYYY-MM-DD'));

        const newDay = {
          type,
          id: (oldDay?.id || 0) + 1,
          created: dayjs().toString(),
          rangeDates: [dayjs(date).format('YYYY-MM-DD')],
          wasChoiceOfDay: true,
          ...workData?.timeState,
          ...workData?.extraOptions,
          comment: workData?.dailyPlanningText,
        };

        const newDays = [...days].filter(({ rangeDates }) => rangeDates[0] !== dayjs(date).format('YYYY-MM-DD'));

        createLog({
          parent: monthlyReportUUID,
          type: oldDay ? 'UPDATE' : 'CREATE',
          entityType: 'monthlyReportDay',
          values: newDay,
        });

        createEvent(eventsStorageUUID, {
          actor: actor || profileActorUUID,
          params: {
            type: 'create-daily-report',
            dayReportId: (oldDay?.id || 0) + 1,
            day: dayjs(date).format('YYYY-MM-DD'),
            monthlyReport: monthlyReportUUID,
            metaevent: metaevent?.uuid,
            metaeventParams: metaevent?.params,
          },
        });
        await updateDayInMonthlyReport(monthlyReportUUID, [...newDays, newDay], constants);
        setModalOpen(false);
        saveCallback?.();
        antNotification.success('Дневной отчет сохранен!');
      } catch (e) {
        console.error(e);
        antNotification.error('Не удалось изменить дневной отчет!');
      }
    };

    useEffect(() => {
      if (!isModalOpen) {
        setTrigger('');
        setOptions({});
      }
    }, [isModalOpen]);

    return (
      <Modal
        open={isModalOpen}
        title="Дневной отчет"
        width={800}
        centered
        destroyOnClose
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        <Flex vertical gap="middle">
          {metaevent?.params?.instruction && (
            <Alert
              message={(
                <Flex justify="space-between" className="w-full">
                  <span className="font-medium">
                    {metaevent?.params?.instruction_title}
                  </span>
                  {isAdOrRoot && <MetaeventLink metaevent={metaevent} />}
                </Flex>
              )}
              description={(
                <Flex vertical className="-mt-4 -mb-4">
                  <pre className="whitespace-pre-wrap font-sans text-sm text-gray-600 leading-relaxed">
                    {metaevent?.params?.instruction}
                  </pre>
                </Flex>
              )}
              type="info"
              showIcon
              style={{
                padding: '12px 16px',
              }}
            />
          )}

          <StartWorkCard
            deleteCallback={() => {
              updateDayCallback({ kindOfDay: 'report_not_sent' });
            }}
            updateDayCallback={updateDayCallback}
            defaultData={data}
            currentDay={date}
            getPrevDayCallback={getPrevDayCallback}
            showDeleteButton={data?.type !== 'report_not_sent'}
            showLunchBreak={showLunchBreak}
            isStartWorkRecord={isStartWorkRecord}
            titleSaveBtn={titleSaveBtn}
          />
        </Flex>
      </Modal>
    );
  }

  async function run(type, newOptions = {}) {
    const [metaevents, office] = await Promise.all([
      getListOfMetaevents(partitionUUID, {
        depth: 0,
        params: {
          trigger: type,
        },
      }),
      getListOfOffices(partitionUUID, {
        depth: 0,
        params: {
          contractors: [profileActor?.uuid],
        },
      }),
    ]);

    const userGroups = new Set(profileActor?.uinfo?.groups || []);
    const userDepartment = profileActor?.parent;
    const userOffice = office?.data?.[0] || {};

    const selectedMetaevent = metaevents?.data?.reduce((best, current) => {
      let weight = 0;

      if (userOffice && current.params?.office === userOffice?.uuid) {
        weight += 20;
      }

      if (userDepartment && current.params?.department === userDepartment) {
        weight += 10;
      }

      const metaeventGroups = new Set(current.params?.groups || []);
      if (metaeventGroups?.size > 0 && userGroups?.size > 0) {
        const matchingGroups = [...userGroups].filter((group) => metaeventGroups.has(group));
        const matchRatio = matchingGroups?.length / userGroups?.size;
        weight += matchRatio * 5;
      }

      if (!best || weight > best.weight) {
        return { ...current, weight };
      }

      if (weight === best.weight && dayjs(current.created).isAfter(dayjs(best.created))) {
        return { ...current, weight };
      }

      return best;
    }, null);

    setOptions({
      ...newOptions,
      metaevent: selectedMetaevent?.weight > 0
        ? selectedMetaevent
        : null,
    });
    setTrigger(type);
  }

  const EventComponent = useMemo(() => {
    switch (trigger) {
      case 'create-daily-report':
        return CreateDailyReportComponent;
      default:
        return () => null;
    }
  }, [trigger]);

  return {
    run,
    EventComponent,
  };
}
