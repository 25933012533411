import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Card, Flex } from 'antd';

import useArifactsStorage from '../../../redux/departments/hooks/useArifactsStorage';
import useURLParams from '../../../hooks/useURLParams';

import DepartmentRoot from '../../../components/departments/DepartmentRoot';

import UniGeneralButtons from '../../../components/commonComponents/uniGeneralButtons/UniGeneralButtons';
import PageWrapper from '../../../components/PageWrapper';
import EmployeeRequestsTable from '../../../components/employeeRequest/EmployeeRequestsTable';
import DepartmentRequestsTable from '../../../components/departmentRequest/DepartmentRequestsTable';

export default function DepartmentsPage() {
  useArifactsStorage(true);
  const { routeNavigateTo, getURLParams } = useURLParams();

  const tabs = [
    { name: 'departmentsInfo', title: 'Отделы' },
    { name: 'requests', title: 'Заявки' },
  ];

  // Получаем активную вкладку из URL-параметров или используем значение по умолчанию
  const activeTab = getURLParams().tab || 'departmentsInfo';

  const switchViewFunc = (name) => {
    routeNavigateTo('/departments', { tab: name });
  };

  return (
    <PageWrapper
      title="Отделы"
      needWrapperClassName={false}
    >
      <Flex
        className="-mt-5 mb-3"
      >
        <UniGeneralButtons
          viewMode={activeTab}
          switchViewFunc={switchViewFunc}
          tabs={tabs}
        />
      </Flex>
      {activeTab === 'departmentsInfo' && (
        <DepartmentRoot />
      )}
      {activeTab === 'requests' && (
        <Card
          className="w-full h-max p-0 pb-2 blue-base-card"
          title={(
            <Flex
              className="text-xl"
            >
              Заявки
            </Flex>
          )}
        >
          {/* <EmployeeRequestsTable /> */}
          <DepartmentRequestsTable />
        </Card>
      )}
    </PageWrapper>
  );
}
