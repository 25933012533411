import { Flex } from 'antd';
import React from 'react';
import cn from 'classnames';
import { get , capitalize } from 'lodash';
import dayjs from 'dayjs';
import { getNameForActor } from '../../utils/utils54origins';

export const columnsForContractorTable = [
  {
    title: 'Подрядчик',
    width: '20%',
    dataIndex: ['user'],
    render: (cell, row) => (
      <Flex vertical>
        <Flex>{getNameForActor(cell)}</Flex>
        {!row?.hasReport ? <Flex className="text-red-500">Нет отчета</Flex>
          : (
            <Flex className="text-blue-500">
              {row?.params?.status === 'auto' && 'Автоматический' }
              {row?.params?.status === 'submitted' && 'Пользовательский' }
            </Flex>
          )}
      </Flex>
    ),
  },
  {
    title: 'Проектных часов',
    dataIndex: ['params', 'statistics', 'totalWorks'],
    render: (cell, row) => (row?.hasReport ? (cell || 0) : '-'),
  },
  {
    title: 'Клиентский пр.',
    dataIndex: ['params', 'statistics', 'clientWork'],
    render: (cell, row) => (row?.hasReport ? (cell || 0) : '-'),
  },
  {
    title: 'Внутренний пр.',
    dataIndex: ['params', 'statistics', 'internalWork'],
    render: (cell, row) => (row?.hasReport ? (cell || 0) : '-'),
  },
  {
    title: 'Бенч',
    dataIndex: ['params', 'statistics', 'totalBenches'],
    render: (cell, row) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{row?.hasReport ? (cell || 0) : '-'}</span>,
  },
  {
    title: 'Нерабочих часов',
    dataIndex: ['params', 'statistics', 'totalDayOff'],
    render: (cell, row) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{row?.hasReport ? (cell || 0) : '-'}</span>,
  },
  {
    title: 'Отгул',
    dataIndex: ['params', 'statistics', 'dayoff'],
    render: (cell, row) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{row?.hasReport ? (cell || 0) : '-'}</span>,
  },
  {
    title: 'Больничный',
    dataIndex: ['params', 'statistics', 'sickday'],
    render: (cell, row) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{row?.hasReport ? (cell || 0) : '-'}</span>,
  },
  {
    title: 'Отпуск',
    dataIndex: ['params', 'statistics', 'vacation'],
    render: (cell, row) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{row?.hasReport ? (cell || 0) : '-'}</span>,
  },
];

export const columnsForDepartmentReportTable = [
  {
    title: 'Дата',
    width: '20%',
    dataIndex: ['params', 'date'],
    sortOrder: 'descend',
    sorter: (a, b) => dayjs(a?.params?.date).valueOf() - dayjs(b?.params?.date).valueOf(),
    render: (cell, row) => capitalize(dayjs(cell).format('MMMM YYYY')),
  },
  {
    title: 'Проектных часов',
    width: '8%',
    dataIndex: ['params', 'statistics', 'totalWorks'],
    render: (cell, row) => (cell || 0),
  },
  {
    title: 'Клиентский пр.',
    width: '8%',
    dataIndex: ['params', 'statistics', 'clientWork'],
    render: (cell, row) => (cell || 0),
  },
  {
    title: 'Внутренний пр.',
    width: '8%',
    dataIndex: ['params', 'statistics', 'internalWork'],
    render: (cell, row) => (cell || 0),
  },
  {
    title: 'Бенч',
    width: '8%',
    dataIndex: ['params', 'statistics', 'totalBenches'],
    render: (cell, row) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{(cell || 0)}</span>,
  },
  {
    title: 'Нерабочих часов',
    width: '8%',
    dataIndex: ['params', 'statistics', 'totalDayOff'],
    render: (cell, row) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{(cell || 0)}</span>,
  },
  {
    title: 'Отгул',
    width: '8%',
    dataIndex: ['params', 'statistics', 'dayoff'],
    render: (cell, row) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{(cell || 0)}</span>,
  },
  {
    title: 'Больничный',
    width: '8%',
    dataIndex: ['params', 'statistics', 'sickday'],
    render: (cell, row) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{(cell || 0)}</span>,
  },
  {
    title: 'Отпуск',
    width: '8%',
    dataIndex: ['params', 'statistics', 'vacation'],
    render: (cell, row) => <span className={cn('', { 'text-red-500': +cell > 0 })}>{(cell || 0)}</span>,
  },
];

export function convertTimeFormat(input) {
  const regex = /(\d+)h(\d+)m/;
  const match = input.match(regex);

  if (match) {
    const hours = match[1].padStart(2, '0');
    const minutes = match[2].padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  return '';
}

export function convertTimeFormatToHours(timeStr) {
  const regex = /(\d{1,2})h(\d{2})m?/;
  const match = timeStr?.match(regex);

  if (!match) {
    return '';
  }

  const hours = parseInt(match[1], 10);
  const minutes = match[2] ? parseInt(match[2], 10) : 0;

  let result = '';

  if (hours > 0) {
    result += `${hours} час.`;
  }

  if (minutes > 0) {
    result += ` ${minutes} мин.`;
  }

  return result.trim();
}

export function compareActorUUIDs(a, b) {
  return get(a, 'actor', {}) === get(b, 'actor', {});
}
