import React from 'react';
import {
  FormOutlined,
  AuditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  // PlayCircleOutlined,
  // CalendarOutlined,
  MedicineBoxOutlined,
  ScheduleOutlined,
  FileAddOutlined,
  FileTextOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

export const TRIGGER_NAMES = {
  // Отпуск
  'create-vacation-request': { name: 'Создание заявки на отпуск', color: '#1890ff' },
  'pre-approve-vacation-request': { name: 'Предв. согласование отпуска', color: '#faad14' },
  'approve-vacation-request': { name: 'Утверждение отпуска', color: '#52c41a' },
  'reject-vacation-request': { name: 'Отклонение отпуска', color: '#f5222d' },

  // Больничный и отгул
  'create-sick-day': { name: 'Создание больничного', color: '#f5222d' },
  'create-day-off': { name: 'Создание заявки на отгул', color: '#722ed1' },

  // Отчеты
  'create-daily-report': { name: 'Создание дневного отчета', color: '#52c41a' },
  'create-monthly-report': { name: 'Создание месячного отчета', color: '#52c41a' },

  // Онбординг
  'start-onboarding': { name: 'Начало онбординга', color: '#059669' },
  'complete-onboarding': { name: 'Завершение онбординга', color: '#52c41a' },

  // Увольнение
  'start-termination-contractor': { name: 'Инструкция для подрядчика', color: '#faad14' },
  'start-termination-management': { name: 'Инструкция для руководства', color: '#1890ff' },
  'create-termination-request': { name: 'Создание заявки на увольнение', color: '#faad14' },
  'start-termination': { name: 'Запуск увольнения', color: '#f5222d' },
  'complete-termination': { name: 'Завершение увольнения', color: '#52c41a' },

  // Пользовательские заявки
  'create-custom-request': { name: 'Создание пользовательской заявки', color: '#1890ff' },
};

export const TRIGGER_SECTIONS = {
  vacation: {
    title: 'Отпуск',
    triggers: [
      {
        id: '1',
        title: 'Создание заявки на отпуск',
        value: 'create-vacation-request',
        icon: <FormOutlined />,
        color: '#1890ff',
        description: 'Инициация процесса оформления отпуска подрядчиком',
      },
      {
        id: '2',
        title: 'Предварительное согласование отпуска',
        value: 'pre-approve-vacation-request',
        icon: <AuditOutlined />,
        color: '#faad14',
        description: 'Первичное рассмотрение заявки руководителем',
      },
      {
        id: '3',
        title: 'Утверждение отпуска',
        value: 'approve-vacation-request',
        icon: <CheckCircleOutlined />,
        color: '#52c41a',
        description: 'Финальное утверждение отпуска',
      },
      {
        id: '4',
        title: 'Отклонение отпуска',
        value: 'reject-vacation-request',
        icon: <CloseCircleOutlined />,
        color: '#f5222d',
        description: 'Отказ в предоставлении отпуска',
      },
      // {
      //   id: '5',
      //   title: 'Две недели до отпуска',
      //   value: 'two-weeks-before-vacation',
      //   icon: <CalendarOutlined />,
      //   color: '#13c2c2',
      //   description: 'Автоматические действия за две недели до отпуска',
      // },
      // {
      //   id: '6',
      //   title: 'Начало отпуска',
      //   value: 'vacation-start',
      //   icon: <PlayCircleOutlined />,
      //   color: '#1890ff',
      //   description: 'Автоматические действия в день начала отпуска',
      // },
    ],
  },
  sickLeave: {
    title: 'Больничный',
    triggers: [
      {
        id: '10',
        title: 'Создание больничного',
        value: 'create-sick-day',
        icon: <MedicineBoxOutlined />,
        color: '#f5222d',
        description: 'Регистрация периода нетрудоспособности',
      },
      // {
      //   id: '11',
      //   title: 'Закрытие больничного',
      //   value: 'close-sick-leave',
      //   icon: <CheckCircleOutlined />,
      //   color: '#52c41a',
      //   description: 'Завершение периода нетрудоспособности',
      // },
    ],
  },
  dayOff: {
    title: 'Отгул',
    triggers: [
      {
        id: '7',
        title: 'Заявка на отгул',
        value: 'create-day-off',
        icon: <ScheduleOutlined />,
        color: '#722ed1',
        description: 'Создание заявки на отгул',
      },
      // {
      //   id: '8',
      //   title: 'Утверждение отгула',
      //   value: 'approve-day-off',
      //   icon: <CheckCircleOutlined />,
      //   color: '#52c41a',
      //   description: 'Согласование отгула руководителем',
      // },
      // {
      //   id: '9',
      //   title: 'Отклонение отгула',
      //   value: 'reject-day-off',
      //   icon: <CloseCircleOutlined />,
      //   color: '#f5222d',
      //   description: 'Отказ в предоставлении отгула',
      // },
    ],
  },
  dailyReport: {
    title: 'Дневной отчет',
    triggers: [
      {
        id: '12',
        title: 'Создание дневного отчета',
        value: 'create-daily-report',
        icon: <FileAddOutlined />,
        color: '#52c41a',
        description: 'Ежедневная отчетность по выполненным работам',
      },
      // {
      //   id: '13',
      //   title: 'Проверка дневного отчета',
      //   value: 'review-daily-report',
      //   icon: <AuditOutlined />,
      //   color: '#1890ff',
      //   description: 'Проверка отчета руководителем',
      // },
    ],
  },
  monthlyReport: {
    title: 'Месячный отчет',
    triggers: [
      {
        id: '14',
        title: 'Отправка месячного отчета',
        value: 'create-monthly-report',
        icon: <FileTextOutlined />,
        color: '#52c41a',
        description: 'Формирование и отправка ежемесячного отчета',
      },
      // {
      //   id: '15',
      //   title: 'Проверка месячного отчета',
      //   value: 'review-monthly-report',
      //   icon: <AuditOutlined />,
      //   color: '#1890ff',
      //   description: 'Проверка отчета руководителем',
      // },
    ],
  },
  onboarding: {
    title: 'Онбординг',
    triggers: [
      {
        id: '16',
        title: 'Начало онбординга',
        value: 'start-onboarding',
        icon: <UserAddOutlined />,
        color: '#059669',
        description: 'Процесс адаптации нового подрядчика',
      },
      {
        id: '17',
        title: 'Завершение онбординга',
        value: 'complete-onboarding',
        icon: <CheckCircleOutlined />,
        color: '#52c41a',
        description: 'Завершение процесса адаптации',
      },
    ],
  },
  termination: {
    title: 'Увольнение',
    triggers: [
      {
        id: '18',
        title: 'Инструкция для подрядчика',
        value: 'start-termination-contractor',
        icon: <ExclamationCircleOutlined />,
        color: '#faad14',
        description: 'Инструкция для увольняемого подрядчика по процессу увольнения',
      },
      {
        id: '19',
        title: 'Инструкция для руководства',
        value: 'start-termination-management',
        icon: <FileTextOutlined />,
        color: '#1890ff',
        description: 'Инструкция для руководства по процессу увольнения подрядчика',
      },
      {
        id: '20',
        title: 'Создание заявки на увольнение',
        value: 'create-termination-request',
        icon: <FileAddOutlined />,
        color: '#faad14',
        description: 'Инструкция для создания заявки на увольнение',
      },
      {
        id: '21',
        title: 'Запуск увольнения',
        value: 'start-termination',
        icon: <UserDeleteOutlined />,
        color: '#f5222d',
        description: 'Инструкция для запуска увольнения подрядчика',
      },
      {
        id: '22',
        title: 'Завершение увольнения',
        value: 'complete-termination',
        icon: <CheckCircleOutlined />,
        color: '#52c41a',
        description: 'Инструкция для завершения увольнения подрядчика',
      },
    ],
  },
  customRequest: {
    title: 'Заявка',
    triggers: [
      {
        id: '23',
        title: 'Создание пользовательской заявки',
        value: 'create-custom-request',
        icon: <AuditOutlined />,
        color: '#1890ff',
        description: 'Создание пользовательской заявки',
      },
    ],
  },
};
